import { User, UserProfile } from "oidc-client-ts";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/hooks";
import Account from "../../models/Account";
import {
  getAccountSimpleAsync,
  createAccount,
  setAccountImpersonationList,
  updateAccount,
} from "../../redux/account";
import {
  selectAuthState,
  selectCountryCode,
  selectLanguage,
} from "../../redux/auth";
import { AuthService, CIAMCallbackData } from "../../services/AuthService";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { getSubscriptionStatusAsync } from "../../redux/subscriptionSlide";
import { getCreditStatusAsync } from "../../redux/creditSlide";
import { RequestSubscriptionModel } from "../../models/subscription/request.subscription.model";
import store, { AppDispatch } from "../../redux/store";
import CustomLoader from "../../components/loading/CustomLoader";
import { RedirectCountryModal } from "../../components/modals/RedirectCountryModal";
import { UserSubscriptionInfo } from "../../models/paymentAdyen/userSubscriptionInfo";
import { getChimeUser } from "../../services/Chime";
import TrialStatus from "../../models/TrialStatus";
import "./SigninCallback.css";
import { CreateAccountRequest } from "../../models/account/createAccountRequest";
import ContactPerson, {
  GetContactPersonByGuidRequestModel,
  MapPersonalUserAccountModel,
} from "../../models/ContactPerson";
import {
  getContactPersonByGuidAsync,
  mapPersonalUserAccount,
} from "../../services/ContactPersonsService";
import { ConfirmItsMeModal } from "../../components/Modal/ConfirmItsMeModal";
import { clearAllCart } from "../../redux/shoppingCartSlide";
import AccountInReviewCDM3Modal from "../../components/Modal/AccountInReviewCDM3Modal";
import { setJoinCompanyActiveModal } from "../../redux/joinCompanySlide";
import InactiveSubscriptionCDM3Modal from "../../components/Modal/InactiveSubscriptionCDM3Modal";
import WelcomeCDM3Modal from "../../components/Modal/WelcomeCDM3Modal";
import { TrialModeEnabledCDM3Modal } from "../../components/Modal/TrialModeEnabledCDM3Modal";
import { TrialModeDisabledCDM3Modal } from "../../components/Modal/TrialModeDisabledCDM3Modal";
import { Constants } from "../../utils/helpers/Constants";
import LowCreditBalanceCDM3Modal from "../../components/Modal/LowCreditBalanceCDM3Modal";
import SelectCompanyModal from "../../components/CompanyModal/SelectCompanyModal";
import { clearCart } from "../../redux/shoppingCartV3Slice";
import { useGTMAnalyticTracker } from "../../hooks/useGTMAnalyticTracker";
import { updateForcedToHideNotificationBanner } from "../../redux/maintenanceSlice";
import SwitchCountryModal from "../../components/modals/switchCountry/SwitchCountryModal";
import { useRouting } from "../../hooks/useRouting";
import NegativeCreditBalanceModal from "../../components/modals/negativeCreditBalanceModal/NegativeCreditBalanceModal";
import { useProceedNegativeCreditBalance } from "../../hooks/useProceedNegativeCreditBalance";
import ResponseResultBase from "../../models/ResponseResultBase";
import { CreditStatus } from "../../redux/accountSlide";
import { CommonService } from "../../services/Common";
import { UserProfileConstants } from "../../utils/helpers/UserProfileConstants";

const SignInCallbackCDM3 = () => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const authState = useSelector(selectAuthState);
  const countryCode = useSelector(selectCountryCode);
  const authService: AuthService = AuthService.getInstance();
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { isAbleToShowSwitchCountryModal, isAbleToShowRedirectCountryModal } =
    useRouting();
  const { isNegativeBalance } = useProceedNegativeCreditBalance();

  const [status, setStatus] = useState("");
  const [ciamId, setCiamId] = useState<string>("");
  const [ciamEmail, setCiamEmail] = useState<string>("");
  const [contactGuid, setContactGuid] = useState<string>("");
  const [userCountryName, setUserCountryName] = useState("");
  const [loggedInCountryName, setLoggedInCountryName] = useState("");
  const [ciamAccount, setCiamAccount] = useState<User | null>(null);
  const [contactAccount, setContactAccount] = useState<Account | null>(null);
  const [contactPerson, setContactPerson] = useState<ContactPerson | any>(null);
  const [userSubscriptionInfo, setUserSubscriptionInfo] =
    useState<UserSubscriptionInfo>();
  //Loader
  const [isLoading, setIsLoading] = useState(true);
  //Modal
  const [openConfirmByMeModal, setOpenConfirmByMeModal] =
    useState<boolean>(false);
  const [openAccountReviewModal, setOpenAccountReviewModal] =
    useState<boolean>(false);
  const [openTrialModeEnabledModal, setOpenTrialModeEnabledModal] =
    useState<boolean>(false);
  const [openTrialModeDisabledModal, setOpenTrialModeDisabledModal] =
    useState<boolean>(false);
  const [openLowCreditModal, setOpenLowCreditModal] = useState<boolean>(false);
  const [isOpenNegativeBalanceModal, setOpenNegativeBalanceModal] =
    useState(false);
  const [openInactiveSubscriptionModal, setOpenInactiveSubscriptionModal] =
    useState<boolean>(false);
  const [signInRedirectUrl, setSignInRedirectUrl] = useState<string>("");
  const [selectCompanyModal, setSelectCompanyModal] = useState<boolean>(false);
  const { addAnalyticEvent } = useGTMAnalyticTracker();

  const resetReduxState = (isClearCart: boolean) => {
    if (isClearCart) {
      dispatchReduxToolkit(clearCart());
      dispatchReduxToolkit(clearAllCart());
    }

    dispatchReduxToolkit(setJoinCompanyActiveModal("none"));
  };

  const redirectToUrl = (redirectUrl: string) => {
    if (signInRedirectUrl) {
      if (
        !!userSubscriptionInfo &&
        userSubscriptionInfo.id > 0 &&
        userSubscriptionInfo.isActive &&
        signInRedirectUrl &&
        signInRedirectUrl.includes("/shopping-cart")
      ) {
        resetReduxState(true);
        window.location.href = !!contactAccount?.cdbId ? "/dashboard" : "/";
        return;
      }

      if (signInRedirectUrl !== "/") {
        resetReduxState(false);
        window.location.href = signInRedirectUrl;
        return;
      }
    }

    resetReduxState(false);
    window.location.href = redirectUrl;
  };

  //Handle close modals
  const closeAccountReviewModal = () => {
    setOpenAccountReviewModal(false);
    setTimeout(() => {
      redirectToUrl("/");
    }, 200);
  };

  const closeWelcomeCDM3Modal = () => {
    setOpenAccountReviewModal(false);
    setTimeout(() => {
      redirectToUrl("/subscriptions");
    }, 200);
  };

  const closeTrialModeEnabledModal = () => {
    setOpenTrialModeEnabledModal(false);
    setTimeout(() => {
      redirectToUrl("/");
    }, 200);
  };

  const closeTrialModeDisabledModal = () => {
    setOpenTrialModeDisabledModal(false);
    setTimeout(() => {
      redirectToUrl("/subscriptions");
    }, 200);
  };

  const handleGuidRedirect = async (
    guid: string,
    ciamId: string,
    ciamEmail: string,
    resp: User
  ) => {
    const getContactByGuidRequestModel: GetContactPersonByGuidRequestModel = {
      contactGuid: guid,
      country: !!authState.countryCode ? authState.countryCode : "",
      language: !!authState.language ? authState.language : "",
    };
    let contactPersonResp = await dispatchReduxToolkit(
      getContactPersonByGuidAsync(getContactByGuidRequestModel)
    );
    if (getContactPersonByGuidAsync.fulfilled.match(contactPersonResp)) {
      if (!!contactPersonResp.payload) {
        setCiamAccount(resp);
        setCiamId(ciamId);
        setCiamEmail(ciamEmail);
        setContactGuid(guid);
        setContactPerson(contactPersonResp.payload);
        setOpenConfirmByMeModal(true);
      } else {
        await createNewAccount(resp, ciamId);
      }
    }
  };

  const getCurrentAccount = (ciamId: string) => {
    return dispatchReduxToolkit(
      getAccountSimpleAsync({
        ciamId: ciamId || "",
        language,
        countryCode,
      })
    );
  };

  const handleCloseSelectCompany = (ciamId: string) => {
    const getCompanyPromise = getCurrentAccount(ciamId);
    getCompanyPromise.finally(() => {
      redirectToUrl("/dashboard");
    });
  };

  const userConfirmItsMe = async () => {
    setIsLoading(true);
    const requestObj: MapPersonalUserAccountModel = {
      ciamId: ciamId,
      email: ciamEmail,
      contactPersonGuid: contactGuid,
      country: countryCode,
      language: language,
    };
    const response = await dispatchReduxToolkit(
      mapPersonalUserAccount(requestObj)
    );
    if (mapPersonalUserAccount.fulfilled.match(response)) {
      setIsLoading(false);
      await authService.logoutAsync();
    }
    if (mapPersonalUserAccount.rejected.match(response)) {
      setIsLoading(false);
      setStatus(t("Error_Common"));
    }
  };

  const createNewAccount = async (resp: User, ciamid: string) => {
    const profile = resp.profile;
    const countryCodeLower = profile.countrycode as string;
    const account: CreateAccountRequest = {
      ciamId: profile.ciam_id as string,
      contactFirstName: profile?.given_name ? profile?.given_name : "",
      contactLastName: profile?.family_name ? profile?.family_name : "",
      mobliePhone: profile?.mobile ? (profile?.mobile as string) : "",
      contactEmail: profile?.email ? profile?.email : "",
      ciamEmail: profile?.email ? profile?.email : "",
      name1: (profile.company as any)?.companyName
        ? (profile.company as any).companyName
        : "",
      name2: "",
      city: (profile.company as any)?.city ? (profile.company as any).city : "",
      postCode: (profile.company as any)?.zipCode
        ? (profile.company as any).zipCode
        : "",
      vat: (profile.company as any)?.taxNumber
        ? (profile.company as any).taxNumber.substring(0, 25)
        : "",
      companyCountry: "",
      countryCode: authState?.countryCode
        ? authState?.countryCode
        : (profile.countrycode + "").toUpperCase(),
      companyPhone: (profile.company as any)?.phone
        ? (profile.company as any).phone
        : "",
      companyEmail: "",
      address1: (profile.company as any)?.address1
        ? (profile.company as any).address1
        : "",
      address2: (profile.company as any)?.address2
        ? (profile.company as any).address2
        : "",
      availableCredits: null,
      cdbId: 0,
      employees: [],
      hardware: [],
      isActive: false,
      subscription: null,
      transactions: [],
      privateHint: CommonService.getUserProfileAttribute(
        UserProfileConstants.PROFILE_ATTRIBUTE_PRIVACYHINT,
        countryCodeLower,
        profile
      ),
      privateHintLastChanged: (
        profile[
          UserProfileConstants.PROFILE_ATTRIBUTE_PRIVACYHINT + countryCodeLower
        ] as any
      )?.lastChange
        ? new Date(
            (
              profile[
                UserProfileConstants.PROFILE_ATTRIBUTE_PRIVACYHINT +
                  countryCodeLower
              ] as any
            ).lastChange
          )
        : null,
      termAndConditions: CommonService.getUserProfileAttribute(
        UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS,
        countryCodeLower,
        profile
      ),
      termsAndConditionsLastChanged: (
        profile[
          UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS +
            countryCodeLower
        ] as any
      )?.lastChange
        ? new Date(
            (
              profile[
                UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS +
                  countryCodeLower
              ] as any
            ).lastChange
          )
        : null,
      wholesalerOrganizationId: 0,
      wholesalerBranchId: 0,
      wholesalerGroup: null,
      wholesalerBranch: null,
      employee: null,
      trialStatus: TrialStatus.None,
      language: language,
    };

    const response = await dispatchReduxToolkit(createAccount(account));
    if (createAccount.fulfilled.match(response)) {
      if (!!response.payload && response.payload.success) {
        addAnalyticEvent("account_registration");
        setContactAccount(response.payload.dataObject);
        await getSimpleAccount(resp, ciamid);
      }
      return true;
    }
    if (createAccount.rejected.match(response)) {
      setStatus(t("Error_Common"));
      return false;
    }
  };

  const handleValidateUserStatusAndRedirecting = (
    payload: any,
    redirectUrl: string,
    subscriptionInfo: UserSubscriptionInfo | null,
    currentCredits: number | undefined
  ) => {
    if (
      !!payload.dataObject.impersonationList &&
      payload.dataObject.impersonationList.length > 1
    ) {
      setIsLoading(false);
      setSelectCompanyModal(true);
    } else if (
      payload?.dataObject?.cdbId === 0 ||
      payload?.dataObject?.cdbId === null
    ) {
      setIsLoading(false);
      setOpenAccountReviewModal(true);
    } else if (payload?.dataObject?.trialStatus === TrialStatus.Active) {
      setIsLoading(false);
      setOpenTrialModeEnabledModal(true);
    } else if (payload?.dataObject?.trialStatus === TrialStatus.Expired) {
      setIsLoading(false);
      setOpenTrialModeDisabledModal(true);
    } else if (!subscriptionInfo || !subscriptionInfo?.id) {
      setOpenAccountReviewModal(true);
      setIsLoading(false);
    } else if (
      !!payload.dataObject &&
      !!payload.dataObject.cdbId &&
      !subscriptionInfo.isActive &&
      !!subscriptionInfo.renewalDate &&
      !subscriptionInfo.validTill
    ) {
      setOpenInactiveSubscriptionModal(true);
      setIsLoading(false);
    } else {
      if (
        !!subscriptionInfo &&
        subscriptionInfo.id > 0 &&
        subscriptionInfo.isActive &&
        redirectUrl?.includes("/shopping-cart")
      ) {
        resetReduxState(true);
        window.location.href = contactAccount?.cdbId ? "/dashboard" : "/";
      } else {
        if (
          subscriptionInfo?.subscription?.cdm3SubscriptionType?.id ===
          Constants.SUBSCRIPTION_CDM3_BUSINESS_TYPE
        ) {
          if (
            currentCredits !== undefined &&
            currentCredits < Constants.ACCOUNT_CREDIT_LIMIT_WARNING
          ) {
            setIsLoading(false);
            setOpenLowCreditModal(true);
            return;
          }
          setIsLoading(false);
          window.location.href = "/dashboard";
          return;
        }

        redirectToUrl(
          redirectUrl && redirectUrl === "/" ? "/dashboard" : redirectUrl || "/"
        );
      }
    }
  };

  const mapKeyCloakInfoToUserObject = (keycloakProfile: UserProfile) => {
    const {
      given_name: contactFirstName,
      family_name: contactLastName,
      mobile: mobliePhone,
      email: contactEmail,
      company: {
        companyName: name1,
        city,
        zipCode: postCode,
        taxNumber: vat,
        phone: companyPhone,
        address1,
        address2,
      },
    } = keycloakProfile as any;

    return {
      contactFirstName,
      contactLastName,
      mobliePhone,
      contactEmail,
      name1,
      city,
      postCode,
      vat,
      companyPhone,
      address1,
      address2,
    };
  };

  const findUpdatedKeyCloakFields = (
    keycloakProfile: UserProfile,
    currentProfile: Account
  ): Account | null => {
    let needUpdateFields: Record<string, any> = {};
    const currentProfileAsAny = currentProfile as any;
    const necessaryInfoFromKeycloakAsArray = Object.entries(
      mapKeyCloakInfoToUserObject(keycloakProfile)
    );

    for (const prop of necessaryInfoFromKeycloakAsArray) {
      const [key, value] = prop;

      if (!key) continue;

      if ((currentProfileAsAny[key] ?? "") !== (value ?? "")) {
        needUpdateFields = {
          ...needUpdateFields,
          [key]: value,
        };
      }
    }

    needUpdateFields = {
      ...needUpdateFields,
      termAndConditions: CommonService.getUserProfileAttribute(
        UserProfileConstants.PROFILE_ATTRIBUTE_TERMS_AND_CONDITIONS,
        currentProfile?.countryCode?.toLowerCase(),
        keycloakProfile
      ),
      privateHint: CommonService.getUserProfileAttribute(
        UserProfileConstants.PROFILE_ATTRIBUTE_PRIVACYHINT,
        currentProfile?.countryCode?.toLowerCase(),
        keycloakProfile
      ),
    };

    return Object.keys(needUpdateFields).length === 0
      ? null
      : {
          ...currentProfile,
          ...needUpdateFields,
        };
  };

  const getSimpleAccount = async (resp: User, ciamid: string) => {
    const response = await getCurrentAccount(ciamid);

    if (getAccountSimpleAsync.fulfilled.match(response)) {
      if (!response?.payload?.dataObject) {
        const callback_data = resp.state as CIAMCallbackData;
        if (callback_data?.guid && callback_data.guid.length > 0) {
          setIsLoading(false);
          await handleGuidRedirect(
            callback_data?.guid,
            resp?.profile?.ciam_id as string,
            resp?.profile?.email ?? "",
            resp
          );
        } else {
          let responseCreateAccount = await createNewAccount(
            resp,
            resp.profile.ciam_id as string
          );
          if (responseCreateAccount) {
            setIsLoading(false);
            setOpenAccountReviewModal(true);
          } else {
            setStatus(t("Error_Common"));
          }
        }
      }

      if (response?.payload?.dataObject) {
        addAnalyticEvent("login");
        const payload = response?.payload;
        /**
         * START: Update current account if any changes on Keycloak
         */

        const accountNeedToBeSyncWithKeycloak = findUpdatedKeyCloakFields(
          resp.profile,
          response.payload.dataObject
        );

        if (accountNeedToBeSyncWithKeycloak) {
          await dispatchReduxToolkit(
            updateAccount({
              ...accountNeedToBeSyncWithKeycloak,
              wholesalerOrganizationId:
                accountNeedToBeSyncWithKeycloak.wholesalerGroup?.id,
              wholesalerBranchId:
                accountNeedToBeSyncWithKeycloak.wholesalerBranch?.id,
              mobilePhone: accountNeedToBeSyncWithKeycloak.mobliePhone,
            } as any)
          );
        }
        /**
         * END: Update current account if any changes on Keycloak
         */
        dispatchReduxToolkit(setAccountImpersonationList(payload));
        setContactAccount(response.payload.dataObject);
        if (response.payload.dataObject) {
          getChimeUser({
            CiamId: ciamid,
            name: response.payload.dataObject.contactFirstName,
          });
          const userCountryCodeCheck =
            response.payload.dataObject.companyCountry;
          if (
            !userCountryCodeCheck ||
            userCountryCodeCheck?.toUpperCase() === countryCode?.toUpperCase()
          ) {
            let requestModel: RequestSubscriptionModel = {
              ciamId: ciamid,
              countryCode: countryCode,
              language: language,
            };
            const accountState = store.getState().account;
            const isLoggedInState = store.getState().account?.isLoggedIn;

            Promise.all([
              dispatchReduxToolkit(getSubscriptionStatusAsync(requestModel)),
              dispatchReduxToolkit(getCreditStatusAsync(ciamid)),
            ])
              .then(([subscriptionResponse, creditsResponse]) => {
                const creditsDataObject =
                  creditsResponse.payload as ResponseResultBase<CreditStatus>;

                if (
                  !!creditsDataObject.dataObject &&
                  isNegativeBalance(creditsDataObject.dataObject.credits)
                ) {
                  setIsLoading(false);
                  setOpenNegativeBalanceModal(true);
                  return;
                }

                const timeoutId = setTimeout(() => {
                  const callback_data = resp.state as CIAMCallbackData;
                  if (accountState && isLoggedInState) {
                    const subscriptionInfo =
                      subscriptionResponse?.payload as UserSubscriptionInfo;
                    handleValidateUserStatusAndRedirecting(
                      response.payload,
                      callback_data?.redirectUrl,
                      subscriptionInfo,
                      creditsDataObject.dataObject?.credits
                    );

                    setUserSubscriptionInfo(subscriptionInfo);
                  }
                  clearTimeout(timeoutId);
                }, 100);
              })
              .catch(() => {
                const timeoutId = setTimeout(() => {
                  if (accountState && isLoggedInState) {
                    handleValidateUserStatusAndRedirecting(
                      response.payload,
                      "/subscriptions",
                      null,
                      undefined
                    );
                  }
                  clearTimeout(timeoutId);
                }, 100);
              })
              .finally(() => setIsLoading(false));
          } else {
            const targetCountry =
              response.payload.dataObject.companyCountry ||
              response.payload.dataObject.countryCode;
            let requestModel: RequestSubscriptionModel = {
              ciamId: ciamid,
              countryCode: countryCode,
              language: language,
            };
            Promise.all([
              dispatchReduxToolkit(getSubscriptionStatusAsync(requestModel)),
              dispatchReduxToolkit(getCreditStatusAsync(ciamid)),
            ])
              .then(() => {
                const regions = AuthService.getRegionsLanguages();
                const userCountry = regions.find(
                  (x) =>
                    x.country.trim().toLowerCase() ===
                    targetCountry.trim().toLowerCase()
                );
                setUserCountryName(userCountry?.countryName || "");
                const loggedInCountry = regions.find(
                  (x) =>
                    x.country.trim().toLowerCase() ===
                    countryCode.trim().toLowerCase()
                );
                setLoggedInCountryName(loggedInCountry?.countryName || "");
              })
              .finally(() => setIsLoading(false));
          }
        } else {
          const callback_data = resp.state as CIAMCallbackData;
          const guid = callback_data?.guid ? callback_data?.guid : "";
          if (callback_data && guid.length > 0) {
            await handleGuidRedirect(
              guid,
              resp?.profile?.ciam_id as string,
              resp?.profile?.email ?? "",
              resp
            );
          } else {
            setOpenAccountReviewModal(true);
          }
          setIsLoading(false);
        }
      }
    }

    // reset force to hide notification banner
    dispatchReduxToolkit(updateForcedToHideNotificationBanner(false));

    if (getAccountSimpleAsync.rejected.match(response)) {
      if (response?.payload) {
        await createNewAccount(resp, ciamid);
        return;
      }

      setStatus(t("Error_Common"));
    }
  };

  useEffect(() => {
    async function performLogin() {
      const accountService = AuthService.getInstance();
      const result = await accountService.loginCallbackAsync();

      if (!!result && result.profile && !!result.profile.ciam_id) {
        const callback_data = result.state as CIAMCallbackData;
        if (!!result.state && !!callback_data.redirectUrl) {
          setSignInRedirectUrl(callback_data.redirectUrl);
        }

        const ciamid = result.profile.ciam_id as string;
        await getSimpleAccount(result, ciamid);
      } else {
        setStatus(t("Error_Common"));
      }
    }

    performLogin();
  }, []);

  return (
    <Fragment>
      {isLoading ? <CustomLoader enable /> : <></>}
      {!status && <div className="background-image--popup-modal"></div>}

      {isAbleToShowSwitchCountryModal() ? <SwitchCountryModal /> : <></>}

      {selectCompanyModal && (
        <SelectCompanyModal continue={handleCloseSelectCompany} />
      )}

      {!contactGuid && (
        <>
          {!!userSubscriptionInfo && userSubscriptionInfo.id > 0 ? (
            <>
              {!!contactAccount && !contactAccount.cdbId && (
                <AccountInReviewCDM3Modal
                  isDisplayModal={openAccountReviewModal}
                  setCloseModalTrigger={closeAccountReviewModal}
                />
              )}

              {openInactiveSubscriptionModal && (
                <InactiveSubscriptionCDM3Modal
                  isDisplayModal={openInactiveSubscriptionModal}
                  setCloseModalTrigger={() => {
                    redirectToUrl("/subscriptions");
                  }}
                />
              )}

              {openLowCreditModal && (
                <LowCreditBalanceCDM3Modal
                  isDisplayModal
                  setCloseModalTrigger={() => {
                    redirectToUrl("/dashboard");
                  }}
                />
              )}
              {isOpenNegativeBalanceModal && (
                <NegativeCreditBalanceModal
                  isOpen
                  onClose={() => setOpenNegativeBalanceModal(false)}
                />
              )}
            </>
          ) : !isAbleToShowSwitchCountryModal() ? (
            <WelcomeCDM3Modal
              isDisplayModal={openAccountReviewModal}
              setCloseModalTrigger={closeWelcomeCDM3Modal}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {!contactGuid && (
        <TrialModeEnabledCDM3Modal
          isOpen={openTrialModeEnabledModal}
          closeModal={closeTrialModeEnabledModal}
        />
      )}

      {!contactGuid && (
        <TrialModeDisabledCDM3Modal
          isOpen={openTrialModeDisabledModal}
          closeModal={closeTrialModeDisabledModal}
        />
      )}

      <ConfirmItsMeModal
        isOpen={openConfirmByMeModal}
        contactPerson={contactPerson}
        account={contactAccount}
        ciamAccount={ciamAccount}
        userConfirm={userConfirmItsMe}
      />
      {isAbleToShowRedirectCountryModal() &&
        !!loggedInCountryName &&
        !!userCountryName && (
          <RedirectCountryModal
            loggedInCountry={loggedInCountryName}
            userCountry={userCountryName}
          />
        )}
      {status && <p>Error: {status}</p>}
    </Fragment>
  );
};

export default SignInCallbackCDM3;
