import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";
import { Language, Region } from "../models/RegionState";
import { AuthService } from "../services/AuthService";
import { getAccountSimpleAsync, logoutCallbackAsync } from "./account";
import { RootState } from "./store";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../constants/frok.constant";

export interface AuthState {
  user: User | null;
  isSignIn: boolean | undefined;
  countryCode: string;
  countryName: string;
  language: string;
  timeZoneId: string;
  languages: Language[] | null;
  cdmVersion: string;
  hasWholesalerConfig: boolean;
  isOpenSideNav?: boolean;
}

export const initialState: AuthState = {
  user: null,
  isSignIn: undefined,
  countryCode: "",
  countryName: "",
  language: "",
  timeZoneId: "",
  languages: [],
  cdmVersion: "1.0",
  hasWholesalerConfig: false,
  isOpenSideNav:
    window.screen.availWidth >= SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT
      ? true
      : false,
};

export const selectAuthState = (rootState: RootState) => rootState.auth;
export const selectIsSignIn = (rootState: RootState) =>
  selectAuthState(rootState).isSignIn;
export const selectCountryCode = (rootState: RootState) =>
  selectAuthState(rootState).countryCode;
export const selectTimeZoneId = (rootState: RootState) =>
  selectAuthState(rootState).timeZoneId;
export const selectLanguage = (rootState: RootState) =>
  selectAuthState(rootState).language;
export const selectCdmVersion = (rootState: RootState) =>
  selectAuthState(rootState).cdmVersion;
export const selectCountryName = (rootState: RootState) =>
  selectAuthState(rootState).countryName;
export const selectAllLanguages = (rootState: RootState) =>
  selectAuthState(rootState).languages;
export const selectOpenSideNav = (rootState: RootState) =>
  selectAuthState(rootState).isOpenSideNav;

const authReducerSlice = createSlice({
  name: "authReducer",
  initialState: initialState,
  reducers: {
    authSetSignIn: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSignIn: action.payload };
    },
    authSetUser: (state, action: PayloadAction<User | null>) => {
      return { ...state, user: action.payload };
    },

    authSetLanguage: (state, action: PayloadAction<string>) => {
      const orginalState = AuthService.getAuthState();
      orginalState.language = action.payload;
      AuthService.setAuthState(orginalState);
      return {
        ...state,
        language: action.payload,
      };
    },
    authSetNewState: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        countryCode: action.payload.countryCode,
        countryName: action.payload.countryName,
        language: action.payload.language,
        timeZoneId: action.payload.timeZoneId,
        languages: action.payload.languages,
        cdmVersion: action.payload.cdmVersion,
        hasWholesalerConfig: action.payload.hasWholesalerConfig,
      };
    },
    authFetchState: (state) => {
      const authState = AuthService.getAuthState();
      return {
        ...state,
        countryCode: authState.countryCode,
        language: authState.language,
        countryName: authState.countryName,
        timeZoneId: authState.timeZoneId,
        languages: authState.languages,
        cdmVersion: authState.cdmVersion,
        hasWholesalerConfig: authState.hasWholesalerConfig,
      };
    },
    authOverrideInfo: (state, action: PayloadAction<Region | undefined>) => {
      if (action.payload) {
        const originalState = {
          ...AuthService.getAuthState(),
          cdmVersion: action.payload?.cdmVersion,
          hasWholesalerConfig: action.payload?.hasWholesalerConfig,
        };

        AuthService.setAuthState(originalState);
        return {
          ...state,
          cdmVersion: action.payload.cdmVersion,
          hasWholesalerConfig: action.payload.hasWholesalerConfig,
        };
      }
      return {
        ...state,
      };
    },
    setOpenSideNav: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpenSideNav: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          payload.httpStatusCode === 200 &&
          payload.dataObject &&
          (!payload.dataObject.companyCountry ||
            payload.dataObject.companyCountry?.toUpperCase() ===
              AuthService.getAuthState()?.countryCode?.toUpperCase())
        ) {
          state.isSignIn = true;
        }
      })
      // .addCase(getAccountExtendedAsync.fulfilled, (state, { payload }) => {
      //   if (payload != null && payload.httpStatusCode == 200) {
      //     state.isSignIn = true;
      //   }
      // })
      // .addCase(createAccount.fulfilled, (state, { payload }) => {
      //   if (payload != null && (payload.httpStatusCode == 200 || payload.httpStatusCode == 201)) {
      //     state.isSignIn = true;
      //   }
      // })
      // .addCase(createAccountForEditProfile.fulfilled, (state, { payload }) => {
      //   if (payload != null && (payload.httpStatusCode == 200 || payload.httpStatusCode == 201)) {
      //     state.isSignIn = true;
      //   }
      // })
      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        state.isSignIn = false;
      });
  },
});

export const {
  authSetUser,
  authSetSignIn,
  authFetchState,
  authSetNewState,
  authSetLanguage,
  authOverrideInfo,
  setOpenSideNav,
} = authReducerSlice.actions;
export default authReducerSlice.reducer;
