import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
import {
  LogLevel,
  ConsoleLogger,
  DefaultMessagingSession,
  MessagingSessionConfiguration,
  PrefetchOn
} from 'amazon-chime-sdk-js';
import { createMemberArn, getMessagingSessionEndpoint } from '../utils/helpers/ChimeAPI';
import { AppConsts } from '../utils/helpers/AppConstants';

interface memberObject {
  username: string,
  userId: string
}
class MessagingService {
  private static instance: MessagingService;
  private _member: memberObject; // Store member information

  public static getInstance(): MessagingService {
    if (!MessagingService.instance) {
      MessagingService.instance = new MessagingService();
    }

    return MessagingService.instance;
  }

  public _session: DefaultMessagingSession;
  public sessionId: string;
  public _logger: ConsoleLogger;
  public _messageUpdateCallbacks: any[];
  public _endpoint: string;
  public _connectionStatus: number;

  constructor() {
    this._session = {} as DefaultMessagingSession;
    this.sessionId = uuid();
    this._logger = new ConsoleLogger('SDK Chat Demo', LogLevel.INFO);
    this._messageUpdateCallbacks = [];
    this._endpoint = "";
    this._member = { userId: "", username: "" };
    this._connectionStatus = AppConsts.socketConnectionStatus.disConnect;
  }

  // Method to set member
  public setMember(member: memberObject) {
    this._member = member;
  }

  messageObserver = {
    messagingSessionDidStart: () => {
      this._connectionStatus = AppConsts.socketConnectionStatus.online
    },
    messagingSessionDidStartConnecting: () => {
      this._connectionStatus = AppConsts.socketConnectionStatus.connecting
    },
    messagingSessionDidStop: () => {
      this._connectionStatus = AppConsts.socketConnectionStatus.disConnect
      // Add reconnection logic here
      this.reconnect();
    },
    messagingSessionDidReceiveMessage: (message: any) => {
      this.publishMessageUpdate(message);
    }
  };

  setMessagingEndpoint(member: any) {
    getMessagingSessionEndpoint()
      .then(async response => {
        this._endpoint = response?.Endpoint?.Url;
        const sessionConfig = new MessagingSessionConfiguration(
          createMemberArn(member.userId),
          this.sessionId,
          this._endpoint,
          new AWS.ChimeSDKMessaging()
        );
        sessionConfig.reconnectTimeoutMs = 5000;
        sessionConfig.reconnectFixedWaitMs = 5000;
        sessionConfig.reconnectLongBackoffMs = 10000;
        sessionConfig.reconnectShortBackoffMs = 5000;
        sessionConfig.prefetchOn = PrefetchOn.Connect;
        this._session = new DefaultMessagingSession(
          sessionConfig,
          this._logger
        );
        this._session.addObserver(this.messageObserver);
        this._session.start();
      })
      .catch(err => {
        console.error("connect messaging endpoint error===: ", err);
        this.reconnect();
      });
  }

  async reconnect() {
    console.log('Attempting to reconnect...');
    setTimeout(() => this.connect(), 5000);
  }

  connect() {
    if (!this._member) {
      console.error("Member information not set.");
      return;
    }
    this.setMessagingEndpoint(this._member);
  }

  close() {
    try {
      this._session.stop();
    } catch (err) {
      console.error('Failed to stop Messaging Session.');
    }
  }

  subscribeToMessageUpdate(callback: any) {
    console.log('Message listener subscribed!');
    this._messageUpdateCallbacks.push(callback);
  }

  unsubscribeFromMessageUpdate(callback: any) {
    const index = this._messageUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      this._messageUpdateCallbacks.splice(index, 1);
    }
  }

  publishMessageUpdate(message: any) {
    for (const callback of this._messageUpdateCallbacks) {
      callback(message);
    }
  }

  getConnectionStatus() {
    return this._connectionStatus;
  }
}

export default MessagingService;