import { SideNavItem, SideNavItemKey } from "../types/sideNavigation.type";
import { Constants } from "../utils/helpers/Constants";
import {
  DASHBOARD_MY_SUBSCRIPTION_CDM_3_ROUTE,
  DASHBOARD_WALLET_CDM_3_ROUTE,
} from "./routes/cdm3v0Routes.constants";
import { DASHBOARD_HARDWARE_ROUTE } from "./routes/defaultRoutes.constants";
import {
  CHECKOUT_SHARED_ROUTE,
  DASHBOARD_REQUESTS_SHARED_ROUTE,
  DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE,
  DASHBOARD_STATISTICS_SHARED_ROUTE,
  DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  HOME_SHARED_ROUTE,
  MY_BOSCH_SHARED_ROUTE,
  MY_REQUESTS_SHARED_ROUTE,
  SHOPPING_CART_SHARED_ROUTE,
  SUBSCRIPTIONS_SHARED_ROUTE,
  SUBSCRIPTION_DETAILS_SHARED_ROUTE,
  SUPPORT_SHARED_ROUTE,
} from "./routes/sharedRoutes.constants";

export const SIDE_NAV_HOME_ITEM: SideNavItem = {
  key: SideNavItemKey.HOME,
  icon: "grid-view",
  path: HOME_SHARED_ROUTE.path,
  label: "BreadScrumb_Home",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_PRODUCTS_ITEM: SideNavItem = {
  key: SideNavItemKey.PRODUCTS,
  icon: "cubes",
  path: SUBSCRIPTIONS_SHARED_ROUTE.path,
  label: "Header_Products",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_PRODUCT_DETAILS_ITEM: SideNavItem = {
  key: SideNavItemKey.PRODUCT_DETAILS,
  icon: "",
  path: SUBSCRIPTION_DETAILS_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_NEW_REQUEST_ITEM: SideNavItem = {
  key: SideNavItemKey.NEW_REQUEST,
  icon: "bookmark-add",
  path: MY_REQUESTS_SHARED_ROUTE.path,
  label: "NewRequestCDM3_Breadcrumb_NewRequest",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_MY_REMOTE_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_REMOTE,
  icon: "desktop-dashboard",
  path: DASHBOARD_REQUESTS_SHARED_ROUTE.path,
  label: "MenuItem_MyRemote",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: Constants.FullRolesSupported,
  isAccountMappedRequired: true,
};

export const SIDE_NAV_ALL_REQUESTS_ITEM: SideNavItem = {
  key: SideNavItemKey.ALL_REQUESTS,
  icon: "desktop-apps",
  path: DASHBOARD_REQUESTS_SHARED_ROUTE.path,
  label: "YouRequests",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_REQUEST_DETAILS_ITEM: SideNavItem = {
  key: SideNavItemKey.REQUEST_DETAILS,
  icon: "",
  path: DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: Constants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_HARDWARE_ITEM: SideNavItem = {
  key: SideNavItemKey.HARDWARE,
  icon: "vci",
  path: DASHBOARD_HARDWARE_ROUTE.path,
  label: "SettingsMyHardware_Headline",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_TEAM_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM,
  icon: "team-5",
  path: DASHBOARD_TEAM_SHARED_ROUTE.path,
  label: "DashboardCDM3_MainMenu_Team_Item",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_TEAM_CREATE_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM_CREATE,
  icon: "",
  path: DASHBOARD_TEAM_CREATE_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_TEAM_EDIT_ITEM: SideNavItem = {
  key: SideNavItemKey.TEAM_EDIT,
  icon: "",
  path: DASHBOARD_TEAM_EDIT_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
  isHideOnSideNavigation: true,
};

export const SIDE_NAV_WALLET_ITEM: SideNavItem = {
  key: SideNavItemKey.WALLET,
  icon: "coins",
  path: DASHBOARD_WALLET_CDM_3_ROUTE.path,
  label: "DashboardCDM3_WalletInfo_Wallet_Title",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_MY_PRODUCTS_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_PRODUCTS,
  icon: "car-connected",
  path: DASHBOARD_MY_SUBSCRIPTION_CDM_3_ROUTE.path,
  label: "MenuHeaderPopup_MyAccount_Products",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_STATISTIC_ITEM: SideNavItem = {
  key: SideNavItemKey.STATISTICS,
  icon: "bar-chart-search-detail",
  path: DASHBOARD_STATISTICS_SHARED_ROUTE.path,
  label: "MenuItem_Statistics",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: [Constants.USER_ROLE_MANAGER_ID],
};

export const SIDE_NAV_MY_BOSCH_ITEM: SideNavItem = {
  key: SideNavItemKey.MY_BOSCH,
  icon: "my-brand-frame",
  path: MY_BOSCH_SHARED_ROUTE.path,
  label: "MenuItem_MyBosch",
  isActive: true,
  isLoginRequired: true,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_SUPPORT_ITEM: SideNavItem = {
  key: SideNavItemKey.SUPPORT,
  icon: "customerservice",
  path: SUPPORT_SHARED_ROUTE.path,
  label: "MenuHeaderPopup_Support_Headline",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_SHOPPING_CART_ITEM: SideNavItem = {
  key: SideNavItemKey.SHOPPING_CART,
  icon: "shoppingcart",
  path: SHOPPING_CART_SHARED_ROUTE.path,
  label: "Breadcrumb_ShoppingCart",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: Constants.FullRolesSupported,
};

export const SIDE_NAV_CHECKOUT_ITEM: SideNavItem = {
  key: SideNavItemKey.CHECKOUT,
  icon: "",
  path: CHECKOUT_SHARED_ROUTE.path,
  label: "",
  isActive: true,
  isLoginRequired: false,
  supportedRoles: Constants.FullRolesSupported,
  isHideOnSideNavigation: true,
};

export const STATIC_SIDE_NAV_ITEM = "static_side_nav_item";
