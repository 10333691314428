import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import SupportRequestModel from "../models/support/supportRequest.model";
import { getSupportRequestsAsync } from "./supportSlide";
import {
  ContactLanguage,
  ContactLanguageQueryFields,
  TitulationQueryFields,
  Titulations,
} from "../models/dashboard/DashboardModel";
import ResponseResultBase from "../models/ResponseResultBase";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import http from "../services/HttpService";

export const getTitulationsAsync = createAsyncThunk<
  ResponseResultBase<Titulations[]> | null,
  TitulationQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/GetTitulations",
  async (titleInfo: TitulationQueryFields, thunkApi) => {
    try {
      const model = {
        // ciamId: accountInfo.ciamId,
        // customerId: accountInfo.customerId,
        // country: accountInfo.countryCode,
        language: titleInfo.language,
      };

      const response = await http.post<ResponseResultBase<Titulations[]>>(
        `/api/v1/contactpersons/GetTitulations`,
        model
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactLanguagesAsync = createAsyncThunk<
  ResponseResultBase<ContactLanguage[]> | null,
  ContactLanguageQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/GetLanguages", async (req: ContactLanguageQueryFields, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ContactLanguage[]>>(
      `/api/v1/support/GetLanguages?CountryCode=${req.countryCode}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export interface DashboardState {
  supportRequests: SupportRequestModel[];
  selectedSupportRequest: SupportRequestModel | null;
}

const initialState: DashboardState = {
  supportRequests: [],
  selectedSupportRequest: null,
};

export const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    setSelectedSupportRequest: (
      state,
      action: PayloadAction<SupportRequestModel | null>
    ) => {
      return {
        ...state,
        selectedSupportRequest: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupportRequestsAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        supportRequests: payload?.dataObject || [],
      };
    });
  },
});

export const selectSupportRequests = (rootState: RootState) =>
  rootState.dashboard.supportRequests;
export const selectSelectedSupportRequest = (rootState: RootState) =>
  rootState.dashboard.selectedSupportRequest;
export const { setSelectedSupportRequest } = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const getVehicleNameOfSupportRequestCDMUS = (
  request: SupportRequestModel
) => {
  return `${request.year} ${request.make} ${request.model}`;
};
