import { useTranslation } from "react-i18next";
import RDSearchInput from "../../../../components/RDComponents/RDSearchInput";
import style from "./AdsVehicleIdentificationSearch.module.scss";
import { capitalizeString } from "../../../../utils/text.util";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  selectFormValues,
  updateFormValues,
  updateSearchResult,
} from "../../../../redux/newRequestSlice";
import { useSelector } from "react-redux";
import { ADS_VIN_SEARCH_QUERY_KEY } from "../../../constants/adsNewRequest.constant";
import { getVehicleInfoBySearchMethodAsync } from "../../../../services/VehicleInformationService";
import {
  LICENSE_PLATE_FORMAT_LIST,
  SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
  SEARCH_BY_VIN_METHOD_ID,
} from "../../../../constants/automaticSearch.constants";
import CustomLoader from "../../../../components/loading/CustomLoader";
import AdsBlockTitle from "../../../components/adsBlockTitle/AdsBlockTitle";
import { selectCountryCode } from "../../../../redux/auth";
import AdsSearchByPlateOnUS from "../adsSearchByPlateOnUS/AdsSearchByPlateOnUS";
import {
  AdsSearchVehicleMode,
  AdsVehicleIdentificationErrors,
} from "../../../types/adsNewRequest.type";
import {
  BACK_SPACE_PRESSED_TYPE,
  DELETE_KEY_PRESSED_TYPE,
} from "../../../../constants/sematicInputType.constants";
import { autoFormatLicensePlate } from "../../../../utils/automaticSearch.util";
import RDAlert from "../../../../components/RDComponents/RDAlert";
import useCaseInsensitiveSearchParams from "../../../../hooks/useCaseInsensitiveSearchParams";

type Props = {
  vinError: string;
  plateError: string;
  setErrors: Dispatch<SetStateAction<AdsVehicleIdentificationErrors>>;
  setAutoValidateEnabled: Dispatch<SetStateAction<boolean>>;
  setShowEditVehicleComponent: Dispatch<SetStateAction<boolean>>;
};

const AdsVehicleIdentificationSearch = ({
  vinError,
  plateError,
  setErrors,
  setAutoValidateEnabled,
  setShowEditVehicleComponent,
}: Props) => {
  const { t } = useTranslation();
  const countryCode = useSelector(selectCountryCode);
  const { getParam } = useCaseInsensitiveSearchParams();
  const dispatch = useAppDispatch();
  const formValues = useSelector(selectFormValues);
  const vinParam = getParam(ADS_VIN_SEARCH_QUERY_KEY);
  const [searchByVinErrorMessage, setSearchByVinErrorMessage] =
    useState(vinError);
  const [searchByPlateErrorMessage, setSearchByPlateErrorMessage] =
    useState(plateError);
  const [isShowNotFoundVehicleModal, setShowNotFoundVehicleModal] =
    useState(false);
  const [isEditingOnVin, setEditingOnVin] = useState(false);
  const [isEditingOnPlate, setEditingOnPlate] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setSearchByVinErrorMessage(vinError);
  }, [vinError]);

  useEffect(() => {
    setSearchByPlateErrorMessage(plateError);
  }, [plateError]);

  useEffect(() => {
    if (
      !formValues.searchCountry ||
      !formValues.searchCountryCode ||
      !formValues.searchMethodId ||
      !!formValues.isAutoSearchByVinExecuted
    )
      return;

    if (!vinParam || !!validateSearchKeyword(SEARCH_BY_VIN_METHOD_ID, vinParam))
      return;

    onSearchVehicle(SEARCH_BY_VIN_METHOD_ID, vinParam).finally(() =>
      dispatch(
        updateFormValues({
          isAutoSearchByVinExecuted: true,
        })
      )
    );
  }, [
    formValues.searchCountry,
    formValues.searchCountryCode,
    formValues.searchMethodId,
    formValues.isAutoSearchByVinExecuted,
  ]);

  useEffect(() => {
    if (
      formValues.searchMethodId !== SEARCH_BY_VIN_METHOD_ID ||
      !isEditingOnVin
    ) {
      setSearchByVinErrorMessage("");
      return;
    }

    setSearchByVinErrorMessage(validateSearchKeyword(SEARCH_BY_VIN_METHOD_ID));
  }, [
    formValues.vin,
    formValues.searchMethodId,
    formValues.searchCountry,
    isEditingOnVin,
  ]);

  useEffect(() => {
    if (
      formValues.searchMethodId !== SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID ||
      !isEditingOnPlate
    ) {
      setSearchByPlateErrorMessage("");
      return;
    }

    setSearchByPlateErrorMessage(
      validateSearchKeyword(SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID)
    );
  }, [
    formValues.licensePlate,
    formValues.searchMethodId,
    formValues.searchCountry,
    isEditingOnPlate,
  ]);

  const validateSearchKeyword = (searchMethodId: number, vin?: string) => {
    const searchMethod = formValues.searchCountry?.searchMethods.find(
      (method) =>
        method.id ===
        (searchMethodId === SEARCH_BY_VIN_METHOD_ID
          ? SEARCH_BY_VIN_METHOD_ID
          : SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID)
    );

    let searchError = "";

    if (
      !searchMethod ||
      !searchMethod.validation ||
      !searchMethod.validation.regex ||
      new RegExp(searchMethod.validation.regex).test(
        searchMethodId === SEARCH_BY_VIN_METHOD_ID
          ? vin || formValues.vin
          : formValues.licensePlate || ""
      )
    )
      searchError = "";
    else
      searchError = t(
        formValues.searchMethodId === SEARCH_BY_VIN_METHOD_ID
          ? "RequestDetailRemoteDiagnostic_VIN_Length_Error"
          : "AutomaticSearch_InvalidInput"
      );

    return searchError;
  };

  const onKeyPress = (event: any) => {
    if (event.charCode === 13 && !searchByVinErrorMessage) {
      onSearchVehicle(SEARCH_BY_VIN_METHOD_ID);
    }
  };

  const onSearchVehicle = async (methodId: number, vin?: string) => {
    try {
      const vinError = validateSearchKeyword(SEARCH_BY_VIN_METHOD_ID);

      if (methodId === SEARCH_BY_VIN_METHOD_ID && !!vinError) {
        setErrors((prev) => ({
          ...prev,
          vin: vinError,
        }));
        setSearchByVinErrorMessage(vinError);
        return;
      }

      const plateError = validateSearchKeyword(
        SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID
      );

      if (
        methodId === SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID &&
        countryCode.trim().toLowerCase() !== "us" &&
        !!plateError
      ) {
        setErrors((prev) => ({
          ...prev,
          vin: plateError,
        }));
        setSearchByPlateErrorMessage(plateError);
        return;
      }

      setShowEditVehicleComponent(true);

      dispatch(updateSearchResult(null));
      dispatch(
        updateFormValues({
          brandSelected: "",
          modelSelected: "",
          engineSelected: 0,
          yearSelected: 0,
          variantSelected: "",
        })
      );
      setAutoValidateEnabled(true);
      setShowLoader(true);
      const searchValue =
        methodId === SEARCH_BY_VIN_METHOD_ID
          ? vin || formValues.vin
          : formValues.licensePlate || "";
      const result: any = await dispatch(
        getVehicleInfoBySearchMethodAsync({
          countryCode: formValues.searchCountryCode,
          searchValue,
          searchMethodId: methodId,
          state: formValues.searchCountryState,
        })
      );

      if (!result?.payload) setShowNotFoundVehicleModal(true);

      setShowLoader(false);
    } catch {
      dispatch(
        updateFormValues({
          brandSelected: "",
          modelSelected: "",
          engineSelected: 0,
          yearSelected: 0,
          variantSelected: "",
        })
      );
      setShowLoader(false);
    }
  };

  const onSearchInputChanged = (value: string, field: AdsSearchVehicleMode) => {
    setErrors((prev) => ({
      ...prev,
      [field === "vin" ? "vin" : "plate"]: "",
    }));

    if (field === "vin" && !isEditingOnVin) setEditingOnVin(true);
    else if (field === "licensePlate" && !isEditingOnPlate)
      setEditingOnPlate(true);

    dispatch(
      updateFormValues({
        [field]: value,
        searchMethodId:
          field === "vin"
            ? SEARCH_BY_VIN_METHOD_ID
            : SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
      })
    );
  };

  const onClearAutomaticSearchField = (field: AdsSearchVehicleMode) => {
    dispatch(
      updateFormValues({
        [field]: "",
      })
    );
  };

  const getMaxLengthOfSearchInput = (
    searchMethodId: number,
    field: AdsSearchVehicleMode
  ) => {
    if (field === "vin" && !isEditingOnVin) return 0;
    if (field === "licensePlate" && !isEditingOnPlate) return 0;

    const targetSearchMethod = formValues.searchCountry?.searchMethods.find(
      (method) => method.id === searchMethodId
    );

    return targetSearchMethod?.validation?.maxLength || 0;
  };

  const isAbleToShowSearchByPlate = () => {
    return !!formValues.searchCountry?.searchMethods.find(
      (method) => method.id === SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID
    );
  };

  const onKeyPressOnSearchByPlate = (event: any) => {
    if (event.charCode === 13 && !searchByPlateErrorMessage) {
      onSearchVehicle(SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID);
    }
  };

  const onLicensePlateInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    { value }: { value: string }
  ) => {
    const inputType = (event.nativeEvent as any).inputType;
    const searchValidation = LICENSE_PLATE_FORMAT_LIST.find(
      (item) =>
        item.countryCode.toLowerCase() ===
        formValues.searchCountryCode.toLowerCase()
    );
    const isAbleToFormat = ![
      BACK_SPACE_PRESSED_TYPE,
      DELETE_KEY_PRESSED_TYPE,
    ].includes(inputType);

    dispatch(
      updateFormValues({
        licensePlate:
          isAbleToFormat && searchValidation
            ? autoFormatLicensePlate(value, searchValidation.autoFormatRule)
            : value,
        searchMethodId: SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
      })
    );
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.box}>
          <div className={style.block}>
            <AdsBlockTitle title={t("ADS_SearchByVin")} />
            <RDSearchInput
              type="search"
              title={`${capitalizeString(
                t("RequestDetailRemoteDiagnostic_FieldTitle_VIN")
              )} *`}
              onKeyPress={onKeyPress}
              onChange={(_: any, { value }: any) =>
                onSearchInputChanged(value, "vin")
              }
              value={formValues.vin}
              onClear={() => onClearAutomaticSearchField("vin")}
              onSearch={() => onSearchVehicle(SEARCH_BY_VIN_METHOD_ID)}
              errorMessage={vinError || searchByVinErrorMessage}
              maxLength={
                !!vinError
                  ? 0
                  : getMaxLengthOfSearchInput(SEARCH_BY_VIN_METHOD_ID, "vin")
              }
              onClick={() => setEditingOnVin(true)}
              onBlur={() => setEditingOnVin(false)}
            />
          </div>
          {isAbleToShowSearchByPlate() ? (
            <div className={style.block}>
              <AdsBlockTitle title={t("ADS_SearchByPlate")} />
              {countryCode.trim().toLowerCase() !== "us" ? (
                <RDSearchInput
                  type="search"
                  title={`${capitalizeString(
                    t("AutomaticSearch_SearchMethod_Id10")
                  )}`}
                  placeholder={
                    formValues.searchCountry?.searchMethods.find(
                      (method) =>
                        method.id === SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID
                    )?.placeHolder || ""
                  }
                  onKeyPress={onKeyPressOnSearchByPlate}
                  onChange={onLicensePlateInputChange}
                  value={formValues.licensePlate || ""}
                  onClear={() => onClearAutomaticSearchField("licensePlate")}
                  onSearch={() =>
                    onSearchVehicle(SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID)
                  }
                  errorMessage={searchByPlateErrorMessage}
                  maxLength={
                    !!plateError
                      ? 0
                      : getMaxLengthOfSearchInput(
                          SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID,
                          "licensePlate"
                        )
                  }
                  onClick={() => setEditingOnPlate(true)}
                  onBlur={() => setEditingOnPlate(false)}
                />
              ) : (
                <AdsSearchByPlateOnUS
                  onSearch={() =>
                    onSearchVehicle(SEARCH_BY_REGISTRATION_NUMBER_METHOD_ID)
                  }
                  error={searchByPlateErrorMessage}
                  setError={setSearchByPlateErrorMessage}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {isShowNotFoundVehicleModal ? (
        <RDAlert
          isOpen
          type="error"
          title={t("Error_Title")}
          messages={[t("AutomaticSearch_VehicleNotFound_Message")]}
          acceptButtonText={t("SummaryPage_Close")}
          onAccept={() => setShowNotFoundVehicleModal(false)}
        />
      ) : (
        <></>
      )}
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default AdsVehicleIdentificationSearch;
