import { Redirect, useHistory } from "react-router-dom";
import { useUserSubscription } from "./useUserSubscription";
import { useSelector } from "react-redux";
import { selectAccount, selectAccountSubscription } from "../redux/account";
import {
  selectCdmVersion,
  selectCountryCode,
  selectIsSignIn,
} from "../redux/auth";
import { applicationRouters } from "../router/router.config";
import { CdmVersion } from "../models/pageConfiguration/CdmVersion";
import { RouteConfigure } from "../models/Route";
import { DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE } from "../constants/routes/sharedRoutes.constants";
import { REQUEST_ID_PARAM } from "../constants/commonParams.constant";

export const useRouting = () => {
  const history = useHistory();
  const { checkSubscriptionIsTrial, checkUserIsMapped, isBranchAccount } =
    useUserSubscription();
  const countryCode = useSelector(selectCountryCode);
  const userSubscription = useSelector(selectAccountSubscription);
  const currentAccount = useSelector(selectAccount);
  const cdmVersion = useSelector(selectCdmVersion);
  const isLoggedIn = useSelector(selectIsSignIn);

  const isWalletAndMyProductsRouteAccessible = () => {
    return (
      !isBranchAccount() && !checkSubscriptionIsTrial() && !!checkUserIsMapped()
    );
  };

  /**
   * @param pathname ex: /regions
   * @param state insideProps.state params of render route function
   * @returns
   */
  const redirectToRoute = (pathname: string, state: any) => {
    return (
      <Redirect
        to={{
          pathname: pathname,
          state,
        }}
      />
    );
  };

  const redirectTo401Page = (from: any) => {
    return redirectToRoute("/exception?type=401", {
      state: {
        from,
      },
    });
  };

  const isAbleToShowSwitchCountryModal = () => {
    if (!currentAccount || !!currentAccount.companyCountry) return false;

    return (
      countryCode.toLowerCase() !== currentAccount.countryCode.toLowerCase() &&
      !checkUserIsMapped() &&
      !userSubscription
    );
  };

  const isAbleToShowRedirectCountryModal = () => {
    const targetCountry =
      currentAccount?.companyCountry || currentAccount?.countryCode;

    return (
      !!userSubscription &&
      targetCountry?.toLowerCase() !== countryCode.toLowerCase()
    );
  };

  const getSupportedRoutes = (): RouteConfigure[] => {
    const originalRoutes = applicationRouters[cdmVersion as CdmVersion] || [];
    const supportedRoutes: RouteConfigure[] = [];

    for (const route of originalRoutes) {
      if (!!route.isProtected && !isLoggedIn) continue;

      supportedRoutes.push(route);
    }

    return supportedRoutes;
  };

  const getHomeRoute = (): RouteConfigure => {
    const originalRoutes = applicationRouters[cdmVersion as CdmVersion] || [];

    return originalRoutes.find((route) => route.path === "/") as RouteConfigure;
  };

  const getCurrentRoute = (): RouteConfigure | undefined => {
    const homeRoute = getHomeRoute();
    const originalRoutes = applicationRouters[cdmVersion as CdmVersion] || [];
    const browserPathName = window.location.pathname;

    if (browserPathName === "/") return homeRoute;

    for (const route of originalRoutes) {
      if (!route.path.includes(":")) {
        if (route.path === browserPathName) return route;

        continue;
      }

      let convertedPathName = route.path.split(":")[0];
      convertedPathName = convertedPathName.slice(
        0,
        convertedPathName.length - 1
      );

      const pathNameAsArray = browserPathName.split("/");
      const convertedPathNameAsArray = convertedPathName.split("/");

      const referencePathNameAsArray: string[] = [];

      for (let i = 0; i < convertedPathNameAsArray.length; ++i)
        referencePathNameAsArray.push(pathNameAsArray[i]);

      if (referencePathNameAsArray.join("/") === convertedPathName)
        return route;
    }

    return undefined;
  };

  const goToRequestDetailsPage = (requestId: number) => {
    history.push(
      DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE.path.replace(
        `:${REQUEST_ID_PARAM}`,
        requestId.toString()
      )
    );
  };

  return {
    redirectToRoute,
    redirectTo401Page,
    isWalletAndMyProductsRouteAccessible,
    isAbleToShowSwitchCountryModal,
    isAbleToShowRedirectCountryModal,
    getSupportedRoutes,
    getCurrentRoute,
    goToRequestDetailsPage,
  };
};
