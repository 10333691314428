import { createAsyncThunk } from "@reduxjs/toolkit";
import ContactPerson, {
  GetContactPersonByGuidRequestModel,
  MapOrgAccountInvitationRequestModel,
  MapPersonalUserAccountByInvitationCodeModel,
  MapPersonalUserAccountModel,
  ReactivatePersonalUserAccountModel,
  SendInvitationEmailRequestModel,
  UnmapPersonalUserAccountModel,
} from "../models/ContactPerson";
import { RequestContactPerson } from "../models/contactPerson/request.contactPerson.model";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import { SimpleAccountEmployeeModel } from "../models/Account";
import http from "./HttpService";
import ResponseResultBase from "../models/ResponseResultBase";
import WorkshopRole, { WorkshopRoleQueryFields } from "../models/WorkshopRole";
import { ResponseCreateContactPersonModel } from "../models/contactPerson/response.createContactPerson.model";
import { TeamFavouriteModel } from "../models/contactPerson/teamFavourite.model";
import { RequestCreateContactPersonFavoriteModel } from "../models/contactPerson/request.createContactPersonFavourite.model";

export const getContactPersonAsync = createAsyncThunk<
  ContactPerson | null,
  RequestContactPerson,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("contactpersons/getContactPerson", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ContactPerson>>(
      `/api/v1/contactpersons/GetContactPerson?CiamId=${requestModel.ciamId}&contactPersonId=${requestModel.contactPersonId}&countryCode=${requestModel.countryCode}`
    );
    return response.data.dataObject as ContactPerson;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getContactPersonsAsync = createAsyncThunk<
  SimpleAccountEmployeeModel[] | null,
  RequestContactPerson,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("contactpersons/getContactPersons", async (requestModel, thunkApi) => {
  try {
    const response = await http.get<
      ResponseResultBase<SimpleAccountEmployeeModel[]>
    >(
      `/api/v1/contactpersons/GetContactPersons?CiamId=${requestModel.ciamId}&CountryCode=${requestModel.countryCode}`
    );
    return response.data.dataObject as SimpleAccountEmployeeModel[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getWorkshopRolesAsync = createAsyncThunk<
  WorkshopRole[] | null,
  WorkshopRoleQueryFields,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/getWorkshopRoles",
  async (model: WorkshopRoleQueryFields, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<WorkshopRole[]>>(
        `/api/v1/contactpersons/${model.ciamId}/GetWorkshopRoles?CiamId=${model.ciamId}&Language=${model.language}`
      );
      return response.data.dataObject as WorkshopRole[];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const createContactPersonAsync = createAsyncThunk<
  ResponseResultBase<ResponseCreateContactPersonModel> | null,
  SimpleAccountEmployeeModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/createContactPerson",
  async (data: SimpleAccountEmployeeModel, thunkApi) => {
    let result: ResponseResultBase<ResponseCreateContactPersonModel> | null =
      null;
    try {
      const response = await http.post<
        ResponseResultBase<ResponseCreateContactPersonModel>
      >(`/api/v1/contactpersons`, data);
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const getContactPersonFavouriteAsync = createAsyncThunk<
  ResponseResultBase<TeamFavouriteModel> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/GetContactPersonFavourite",
  async (ciamId: string, thunkApi) => {
    let result: ResponseResultBase<TeamFavouriteModel> | null = null;
    try {
      const response = await http.get<ResponseResultBase<TeamFavouriteModel>>(
        `/api/v1/contactpersons/GetContactPersonFavourite?ciamId=${ciamId}`
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const createContactPersonFavouriteAsync = createAsyncThunk<
  ResponseResultBase<TeamFavouriteModel> | null,
  RequestCreateContactPersonFavoriteModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/CreateContactPersonFavourite",
  async (data: RequestCreateContactPersonFavoriteModel, thunkApi) => {
    let result: ResponseResultBase<TeamFavouriteModel> | null = null;
    try {
      const response = await http.post<ResponseResultBase<TeamFavouriteModel>>(
        `/api/v1/contactpersons/CreateContactPersonFavourite`,
        data
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const deleteContactPersonFavouriteAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/DeleteContactPersonFavourite",
  async (id: number, thunkApi) => {
    let result: ResponseResultBase<boolean> | null = null;
    try {
      const response = await http.post<ResponseResultBase<boolean>>(
        `/api/v1/contactpersons/DeleteContactPersonFavourite?id=${id}`
      );
      result = response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const sendInvitationAsync = createAsyncThunk<
  ResponseResultBase<any> | null,
  SendInvitationEmailRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/SendInvitation",
  async (req: SendInvitationEmailRequestModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<any>>(
        `/api/v1/contactpersons/SendInvitation`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getContactPersonByGuidAsync = createAsyncThunk<
  ResponseResultBase<ContactPerson> | null,
  GetContactPersonByGuidRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/GetContactPersonByGuid",
  async (model: GetContactPersonByGuidRequestModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<any>>(
        `/api/v1/contactpersons/GetContactPersonByGuid?ContactGuid=${model.contactGuid}&Country=${model.country}&Language=${model.language}`
      );
      return response.data.dataObject;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const mapPersonalUserAccount = createAsyncThunk<
  ResponseResultBase<null> | null,
  MapPersonalUserAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/MapPersonalUserAccount",
  async (req: MapPersonalUserAccountModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<null>>(
        `/api/v1/contactpersons/MapPersonalUserAccount`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const unmapPersonalUserAccount = createAsyncThunk<
  ResponseResultBase<null> | null,
  UnmapPersonalUserAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/MapPersonalUserAccount",
  async (req: UnmapPersonalUserAccountModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<null>>(
        `/api/v1/contactpersons/UnMapPersonalUserAccount`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const mapPersonalUserAccountByInvitationCode = createAsyncThunk<
  ResponseResultBase<null> | null,
  MapPersonalUserAccountByInvitationCodeModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/mapPersonalUserAccountByInvitationCode",
  async (req: MapPersonalUserAccountByInvitationCodeModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<null>>(
        `/api/v1/contactpersons/mapPersonalUserAccountByInvitationCode`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const mapOrgAccountInvitation = createAsyncThunk<
  ResponseResultBase<null> | null,
  MapOrgAccountInvitationRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "Organizations/MapOrgAccountInvitation",
  async (req: MapOrgAccountInvitationRequestModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<null>>(
        `/api/v1/Organizations/MapOrgAccountInvitation`,
        req
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const reactivatePersonalUserAccount = createAsyncThunk<
  boolean,
  ReactivatePersonalUserAccountModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/ReactivatePersonalUserAccount",
  async (req: ReactivatePersonalUserAccountModel, thunkApi) => {
    try {
      const response = await http.post<ResponseResultBase<null>>(
        `/api/v1/contactpersons/ReactivatePersonalUserAccount`,
        req
      );
      return response.data.success;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return false;
  }
);
