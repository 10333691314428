import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Grid, Icon, Image, Message } from "semantic-ui-react";
import { useAppDispatch } from "../../redux/hooks";
import boschSupergraphic from "../../assets/Bosch-Supergraphic_.png";
import logo from "../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as Menu } from "../../assets/svg/menu.svg";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { loginAsync, logoutAsync, selectAccount } from "../../redux/account";
import {
  selectAuthState,
  selectIsSignIn,
  selectCountryName,
  selectLanguage,
  authSetLanguage,
  selectCountryCode,
} from "../../redux/auth";
import { AppDispatch } from "../../redux/store";
import "../../translations/i18n";
import Breadcrumbs, { CrumbProps } from "./Breadcrumbs";
import "./Header.scss";
import HeaderCartButton from "./HeaderCartButton";
import SearchField from "./SearchField";
import { ReactComponent as Regions } from "../../assets/svg/locator.svg";
import { Constants } from "../../utils/helpers/Constants";
import ChangeCompanyModal from "../CompanyModal/ChangeCompanyModal";
import Boschicon from "../BoschIcon/Boschicon";
import { useDashboardConfiguration } from "../../hooks/pageConfiguration/useDashboardConfiguration";
import { useHeaderConfiguration } from "../../hooks/pageConfiguration/useHeaderConfiguration";
import NewMenuHeader from "./menuHeader/NewMenuHeader";
import NewMenuHeaderMobile from "./menuHeader/NewMenuHeaderMobile";
import RDAlert from "../RDComponents/RDAlert";
import RDLanguageSelection from "../RDComponents/RDLanguageSelection/RDLanguageSelection";
import {
  selectIsForcedToHideNotificationBanner,
  updateForcedToHideNotificationBanner,
} from "../../redux/maintenanceSlice";
import {
  addClassNameForComponent,
  getHeightOfComponent,
  getWidthOfComponent,
  handleClickedOutOfComponent,
  isComponentContainsClassName,
  removeClassNameForComponent,
} from "../../utils/dom.util";
import { MaintenanceBannerConstants } from "../../utils/helpers/MaintenanceMessageConstants";
import { SERMI_SUPPORTED_COUNTRIES } from "../../constants/faq.constants";
import { useProceedNegativeCreditBalance } from "../../hooks/useProceedNegativeCreditBalance";

interface HeaderProps {
  crumbs?: CrumbProps[];
  isInLandingPage?: boolean;
}

const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const authState = useSelector(selectAuthState);
  const { dashboardConfig } = useDashboardConfiguration();
  const { headerConfig } = useHeaderConfiguration();
  const [isOpenMenuOnMobile, setOpenMenuOnMobile] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const reduxLanguage = useSelector(selectLanguage);
  const [language, setLanguage] = useState<string>("");
  const { i18n } = useTranslation();
  const [isOpenSwitchRegion, setIsOpenSwitchRegion] = useState(false);
  const [headerContent, setHeaderContent] = useState<string>("");
  const [isChangingCompany, setIsChangingCompany] = useState<boolean>(false);
  const account = useSelector(selectAccount);
  const countryName = useSelector(selectCountryName);
  const isSignIn = useSelector(selectIsSignIn);
  const { isNegativeBalance } = useProceedNegativeCreditBalance();

  const [isShowConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const [isLogoutExecuting, setIsLogoutExecuting] = useState(false);
  const [isMaintenanceScheduleOn, setIsMaintenanceScheduleOn] = useState(false);
  const maintenanceScheduleDatetime = "19/01/2024 5:00 AM (CET)";
  const maintenanceSupportEmail = "remote.diagnostics@bosch.com";

  const [isShowTHLMessage, setIsShowTHLMessage] = useState(false);
  const [notificationTHLMessage, setNotificationTHLMessage] = useState("");

  const [isShowPPUMessage, setIsShowPPUMessage] = useState(false);
  const [notificationPPUMessage, setNotificationPPUMessage] = useState("");

  const [isShowSERMIMessage, setIsShowSERMIMessage] = useState(false);
  const [notificationSERMIMessage, setNotificationSERMIMessage] = useState("");

  const countryCode = useSelector(selectCountryCode);
  const isForceToHideNotificationBanner = useSelector(
    selectIsForcedToHideNotificationBanner
  );

  // For sticky header
  const maintenanceBlockRef = useRef<HTMLDivElement | null>(null);
  const superGraphicRef = useRef<HTMLDivElement | null>(null);
  const headerTopRef = useRef<HTMLDivElement | null>(null);
  const headerMenuRef = useRef<HTMLDivElement | null>(null);
  const searchBarRef = useRef<HTMLDivElement | null>(null);

  const languageOptions =
    !!authState.languages && authState.languages.length > 0
      ? authState.languages.map((x) => {
          return {
            key: x.localized,
            text: x.localized,
            value: x.lcid,
          };
        })
      : [
          {
            key: "English",
            text: "English",
            value: "en-US",
          },
        ];

  useEffect(() => {
    window.addEventListener("scroll", toggleAllStickyComponent);

    return () => {
      window.removeEventListener("scroll", toggleAllStickyComponent);
    };
  }, [window.screen.availWidth, window.screen.availHeight]);

  useEffect(() => {
    if (countryCode) {
      // Check for THL notification messages
      if (
        MaintenanceBannerConstants.ENABLE_THL_MESSAGE_COUNTRIES.includes(
          countryCode?.toUpperCase()
        )
      ) {
        const thlMessage = MaintenanceBannerConstants.THLPhaseOutMessages.find(
          (pair) => {
            return pair.country?.toUpperCase() === countryCode?.toUpperCase();
          }
        );

        setIsShowTHLMessage(true);
        setNotificationTHLMessage(thlMessage?.message ?? "");
      } else {
        setIsShowTHLMessage(false);
        setNotificationTHLMessage("");
      }

      // Check for PPU to Credit notification messages
      if (
        MaintenanceBannerConstants.ENABLE_PPU_MESSAGE_COUNTRIES.includes(
          countryCode?.toUpperCase()
        )
      ) {
        const ppuMessage = MaintenanceBannerConstants.PPUToCreditMessages.find(
          (pair) => {
            return pair?.language?.toUpperCase() === language?.toUpperCase();
          }
        );

        setIsShowPPUMessage(
          !SERMI_SUPPORTED_COUNTRIES.includes(countryCode.toUpperCase())
        );
        setNotificationPPUMessage(
          ppuMessage?.message ?? MaintenanceBannerConstants.DEFAULT_PPU_MESSAGE
        );
      } else {
        setIsShowPPUMessage(false);
        setNotificationPPUMessage("");
      }

      if (SERMI_SUPPORTED_COUNTRIES.includes(countryCode.toUpperCase())) {
        const targetCountry =
          MaintenanceBannerConstants.SERMI_PHASE_OUT_MESSAGES.find(
            (pair) => pair.language.toLowerCase() === language.toLowerCase()
          );
        const faqLink = `${window.location.origin}/support`;
        const message = (
          targetCountry?.message ||
          MaintenanceBannerConstants.DEFAULT_SERMI_PHASE_OUT_MESSAGE
        ).replace("{link}", `<a href=${faqLink}>${faqLink}</a>`);

        setIsShowSERMIMessage(true);
        setNotificationSERMIMessage(message);
      } else {
        setIsShowSERMIMessage(false);
        setNotificationSERMIMessage("");
      }
    }
  }, [countryCode, language]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) =>
      handleClickedOutOfComponent(e, searchBarRef, () => setIsSearch(false));

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    if (!isSearch || !searchBarRef.current) return;

    searchBarRef.current.style.top =
      getHeightOfComponent(maintenanceBlockRef) +
      getHeightOfComponent(superGraphicRef) +
      getHeightOfComponent(headerTopRef) / 2 -
      getHeightOfComponent(searchBarRef) / 2 +
      "px";
    searchBarRef.current.style.opacity = "1";
  }, [isSearch]);

  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setHeaderContent(
        t("DashboardCDM3_Header_Greeting").concat(
          " ",
          account?.contactFirstName || "",
          "!"
        )
      );
      return;
    }
    const mapItem = Constants.headerDashboard.find((x) =>
      window.location.pathname.startsWith(x[0])
    );
    if (mapItem) {
      setHeaderContent(t(mapItem[1]));
    }
  }, [window.location.pathname, language]);

  useEffect(() => {
    setLanguage(reduxLanguage ?? "");
  }, [reduxLanguage]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      const el = document.getElementById("top-menu");
      if (isOpenMenuOnMobile && el && !el?.contains(e.target)) {
        setOpenMenuOnMobile(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpenMenuOnMobile]);

  const toggleAllStickyComponent = () => {
    if (
      !superGraphicRef.current ||
      !headerTopRef.current ||
      !headerMenuRef.current
    )
      return;

    if (
      window.scrollY > getHeightOfAllStickyComponents() &&
      isAbleToTriggerStickyMenuHeader()
    ) {
      addClassNameForComponent(
        superGraphicRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );

      const headerTopMarginTop = getHeightOfComponent(superGraphicRef);

      addClassNameForComponent(
        headerTopRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );
      headerTopRef.current.style.top = headerTopMarginTop + "px";

      addClassNameForComponent(
        headerMenuRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );
      headerMenuRef.current.style.top =
        headerTopMarginTop + getHeightOfComponent(headerTopRef) + "px";

      if (
        window.innerWidth >
        Constants.DISPLAY_WIDTH_ABLE_TO_SET_LEFT_FOR_STICKY_HEADER
      ) {
        const headerTopMarginLeft = getMarginLeftOfStickyComponent();

        headerTopRef.current.style.left = headerTopMarginLeft + "px";
        headerMenuRef.current.style.left = headerTopMarginLeft + "px";
      } else {
        headerTopRef.current.style.left = "0px";
        headerMenuRef.current.style.left = "0px";
      }
    } else {
      removeClassNameForComponent(
        superGraphicRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );
      removeClassNameForComponent(
        headerTopRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );
      removeClassNameForComponent(
        headerMenuRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      );
    }
  };

  const getMarginLeftOfStickyComponent = () => {
    if (!headerTopRef.current) return 0;

    return (document.body.clientWidth - getWidthOfComponent(headerTopRef)) / 2;
  };

  const isAbleToTriggerStickyMenuHeader = () => {
    if (
      !superGraphicRef.current ||
      !headerTopRef.current ||
      !headerMenuRef.current
    )
      return false;

    const screenHeight = window.screen.height;
    const bodyHeight = document.body.scrollHeight;
    const stickyComponentsHeight = getHeightOfAllStickyComponents();

    if (
      isComponentContainsClassName(
        headerTopRef,
        Constants.HEADER_STICKY_MODE_CLASSNAME
      )
    )
      return bodyHeight - screenHeight > 0;

    return bodyHeight - screenHeight > stickyComponentsHeight * 3.5;
  };

  const getHeightOfAllStickyComponents = () => {
    return (
      getHeightOfComponent(maintenanceBlockRef) +
      getHeightOfComponent(superGraphicRef) +
      getHeightOfComponent(headerTopRef) +
      getHeightOfComponent(headerMenuRef)
    );
  };

  const handleCloseMaintenanceBanner = () => {
    setIsMaintenanceScheduleOn(false);
    setIsShowTHLMessage(false);
    dispatchReduxToolkit(updateForcedToHideNotificationBanner(true));
  };

  const switchLanguage = (data: any) => {
    setLanguage(data);
    i18n.changeLanguage(data.trim());
    dispatchReduxToolkit(authSetLanguage(data));
  };

  const login = () => {
    dispatchReduxToolkit(loginAsync({ redirectUrl: window.location.pathname }));
  };

  const logout = async () => {
    await dispatchReduxToolkit(logoutAsync());
  };

  const getLogoTargetUrl = () => {
    return isNegativeBalance() ? window.location.pathname : "/";
  };

  return (
    <Fragment>
      {(isMaintenanceScheduleOn ||
        isShowTHLMessage ||
        isShowPPUMessage ||
        isShowSERMIMessage) &&
        !isForceToHideNotificationBanner && (
          <div ref={maintenanceBlockRef} className="maintenance_block">
            <Message className="maintenance_message" info size="small">
              <div className="maintenance_detail">
                <Message.Content>
                  <Boschicon name="bosch-ic-service-time" />
                  <p>
                    {isMaintenanceScheduleOn && (
                      <>
                        <span>
                          {" "}
                          {t("MaintenanceSchedule_Message_1").replace(
                            "[maintenance_schedule]",
                            maintenanceScheduleDatetime || ""
                          )}
                        </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("MaintenanceSchedule_Message_2").replace(
                              "[support_email]",
                              "<a href='mailto:" +
                                maintenanceSupportEmail +
                                "' target='_blank'>" +
                                maintenanceSupportEmail +
                                "</a>"
                            ),
                          }}
                        ></span>
                        <br />
                      </>
                    )}
                    {isShowTHLMessage && (
                      <>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: notificationTHLMessage,
                          }}
                        ></span>
                        <br />
                      </>
                    )}
                    {isShowPPUMessage && <span>{notificationPPUMessage}</span>}
                    {isShowSERMIMessage && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " " + notificationSERMIMessage,
                        }}
                      ></span>
                    )}
                  </p>
                  <Boschicon
                    name="bosch-close"
                    onClick={handleCloseMaintenanceBanner}
                  />
                </Message.Content>
              </div>
            </Message>
          </div>
        )}

      <div ref={superGraphicRef} className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      {!props.isInLandingPage && isSearch ? (
        <div
          ref={searchBarRef}
          className="search-field-container search-responsive"
        >
          <SearchField
            showResults={true}
            emitCloseEvent={true}
            closeSearch={() => setIsSearch(false)}
          />
        </div>
      ) : (
        <></>
      )}

      <div
        ref={headerTopRef}
        className="sticky-component-wrapper page-header-top-wrapper"
      >
        <div className="grid-responsive-width page-header-top">
          <div className="header-large-box">
            <Link to={getLogoTargetUrl()} className="header-logo-container">
              <Image src={logo}></Image>
            </Link>
            {props.isInLandingPage && (
              <h4 className="landing-page-title">
                {t("LandingPageCDM3_Title")}
              </h4>
            )}
            {!props.isInLandingPage &&
              !window.location.href.toLowerCase().includes("/v3/checkout") && (
                <Grid className="header-buttons">
                  <Grid.Row only="computer tablet">
                    <headerConfig.AccountButton
                      className="header-icon-button"
                      isMobile={false}
                      login={login}
                      logout={logout}
                      changeCompany={() => {
                        setIsChangingCompany(true);
                      }}
                    />
                    <Button
                      className="header-icon-button icon-desktop"
                      basic
                      onClick={() => setIsOpenSwitchRegion(true)}
                      icon={
                        <Icon>
                          <Regions />
                        </Icon>
                      }
                    ></Button>
                    <Button
                      className="header-icon-button"
                      basic
                      content=""
                      onClick={() =>
                        setIsSearch((prevIsSearch) => !prevIsSearch)
                      }
                      icon={
                        <Icon>
                          <Search />
                        </Icon>
                      }
                      data-testid="global-search-button"
                    ></Button>
                    <HeaderCartButton
                      className="header-icon-button header-cart-cdm-3"
                      isMobile={false}
                    />
                    <RDLanguageSelection
                      currentLanguage={language}
                      languages={languageOptions.map(({ key, text, value }) => {
                        return {
                          key,
                          text,
                          value,
                        };
                      })}
                      onChangeLanguage={(newLang) => switchLanguage(newLang)}
                      className="language-selection-btn"
                    />
                  </Grid.Row>
                  <Grid.Row only="mobile">
                    <Button
                      className="header-icon-button"
                      basic
                      style={{
                        border: `${
                          isOpenMenuOnMobile ? "1px solid black" : "none"
                        }`,
                      }}
                      onClick={() => setOpenMenuOnMobile((prev) => !prev)}
                      icon={
                        <Icon>{isOpenMenuOnMobile ? <Close /> : <Menu />}</Icon>
                      }
                    ></Button>
                  </Grid.Row>
                </Grid>
              )}
          </div>
        </div>
      </div>
      {!props.isInLandingPage && isOpenMenuOnMobile ? (
        <NewMenuHeaderMobile
          isOpen={true}
          onClose={() => setOpenMenuOnMobile(false)}
          openChangeCompanyModal={() => setIsChangingCompany(true)}
          openChangeRegionModal={() => setIsOpenSwitchRegion(true)}
          openSearchBar={() => setIsSearch((prevIsSearch) => !prevIsSearch)}
          openConfirmLogoutModal={() => setShowConfirmLogoutModal(true)}
        />
      ) : (
        <></>
      )}
      {!props.isInLandingPage &&
        !window.location.href.toLowerCase().includes("/v3/checkout") && (
          <div ref={headerMenuRef} className="sticky-component-wrapper">
            <div className="grid-responsive-width ui-header-wrapper">
              <NewMenuHeader
                openConfirmLogoutModal={() => setShowConfirmLogoutModal(true)}
              />
            </div>
          </div>
        )}
      {!props.isInLandingPage &&
        isSignIn &&
        !window.location.href.toLowerCase().includes("/v3/checkout") &&
        !!headerContent && (
          <dashboardConfig.HeaderBar content={headerContent} />
        )}
      {!props.isInLandingPage && !!headerContent && (
        <div
          className={`breadcumb-box-account-cart grid-responsive-width ${
            !!headerContent && "breadcumb-box-account-cart-dashboard"
          } ${
            !!headerContent &&
            dashboardConfig.isNewDashboard &&
            "breadcumb-box-account-cart-dashboard-full-width"
          }`}
        >
          <Grid>
            <Grid.Row>
              <Breadcrumbs crumbs={props.crumbs} />
            </Grid.Row>
          </Grid>
          <Grid></Grid>
        </div>
      )}

      {isOpenSwitchRegion ? (
        <RDAlert
          type="warning"
          isOpen={true}
          // title={t("Popup_Title_Warning")}
          messages={[
            `${t("Header_CurrentRegion")} ${countryName}.&nbsp;`,
            t("Header_ChangeRegions"),
          ]}
          acceptButtonText={t("Header_YesButton")}
          onAccept={() => {
            history.push("/regions");
          }}
          cancelButtonText={t("Header_NoButton")}
          onCancel={() => setIsOpenSwitchRegion(false)}
        />
      ) : (
        <></>
      )}

      {isChangingCompany && (
        <ChangeCompanyModal
          setCloseModalTrigger={() => {
            setIsChangingCompany(false);
          }}
        />
      )}

      {isShowConfirmLogoutModal ? (
        <RDAlert
          type="warning"
          isOpen={true}
          // title={t("Popup_Title_Warning")}
          messages={[t("AccountButton_LogoutModal_Content")]}
          acceptButtonText={t("Header_YesButton")}
          onAccept={() => {
            setIsLogoutExecuting(true);
            logout();
          }}
          cancelButtonText={t("Header_NoButton")}
          onCancel={() => setShowConfirmLogoutModal(false)}
          isAcceptLoading={isLogoutExecuting}
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default Header;
