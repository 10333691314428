import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./store";
import httpPCS from "../services/HttpServicePCS";
import axios, { AxiosError } from "axios";
import ResponseResultBase from "../models/ResponseResultBase";

export interface MessageState {
  channelMessages: ChannelMessagesState[] | any[];
  nextToken: string | null;
}

export interface ChannelMessagesState {
  Content: any;
  Redacted: any;
  LastEditedTimestamp: string;
  Status: { Value: string };
  MessageId: any;
  CreatedTimestamp: string;
  Sender: {
    Name: any;
    Arn: any;
  };
  Metadata: any;
}

export interface ChannelState {
  messages: MessageState;
  ChannelArn: string;
  LastMessageTimestamp: any | null;
  Metadata: string;
  Mode: string;
  Name: string;
  Privacy: string;
  CreatedBy: {
    Arn: string;
    Name: string;
  };
  CreatedTimestamp: object | null;
  LastUpdatedTimestamp: any | null;
  ChannelFlowArn: string;
  chimeUserId: string;
  IsTranslate: {
    status: boolean;
    langSource: string;
    langTarget: string;
    languagebox: boolean;
  };
  apiKey: string;
}

export const initialMessageState: MessageState = {
  channelMessages: [],
  nextToken: null,
};

export const initialState: ChannelState = {
  messages: initialMessageState,
  ChannelArn: "",
  LastMessageTimestamp: null,
  Metadata: "",
  Mode: "",
  Name: "",
  Privacy: "",
  CreatedBy: {
    Arn: "",
    Name: "",
  },
  CreatedTimestamp: null,
  LastUpdatedTimestamp: null,
  ChannelFlowArn: "",
  chimeUserId: "",
  IsTranslate: {
    status: false,
    langSource: "auto",
    langTarget: "auto",
    languagebox: false,
  },
  apiKey: "",
};

export const getApiKeyChime = createAsyncThunk(
  "chat/getApiKey",
  async (data, thunkApi) => {
    let result: any;
    try {
      const response = await httpPCS.get(`/api/Ticket/GetApiKeyForCDM/`);
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        result = {
          dataObject: response.data,
          errorMessages: [],
          httpStatusCode: 200,
          success: true,
        };
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return result;
  }
);

export const deleteChannelPrivateAsync = createAsyncThunk<
  ResponseResultBase<boolean> | null,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("Chime/DeleteChannelPrivate", async (ticketId: number, thunkApi) => {
  try {
    const response = await httpPCS.delete<ResponseResultBase<boolean>>(
      `/api/Chime/DeleteChannelPrivate`,
      { data: { ticketId: ticketId.toString() } }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

const chatReducerSlice = createSlice({
  name: "chatReducer",
  initialState: initialState,
  reducers: {
    channelSetChannel: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ChannelArn: action.payload.ChannelArn,
        Name: action.payload.Name,
        CreatedBy: action.payload.CreatedBy,
        CreatedTimestamp: action.payload.CreatedTimestamp,
        LastUpdatedTimestamp: action.payload.LastUpdatedTimestamp,
        messages: initialMessageState,
      };
    },

    channelSetChannelArn: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ChannelArn: action.payload,
      };
    },

    channelSetMessage: (state, action: PayloadAction<MessageState>) => {
      return {
        ...state,
        messages: action.payload,
      };
    },

    channelClearState: (state, action: PayloadAction) => {
      return {
        ...state,
        messages: initialMessageState,
        ChannelArn: "",
        LastMessageTimestamp: null,
        Metadata: "",
        Mode: "",
        Name: "",
        Privacy: "",
        CreatedBy: {
          Arn: "",
          Name: "",
        },
        CreatedTimestamp: null,
        LastUpdatedTimestamp: null,
        ChannelFlowArn: "",
        chimeUserId: "",
      };
    },

    channelSetChimeUserId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        chimeUserId: action.payload,
      };
    },
    channelSetBriefInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        chimeUserId: action.payload.chimeUserId,
        ChannelArn: action.payload.channelArn,
        Name: action.payload.name,
      };
    },
    channelSetIsTranslate: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        IsTranslate: action.payload,
      };
    },
    channelSetApiKey: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        apiKey: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApiKeyChime.fulfilled, (state, { payload }) => {
      if (payload != null && payload.httpStatusCode === 200) {
        state.apiKey = payload.dataObject.value;
      }
    });
  },
});

export const {
  channelSetChannel,
  channelSetMessage,
  channelClearState,
  channelSetChimeUserId,
  channelSetChannelArn,
  channelSetBriefInfo,
  channelSetIsTranslate,
  channelSetApiKey,
} = chatReducerSlice.actions;
export default chatReducerSlice.reducer;
export const selectChannelState = (rootState: RootState) => rootState.chat;
export const selectChannelArn = (rootState: RootState) =>
  selectChannelState(rootState).ChannelArn;
export const selectChannelMessage = (rootState: RootState) =>
  selectChannelState(rootState).messages;
export const selectChannelName = (rootState: RootState) =>
  selectChannelState(rootState).Name;
export const selectChimeUserId = (rootState: RootState) =>
  selectChannelState(rootState).chimeUserId;
export const selectIsTranslate = (rootState: RootState) =>
  selectChannelState(rootState).IsTranslate;
export const selectApiKey = (rootState: RootState) =>
  selectChannelState(rootState).apiKey;
