import httpPCS from "./HttpServicePCS";

export const getChimeUser = async (chimeUser: any) => {
  try {
    const response = await httpPCS.post<any>(
      `/api/Chime/ChimeUserAccount/`,
      chimeUser
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getChimeSecret = async (apiKey: string) => {
  try {
    if (apiKey) {
      const headers = {
        ApiKey: apiKey,
      };
      const response = await httpPCS.get<any>(`/api/chime/GetChimeSecret/`, {
        headers,
      });
      return response.data;
    }
    return null;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const getPrivateKeySecret = async () => {
  try {
    const response = await httpPCS.get<any>(`/api/Ticket/GetSecretKeyForCDM/`);
    return response.data;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const getApiKey = async () => {
  try {
    const response = await httpPCS.get<any>(`/api/Ticket/GetApiKeyForCDM/`);
    return response.data.value;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const liveTranslateMessage = async (
  textContent: string,
  sourceLanguageCode: string,
  targetLanguageCode: string,
  apiKey: string
) => {
  if (
    sourceLanguageCode === "" ||
    targetLanguageCode === "" ||
    sourceLanguageCode === targetLanguageCode
  ) {
    return textContent;
  }
  const data = {
    text: textContent,
    sourceLanguageCode: sourceLanguageCode,
    targetLanguageCode: targetLanguageCode,
  };

  const headers = {
    ApiKey: apiKey,
  };
  const res = await httpPCS.post(`/api/Chime/TranslateMessage`, data, {
    headers,
  });
  return res.data.translatedText;
};
