export const AppConsts = {
  appBaseUrl: "",
  //remoteServiceBaseUrl: 'https://redi-hub-api.azurewebsites.net',
  //remoteServiceBaseUrl: 'https://app-dev-germanywestcentral-rediapi.azurewebsites.net',
  //remoteServiceBaseUrl: 'http://localhost:15825',
  appLanguages: [
    { key: "English", text: "English", value: "en" },
    { key: "Dansk", text: "Dansk", value: "da" },
  ],
  homeSideLink: [
    {
      countryCode: "AT",
      aboutUs: "https://www.bosch.at/unser-unternehmen/bosch-in-oesterreich/",
      news: "https://www.bosch.at/news-and-stories/technik-fuers-leben-preis/",
      position: "https://www.bosch.at/karriere/",
    },
    {
      countryCode: "BE",
      aboutUs: "https://www.bosch.be/our-company/our-history/",
      news: "https://www.bosch.be/news-and-stories/climatemattersatbosch-papillon-project/",
      position: "https://www.bosch.be/careers/",
    },
    {
      countryCode: "CH",
      aboutUs: "https://www.bosch.ch/unser-unternehmen/bosch-in-der-schweiz/",
      news: "https://www.bosch.ch/news-and-stories/geschaeftsjahr-2020/",
      position: "https://www.bosch.ch/karriere/starte-deine-karriere/",
    },
    {
      countryCode: "DK",
      aboutUs: "",
      news: "https://www.bosch.dk/nyheder/",
      position: "https://www.bosch.dk/karrierer/",
    },
    {
      countryCode: "DE",
      aboutUs: "https://www.bosch.de/unser-unternehmen/bosch-gruppe-weltweit/",
      news: "https://www.bosch.com/de/unternehmen/geschaeftsbericht/",
      position: "https://www.bosch.de/karriere/jobs/",
    },
    {
      countryCode: "ES",
      aboutUs: "https://www.grupo-bosch.es/nuestra-empresa/bosch-en-espana/",
      news: "https://www.grupo-bosch.es/productos-y-servicios/",
      position: "https://www.grupo-bosch.es/trabaja-con-nosotros/",
    },
    {
      countryCode: "FI",
      aboutUs: "",
      news: "https://www.bosch.fi/uutisia-ja-tarinoita/ces-2021-boschin-aiot-ratkaisuissa-tekoaely-ja-esineiden-internet-valjastetaan-ihmisen-ja-ympaeristoen-hyvaeksi/",
      position: "",
    },
    {
      countryCode: "LU",
      aboutUs: "https://www.bosch.be/our-company/our-history/",
      news: "https://www.bosch.be/news-and-stories/climatemattersatbosch-papillon-project/",
      position: "https://www.bosch.be/careers/",
    },
    {
      countryCode: "NL",
      aboutUs: "https://www.bosch.nl/ons-bedrijf/onze-geschiedenis/",
      news: "https://www.bosch.nl/nieuws/climatemattersatbosch-in-tilburg/",
      position: "https://www.bosch.nl/carriere/",
    },
    {
      countryCode: "NO",
      aboutUs: "",
      news: "https://www.bosch.no/nyheter/ces-2021-bosch-setter-sin-lit-til-kunstig-intelligens-og-tilkoblingsmuligheter/",
      position: "",
    },
    {
      countryCode: "SE",
      aboutUs: "",
      news: "https://www.bosch.se/nyheter/ces-2021-bosch-ai-system-anvaends-foer-att-navigera-minirobotar-pa-manen/",
      position: "https://www.bosch.se/jobba-hos-oss/",
    },
    {
      countryCode: "FR",
      // aboutUs: "https://www.bosch.fr/notre-entreprise/bosch-en-france/",
      aboutUs: "",
      news: "https://www.bosch.fr/actualites-et-stories/",
      position: "https://www.bosch.fr/carrieres/",
    },
  ],
  socketConnectionStatus: {
    online: 1,
    connecting: 2,
    disConnect: 3,
  },
};
export const DEFAULT_DISPLAYING_VALUE = "-";
