import { FC, useState, useRef } from "react";
import { Button, Grid, Icon, Menu } from "semantic-ui-react";
import "./styles/header.css";
import { useTranslation } from "react-i18next";
import Boschicon from "../../components/BoschIcon/Boschicon";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
import ModalTranlationOptions from "./ModalTranlationOptions";
import { useSelector } from "react-redux";
import { selectChannelName, selectIsTranslate } from "../../redux/chat";

export interface IHeader {
  title: string;
  onClose: () => void;
  onTranslateMode: (data: any) => any;
  translateModeData: any;
}

const Header: FC<IHeader> = ({ onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isTranslate = useSelector(selectIsTranslate);
  const ref = useRef<HTMLSelectElement>();
  const channelName = useSelector(selectChannelName);

  return (
    <div>
      <div className="sc-header">
        <div className="sc-header-right">
          <div className="sc-header-setting">
            <Button
              className="header-icon-button"
              id="menu-btn"
              basic
              style={{ border: `${open ? "1px solid black" : "none"}` }}
              onClick={() => setOpen(!open)}
              content={<span>{t("Header_Label_Settings")}</span>}
              icon={<Icon>{open ? <Close /> : <MenuIcon />}</Icon>}
            />
          </div>
          <div className="vertical-line"></div>
          <div className="sc-header-channel-name">
            {channelName || ""}
          </div>
        </div>
        <Button
          icon={<Icon name="close" size="large" />}
          basic
          onClick={onClose}
          className="sc-header--close-button"
        />
      </div>
      <Grid
        className={`menu-wrapper menu-setting-chat ${open ? "" : "display-none"
          }`}
      >
        <Grid.Row
          className={`slide-down-animation  ${open ? "visible" : ""}`}
          id="top-menu"
          ref={ref}
        >
          <Menu vertical borderless>
            {/* <Button
                  className={`header-icon-button ${translateMode ? "btn-translate-active" : ""}`}
                  basic
                  onClick={() => setOpenTranslateModal(true)}
                  content={t("Header_Label_Translation")}
                  icon={<Icon size='small'><Boschicon style={{ padding: "unset" }} name="bosch-ic-translate" /></Icon>}
                /> */}

            {isTranslate.languagebox && (
              <ModalTranlationOptions
                translateModeData={isTranslate}
                isOpen={isTranslate.languagebox}
              />
            )}

            <Button
              className={"header-icon-button"}
              basic
              disabled
              content={t("Header_Label_Share_Screen")}
              icon={
                <Icon size="small">
                  <Boschicon
                    style={{ padding: "unset" }}
                    name="bosch-ic-desktop"
                  />
                </Icon>
              }
            />
            <Button
              className={"header-icon-button"}
              basic
              disabled
              content={t("Header_Label_Microphone")}
              icon={
                <Icon size="small">
                  <Boschicon
                    style={{ padding: "unset" }}
                    name="bosch-ic-microphone-classic"
                  />
                </Icon>
              }
            />
            <Button
              className={"header-icon-button"}
              basic
              disabled
              content={t("Header_Label_Camera")}
              icon={
                <Icon size="small">
                  <Boschicon
                    style={{ padding: "unset" }}
                    name="bosch-ic-video"
                  />
                </Icon>
              }
            />
          </Menu>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Header;
