import { useTranslation } from "react-i18next";
import style from "./SideNavigationBottom.module.scss";
import { loginAsync, logoutAsync, selectAccount } from "../../redux/account";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/hooks";
import { useState } from "react";
import { SideNavigationItem } from "@bosch/react-frok";
import CustomLoader from "../../components/loading/CustomLoader";
import RDMessageDialog from "../../components/RDComponents/RDMessageDialog/RDMessageDialog";
import { useHistory } from "react-router-dom";
import { selectCountryName } from "../../redux/auth";
import LangSelection from "../../features/sideNavigationBottom/langSelection/LangSelection";
import { STATIC_SIDE_NAV_ITEM } from "../../constants/sideNav.constant";
import AccountOptions from "../../features/sideNavigationBottom/accountOptions/AccountOptions";
import { CONTACT_US_SHARED_ROUTE } from "../../constants/routes/sharedRoutes.constants";
import { triggerSideNavigation } from "../../utils/frok.util";
import { SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT } from "../../constants/frok.constant";

const SideNavigationBottom = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentAccount = useSelector(selectAccount);
  const countryName = useSelector(selectCountryName);
  const [isShowLoader, setShowLoader] = useState(false);
  const [isShowConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const [isShowConfirmChangeRegionsModal, setShowConfirmChangeRegionsModal] =
    useState(false);

  const onGoToRegionsPage = () => {
    setShowConfirmChangeRegionsModal(true);
  };

  const onLogIn = () => {
    if (currentAccount) setShowConfirmLogoutModal(true);
    else {
      setShowLoader(true);
      dispatch(loginAsync({ redirectUrl: window.location.pathname }));
    }

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  const onGoToContactPage = () => {
    history.push(CONTACT_US_SHARED_ROUTE.path);

    if (window.screen.availWidth < SIDE_NAVIGATION_ON_MOBILE_BREAKPOINT)
      triggerSideNavigation("close");
  };

  return (
    <>
      <div className={style.container}>
        <LangSelection />
        <SideNavigationItem
          value=""
          icon="locator"
          label={countryName}
          title={countryName}
          link={{
            as: "button",
            onClick: () => onGoToRegionsPage(),
          }}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
        {currentAccount ? <AccountOptions /> : <></>}
        <SideNavigationItem
          value=""
          icon={currentAccount ? "logout" : "login"}
          label={t(
            currentAccount ? "AccountButton_LogoutButton" : "RegisterOrLogin"
          )}
          title={t(
            currentAccount ? "AccountButton_LogoutButton" : "RegisterOrLogin"
          )}
          link={{
            as: "button",
            onClick: () => onLogIn(),
          }}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
        <SideNavigationItem
          value=""
          icon="mail"
          label={t("Header_ContactUs")}
          title={t("Header_ContactUs")}
          link={{
            as: "button",
            onClick: () => onGoToContactPage(),
          }}
          aria-label={STATIC_SIDE_NAV_ITEM}
        />
      </div>
      {isShowLoader ? <CustomLoader enable /> : <></>}
      {isShowConfirmLogoutModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[t("AccountButton_LogoutModal_Content")]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            dispatch(logoutAsync());
            setShowConfirmLogoutModal(false);
            setShowLoader(true);
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => setShowConfirmLogoutModal(false)}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
      {isShowConfirmChangeRegionsModal ? (
        <RDMessageDialog
          variant="warning"
          messages={[
            `${t("Header_CurrentRegion")} ${countryName}.`,
            t("Header_ChangeRegions"),
          ]}
          confirmLabel={t("Header_YesButton")}
          onConfirm={() => {
            history.push("/regions");
          }}
          cancelLabel={t("Header_NoButton")}
          onCancel={() => setShowConfirmChangeRegionsModal(false)}
          className={style.log_out_modal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SideNavigationBottom;
