import { useTranslation } from "react-i18next";
import style from "./AdsRequestDetails.module.scss";
import AdsComponentTitle from "../../../components/adsComponentTitle/AdsComponentTitle";
import RDDropdown from "../../../../components/RDComponents/RDDropdown";
import { useSelector } from "react-redux";
import {
  NewRequestStep,
  selectFormValues,
  selectServiceTypes,
  selectTechnicians,
  setCurrentStep,
  updateFormValues,
} from "../../../../redux/newRequestSlice";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  DropdownItemProps,
  InputOnChangeData,
} from "semantic-ui-react";
import { selectCountryCode, selectLanguage } from "../../../../redux/auth";
import { selectAccount, selectCiamId } from "../../../../redux/account";
import { GetSupportRequestTypeRequestModel } from "../../../../models/support/request.countryLanguageTimeZone.model";
import { useAppDispatch } from "../../../../redux/hooks";
import { getSupportRequestTypesAsync } from "../../../../redux/supportSlide";
import { getContactPersonsAsync } from "../../../../services/ContactPersonsService";
import { RequestContactPerson } from "../../../../models/contactPerson/request.contactPerson.model";
import CustomLoader from "../../../../components/loading/CustomLoader";
import Boschicon from "../../../../components/BoschIcon/Boschicon";
import { Constants } from "../../../../utils/helpers/Constants";
import RDInput from "../../../../components/RDComponents/RDInput";
import RDButton from "../../../../components/RDComponents/RDButton";
import { ADS_ADD_NEW_TECHNICIAN_ROUTE } from "../../../constants/adsRoutes.constant";
import { capitalizeString } from "../../../../utils/text.util";
import { useAdsRedirect } from "../../../hooks/useAdsRedirect";
import {
  ADS_DIAGNOSTIC_TOOL_SERIAL_SEARCH_QUERY_KEY,
  ADS_REQUEST_DETAILS_FIELDS_INIT,
  ADS_VCI_SERIAL_SEARCH_QUERY_KEY,
} from "../../../constants/adsNewRequest.constant";
import { useAdsNewRequest } from "../../../hooks/useAdsNewRequest";
import { AdsRequestDetailsFields } from "../../../types/adsNewRequest.type";
import { getCreditStatusAsync } from "../../../../redux/creditSlide";
import { useCreditValidation } from "../../../../hooks/NewRequestHooks/useCreditValidation";
import AdsNegativeCreditWarningModal from "../../../components/adsNegativeCreditWarningModal/AdsNegativeCreditWarningModal";
import useCaseInsensitiveSearchParams from "../../../../hooks/useCaseInsensitiveSearchParams";

const AdsRequestDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isInsufficientCredit } = useCreditValidation();
  const { getParam } = useCaseInsensitiveSearchParams();
  const vciSerialParam = getParam(ADS_VCI_SERIAL_SEARCH_QUERY_KEY);
  const diagnosticToolSerialParam = getParam(
    ADS_DIAGNOSTIC_TOOL_SERIAL_SEARCH_QUERY_KEY
  );
  const countryCode = useSelector(selectCountryCode);
  const account = useSelector(selectAccount);
  const language = useSelector(selectLanguage);
  const ciamId = useSelector(selectCiamId);
  const formValues = useSelector(selectFormValues);
  const serviceTypes = useSelector(selectServiceTypes);
  const technicians = useSelector(selectTechnicians);
  const { redirectToAdsPage } = useAdsRedirect();
  const { validateRepairOrder } = useAdsNewRequest();
  const [serviceTypeOptions, setServiceTypeOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [technicianOptions, setTechnicianOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [editingFields, setEditingFields] = useState<AdsRequestDetailsFields>(
    ADS_REQUEST_DETAILS_FIELDS_INIT
  );
  const [fieldErrors, setFieldErrors] = useState<AdsRequestDetailsFields>(
    ADS_REQUEST_DETAILS_FIELDS_INIT
  );
  const [isShowLoader, setShowLoader] = useState(true);

  useEffect(() => {
    dispatch(
      updateFormValues({
        selectedVciSerial: vciSerialParam || "",
        selectedDiagSerial: diagnosticToolSerialParam || "",
      })
    );
  }, []);

  useEffect(() => {
    if (!!ciamId) {
      dispatch(getCreditStatusAsync(ciamId));
    }
  }, [ciamId]);

  useEffect(() => {
    if (!countryCode || !language) return;

    loadAllNecessaryData();
  }, [countryCode, language]);

  useEffect(() => {
    if (serviceTypes) {
      setServiceTypeOptions(
        serviceTypes.map((x) => {
          return {
            key: x.id,
            text: x.serviceTypeLocalized,
            value: x.id,
          };
        })
      );
    }
  }, [serviceTypes]);

  useEffect(() => {
    const filteredTechnicians = technicians.filter(
      (x) =>
        !!account &&
        (account.userRole?.id === Constants.USER_ROLE_MANAGER_ID ||
          x.id === account?.employee?.id)
    );
    setTechnicianOptions(
      filteredTechnicians.map((x) => {
        return {
          key: x.id,
          text: `${x.firstName} ${x.lastName}`,
          value: x.id,
        };
      })
    );

    if (filteredTechnicians.length === 1) {
      dispatch(
        updateFormValues({
          technicianSelected: filteredTechnicians[0].id,
          technicianSelectedModel: filteredTechnicians[0],
        })
      );
    } else if (
      !!formValues.technicianFavourite &&
      filteredTechnicians &&
      !formValues.technicianSelected
    ) {
      const technician = filteredTechnicians.find(
        (x) => x.id === formValues.technicianFavourite?.contactPersonId
      );
      if (!!technician) {
        dispatch(
          updateFormValues({
            technicianSelected: technician.id,
            technicianSelectedModel: technician,
          })
        );
      }
    }
  }, [technicians, formValues?.technicianFavourite]);

  useEffect(() => {
    if (!editingFields.repairOrder) return;

    setFieldErrors((prev) => ({
      ...prev,
      repairOrder: validateRepairOrder(formValues.repairOrder || ""),
    }));
  }, [formValues.repairOrder]);

  useEffect(() => {
    if (!editingFields.diagnosticToolSerial) return;

    setFieldErrors((prev) => ({
      ...prev,
      diagnosticToolSerial: !formValues.selectedDiagSerial
        ? t("AutomaticSearch_InvalidInput")
        : "",
    }));
  }, [formValues.selectedDiagSerial]);

  useEffect(() => {
    if (!editingFields.vciSerial) return;

    setFieldErrors((prev) => ({
      ...prev,
      vciSerial: !formValues.selectedVciSerial
        ? t("AutomaticSearch_InvalidInput")
        : "",
    }));
  }, [formValues.selectedVciSerial]);

  const loadAllNecessaryData = () => {
    let getSupportRequestTypesRequestModel: GetSupportRequestTypeRequestModel =
      {
        countryCode,
        language,
        timeZoneId: "",
        ciamId,
        isTHLTicket: false,
      };
    let getContactPersonsRequestModel: RequestContactPerson = {
      ciamId,
      contactPersonId: "",
      countryCode,
    };

    Promise.all([
      dispatch(getSupportRequestTypesAsync(getSupportRequestTypesRequestModel)),
      dispatch(getContactPersonsAsync(getContactPersonsRequestModel)),
    ]).finally(() => setShowLoader(false));
  };

  const onServiceTypeChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        serviceTypeSelected: +value,
        serviceTypeSelectedModel: serviceTypes.find((x) => x.id === +value),
      })
    );
  };

  const onTechnicianChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        technicianSelected: value,
        technicianSelectedModel: technicians.find((x) => x.id === value),
      })
    );
  };

  const onAddNewTechnician = () => {
    redirectToAdsPage(ADS_ADD_NEW_TECHNICIAN_ROUTE.path);
  };

  const onRepairOrderInputChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    if (!editingFields.repairOrder)
      setEditingFields((prev) => ({
        ...prev,
        repairOrder: "1",
      }));

    dispatch(
      updateFormValues({
        repairOrder: value,
      })
    );
  };

  const onDiagnosticToolSerialInputChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    if (!editingFields.diagnosticToolSerial)
      setEditingFields((prev) => ({
        ...prev,
        diagnosticToolSerial: "1",
      }));

    dispatch(
      updateFormValues({
        selectedDiagSerial: value,
      })
    );
  };

  const onVciSerialInputChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    if (!editingFields.vciSerial)
      setEditingFields((prev) => ({
        ...prev,
        vciSerial: "1",
      }));

    dispatch(
      updateFormValues({
        selectedVciSerial: value,
      })
    );
  };

  const isNextButtonDisabled = (): boolean => {
    return (
      !formValues.serviceTypeSelected ||
      !formValues.technicianSelected ||
      !formValues.repairOrder ||
      !formValues.selectedDiagSerial ||
      !formValues.selectedVciSerial
    );
  };

  const onClickNextButton = () => {
    dispatch(setCurrentStep(NewRequestStep.New_Request_Summary));
  };

  return (
    <>
      <div className={style.container}>
        <AdsComponentTitle title={t("MyRequests_ProgressBar_First")} />
        <div className={style.wrapper}>
          <RDDropdown
            title={`${capitalizeString(
              t("MyRequests_First_SelectServiceModal_Choose")
            )} *`}
            placeholder="-"
            options={serviceTypeOptions}
            search
            selectOnBlur={false}
            value={formValues?.serviceTypeSelected}
            onChange={onServiceTypeChanged}
          />
          <div className={style.block}>
            <RDDropdown
              title={`${capitalizeString(t("ReportFile_ContactPerson"))} *`}
              placeholder="-"
              options={technicianOptions}
              search
              selectOnBlur={false}
              value={formValues?.technicianSelected}
              onChange={onTechnicianChanged}
              header={
                !!account && (
                  <Button
                    primary
                    className={style.add_new_button}
                    onClick={onAddNewTechnician}
                  >
                    <Boschicon name="bosch-ic-user-add" />
                    {t("TechnicianDropdown_AddNew_ButtonText")}
                  </Button>
                )
              }
            />
            <RDInput
              title={`${t("ADS_NewRequest_RepairOrder")} *`}
              value={formValues.repairOrder}
              onChange={onRepairOrderInputChange}
              errorMessage={fieldErrors.repairOrder}
            />
          </div>
          <div className={style.block}>
            <RDInput
              disabled={!!diagnosticToolSerialParam}
              title={`${t("ADS_DiagnosticToolSerial")} *`}
              value={formValues.selectedDiagSerial}
              onChange={onDiagnosticToolSerialInputChange}
              errorMessage={fieldErrors.diagnosticToolSerial}
            />
            <RDInput
              disabled={!!vciSerialParam}
              title={`${t("ADS_VciSerial")} *`}
              value={formValues.selectedVciSerial}
              onChange={onVciSerialInputChange}
              errorMessage={fieldErrors.vciSerial}
            />
          </div>
          <div className={style.bottom}>
            <RDButton
              disabled={isNextButtonDisabled()}
              onClick={onClickNextButton}
            >
              {t("CommonText_Next")}
            </RDButton>
          </div>
        </div>
      </div>
      {isShowLoader ? <CustomLoader enable /> : <></>}
      {isInsufficientCredit ? (
        <AdsNegativeCreditWarningModal
          onCancel={() => {
            dispatch(
              updateFormValues({
                serviceTypeSelected: 0,
                serviceTypeSelectedModel: null,
              })
            );
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdsRequestDetails;
