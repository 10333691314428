import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import UserInput from "./UserInput";
import Header from "./Header";
import "./styles/chat-window.css";
import { useSelector } from "react-redux";
import {
  channelSetMessage,
  selectApiKey,
  selectChannelArn,
  selectChannelMessage,
  selectChimeUserId,
  selectIsTranslate,
} from "../../redux/chat";
import { createMemberArn, listChannelMessages } from "../helpers/ChimeAPI";
import Messages from "./MessageList";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { getChimeSecret, liveTranslateMessage } from "../../services/Chime";
import AWS from "aws-sdk";
import { useAppSelector } from "../../redux/hooks";
import { selectIsSignIn } from "../../redux/auth";
import { RSADecrypt } from "../../services/RSA_Encrypt";
import Boschicon from "../../components/BoschIcon/Boschicon";
import { useTranslation } from "react-i18next";

const ChatWindow: FC<IChatProps> = (props) => {
  const channelArn = useSelector(selectChannelArn);
  const chimeUserId = useSelector(selectChimeUserId);
  const messages = useSelector(selectChannelMessage);
  const isSignIn = useAppSelector(selectIsSignIn);
  const isTranslate = useSelector(selectIsTranslate);
  const [translateModeData, onChangetranslateModeData] = useState<any>({
    isTranslate: false,
    langSource: "auto",
    langTarget: window.navigator.language.slice(0, 2),
  });
  const [isErrorApiCall, setIsErrorApiCall] = useState<boolean>(false);
  const apiKey = useAppSelector(selectApiKey);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const updateMessage = async () => {
      if (isSignIn && channelArn && channelArn !== "" && chimeUserId) {
        if (!AWS.config.credentials) {
          const awsKeys = await getChimeSecret(apiKey);
          if (awsKeys) {
            const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
            if (awsKeysDecrypt) {
              let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!);
              AWS.config.credentials = {
                accessKeyId: awsKeysDecryptData.AccessKey,
                secretAccessKey: awsKeysDecryptData.SecretKey,
              };
            }
          } else {
            console.log("Cannot get AWS keys");
          }
        }

        const newMessages = await listChannelMessages(channelArn, chimeUserId);
        if (newMessages && newMessages.Messages) {
          const message_translate = await Promise.all(
            newMessages.Messages.map(async (message: any) => {
              message.CreatedTimestamp = message.CreatedTimestamp.toISOString();
              message.LastUpdatedTimestamp = message.LastUpdatedTimestamp.toISOString();
              if (
                createMemberArn(chimeUserId) !== message.Sender.Arn &&
                isTranslate.status
              ) {
                const translateMessage = await liveTranslateMessage(
                  message.Content,
                  isTranslate.langSource,
                  isTranslate.langTarget,
                  apiKey
                );
                return { ...message, Content: translateMessage };
              }
              return message;
            })
          );
          setIsErrorApiCall(false)
          dispatchReduxToolkit(
            channelSetMessage({
              channelMessages: message_translate,
              nextToken: newMessages.NextToken,
            })
          );
        } else {
          setIsErrorApiCall(true)
        }
      }
    };
    updateMessage();
  }, [props.newMessage, translateModeData, isTranslate, chimeUserId]);

  return (
    <div
      className={classNames(
        "sc-chat-window",
        { opened: props.isOpen },
        { closed: !props.isOpen }
      )}
    >
      <Header
        title={props.title}
        onClose={props.onClose}
        onTranslateMode={(data) => onChangetranslateModeData(data)}
        translateModeData={translateModeData}
      />

      {
        (isErrorApiCall) && (<div className="sc-chat-disconnect-noti">
          <Boschicon name="alert-info" />
          <p dangerouslySetInnerHTML={{
            __html: t("CDM3ChatWidget_Connection_Error")
              .replace("[", `<a onclick="window.location.reload()">`)
              .replace("]", "</a>")
          }}></p>
        </div>)
      }

      <Messages
        channelArn={channelArn}
        messages={messages}
        userId={chimeUserId}
        translateModeData={translateModeData}
        browserLanguage={props.browserLanguage}
      />

      <UserInput
        onSubmit={props.onUserInputSubmit}
        placeholder={props.placeHolder}
        updateMarker={props.updateMarker}
        activeChannelArn={channelArn}
        newMessage={props.newMessage}
      />
    </div>
  );
};

export interface IChatProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  newMessage: string;
  activeChannelArn: string;
  onUserInputSubmit: () => void;
  placeHolder: string;
  updateMarker: any;
  browserLanguage: string;
  translateMode: any;
}

export default ChatWindow;