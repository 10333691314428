import { Badge, Button } from "@bosch/react-frok";
import style from "./CartButton.module.scss";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { useCartCount } from "../../../hooks/useCartCount";
import { setPrevPageBeforeBuyingCredits } from "../../../redux/shoppingCartV3Slice";
import { SHOPPING_CART_SHARED_ROUTE } from "../../../constants/routes/sharedRoutes.constants";
import { Constants } from "../../../utils/helpers/Constants";
import CustomLoader from "../../../components/loading/CustomLoader";
import { useGetCart } from "../../../hooks/httpHook/useGetCart";

const CartButton = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { cartCount } = useCartCount();
  const [isShowLoader] = useGetCart();

  const onGoToShoppingCart = () => {
    dispatch(setPrevPageBeforeBuyingCredits());
    history.push(SHOPPING_CART_SHARED_ROUTE.path);
  };

  const renderCartQuantity = () => {
    if (cartCount > Constants.CART_BADGE_MAX_QUANTITY) {
      return Constants.CART_OVER_MAX_QUANTITY_LABEL;
    }

    return cartCount;
  };

  return (
    <>
      <Button
        mode="integrated"
        className={style.container}
        icon="shoppingcart"
        onClick={onGoToShoppingCart}
      >
        {cartCount > 0 ? (
          <Badge label={renderCartQuantity()} className={style.cart_quantity} />
        ) : (
          <></>
        )}
      </Button>
      {isShowLoader ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default CartButton;
