import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CountryPaymentMethodsDto } from "../models/paymentAdyen/countryPaymentMethodsDto";
import { selectCountryCode } from "../redux/auth";
import { selectAvailablePaymentMethods } from "../redux/shoppingCartV3Slice";
import { isAccountBeAbleToPayByBoschAccount } from "../redux/account";
import { PaymentMethodTypeDto } from "../models/paymentAdyen/PaymentMethodTypeDto";

export const usePaymentMethods = () => {
  const countryCode = useSelector(selectCountryCode);
  const availablePaymentMethods = useSelector(selectAvailablePaymentMethods);
  const [paymentMethods, setPaymentMethods] = useState<
    CountryPaymentMethodsDto[]
  >([]);
  const isAbleToPayByBoschAccount = useSelector(
    isAccountBeAbleToPayByBoschAccount
  );

  useEffect(() => {
    const paymentMethod = availablePaymentMethods?.find(
      (x) => x.countryCode?.toLowerCase() === countryCode?.toLowerCase()
    );
    if (
      !paymentMethod ||
      !paymentMethod.paymentMethodTypes ||
      paymentMethod.paymentMethodTypes.length <= 0
    )
      return;

    const convertedPaymentMethods: CountryPaymentMethodsDto[] = [];

    paymentMethod.paymentMethodTypes.forEach((method) => {
      convertedPaymentMethods.push({
        ...paymentMethod,
        paymentMethodTypes: [method],
      });
    });

    if (isAbleToPayByBoschAccount) {
      convertedPaymentMethods.push({
        ...paymentMethod,
        paymentMethodTypes: [PaymentMethodTypeDto.BoschAccount],
      });
    }

    setPaymentMethods(convertedPaymentMethods);
  }, [availablePaymentMethods]);

  return paymentMethods;
};
