import { reducer as formReducer } from "redux-form";
import authReducer from "./auth";
import {
  PreloadedState,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import accountReducer from "./accountSlide";
import shoppingCartReducer from "./shoppingCartSlide";
import myRequestReducer from "./myRequestSlide";
import accountRediReducer from "./account";
import transactionReducer from "./transactionSlide";
import paymentAdyenReducer from "./paymentAdyenSlide";
import creditReducer from "./creditSlide";
import regionReducer from "./region";
import chatReducer from "./chat";
import meetingReducer from "../redux/meeting";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import dashboardReducer from "./dashboardSlide";
import joinCompanyReducer from "./joinCompanySlide";
import newRequestReducer from "./newRequestSlice";
import dashboardCDM3Reducer from "./dashboardCDM3Slice";
import contactPersonsSlice from "./contactPersonSlide";
import hardwareSlice from "./hardwareSlice";
import productsReducer from "./productsSlice";
import shoppingCartV3Reducer from "./shoppingCartV3Slice";
import servicePlansReducer from "./servicePlansSlice";
import checkOutReducer from "./checkoutSlide";
import maintenanceReducer from "./maintenanceSlice";
import statisticCdmUSReducer from "./statisticSlice";

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  accountRedi: accountRediReducer,
  regionRedi: regionReducer,
  shoppingCartItem: shoppingCartReducer,
  account: accountReducer,
  myRequestForm: myRequestReducer,
  transaction: transactionReducer,
  credit: creditReducer,
  region: regionReducer,
  paymentAdyen: paymentAdyenReducer,
  chat: chatReducer,
  meeting: meetingReducer,
  dashboard: dashboardReducer,
  joinCompany: joinCompanyReducer,
  newRequest: newRequestReducer,
  dashboardCMD3: dashboardCDM3Reducer,
  contactPersion: contactPersonsSlice,
  hardware: hardwareSlice,
  products: productsReducer,
  shoppingCartV3: shoppingCartV3Reducer,
  servicePlans: servicePlansReducer,
  checkout: checkOutReducer,
  maintenance: maintenanceReducer,
  statistic: statisticCdmUSReducer,
});

const persistConfig = {
  key: "rootRedi",
  version: 1,
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
});
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
