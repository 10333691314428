export class MaintenanceBannerConstants {
  public static ENABLE_THL_MESSAGE_COUNTRIES = ["DK", "FI", "SE", "NO"];
  public static ENABLE_PPU_MESSAGE_COUNTRIES = [
    "AT",
    "BE",
    "CH",
    "DE",
    "ES",
    "FR",
    "IT",
    "LU",
    "NL",
    "PL",
  ];

  public static DEFAULT_PPU_MESSAGE =
    "Dear Remote Diagnostics user, we have a change for all our Pay-Per-Use users. If you book a Service you can pay this also in credits like in the subscription model. You can easily buy credits in the customer portal. You still can use the Service as usual and the process for the Service itself didn’t change.";

  public static PPUToCreditMessages = [
    {
      language: "de-AT",
      message:
        "Lieber Remote Diagnose Nutzer, wir haben eine Änderung für alle unsere Pay-Per-Use-Nutzer. Wenn Sie einen Service buchen, muss dieser wie beim Abonnementmodell auch in Credits bezahlt werden. Im Kundenportal können Sie ganz einfach Credits kaufen. Sie können den Service weiterhin wie gewohnt nutzen und der Prozess für den Service hat sich nicht geändert.",
    },
    {
      language: "nl-BE",
      message:
        "Beste Remote Diagnostics-gebruiker, we hebben een wijziging voor al onze Pay-Per-Use-gebruikers. Als u een dienst boekt, kunt u deze ook in credits betalen, zoals in het abonnementsmodel. In het klantenportaal kunt u eenvoudig credits kopen. U kunt de dienst nog steeds op de gebruikelijke manier gebruiken en het proces voor de dienst zelf is niet gewijzigd.",
    },
    {
      language: "fr-BE",
      message:
        "Cher utilisateur du diagnostic à distance, Nous vous informons qu’il y a un changement pour tous nos utilisateurs Pay-Per-Use. Comme dans le modèle d’abonnement, il vous est à présent possible de payer votre session à la demande en crédits. Pour ce faire vous pouvez facilement acheter des crédits sur le portail client. Bien entendu, il vous est toujours possible d’utiliser le Service comme d’habitude, le processus du Service lui-même n’a pas changé.",
    },
    {
      language: "de-CH",
      message:
        "Lieber Remote Diagnose Nutzer, wir haben eine Änderung für alle unsere Pay-Per-Use-Nutzer. Wenn Sie einen Service buchen, muss dieser wie beim Abonnementmodell auch in Credits bezahlt werden. Im Kundenportal können Sie ganz einfach Credits kaufen. Sie können den Service weiterhin wie gewohnt nutzen und der Prozess für den Service hat sich nicht geändert.",
    },
    {
      language: "de-DE",
      message:
        "Lieber Remote Diagnose Nutzer, wir haben eine Änderung für alle unsere Pay-Per-Use-Nutzer. Wenn Sie einen Service buchen, muss dieser wie beim Abonnementmodell auch in Credits bezahlt werden. Im Kundenportal können Sie ganz einfach Credits kaufen. Sie können den Service weiterhin wie gewohnt nutzen und der Prozess für den Service hat sich nicht geändert.",
    },
    {
      language: "es-ES",
      message:
        "Estimado usuario de diagnostico remoto, hemos realizado un cambio para todos nuestros usuarios de pago por uso. Si reserva un Servicio, también podrá pagarlo en créditos como en el modelo de suscripción. Puede comprar créditos fácilmente en el portal del cliente, pudiendo seguir utilizando el Servicio de pago por uso como de costumbre. El proceso del Servicio en sí no cambia.",
    },
    {
      language: "fr-FR",
      message:
        "Cher utilisateur du diagnostic à distance, Nous vous informons qu’il y a un changement pour tous nos utilisateurs Pay-Per-Use. Comme dans le modèle d’abonnement, il vous est à présent possible de payer votre session à la demande en crédits. Pour ce faire vous pouvez facilement acheter des crédits sur le portail client. Bien entendu, il vous est toujours possible d’utiliser le Service comme d’habitude, le processus du Service lui-même n’a pas changé.",
    },
    {
      language: "it-IT",
      message:
        "Gentile utente di Remote Diagnostics, abbiamo una modifica per tutti i nostri utenti Pay-Per-Use. Se prenoti un Servizio puoi pagarlo anche in crediti come nel modello di abbonamento. Puoi acquistare facilmente crediti nel portale clienti. Puoi comunque utilizzare il Servizio come al solito e il processo per il Servizio stesso non è cambiato.",
    },
    {
      language: "fr-LU",
      message:
        "Cher utilisateur du diagnostic à distance, nous vous informons qu’il y a un changement pour tous nos utilisateurs Pay-Per-Use. Comme dans le modèle d’abonnement, il vous est à présent possible de payer votre session à la demande en crédits. Pour ce faire vous pouvez facilement acheter des crédits sur le portail client. Bien entendu, il vous est toujours possible d’utiliser le Service comme d’habitude, le processus du Service lui-même n’a pas changé.",
    },
    {
      language: "nl-NL",
      message:
        "Beste Remote Diagnostics-gebruiker, we hebben een wijziging voor al onze Pay-Per-Use-gebruikers. Als u een dienst boekt, kunt u deze ook in credits betalen, zoals in het abonnementsmodel. In het klantenportaal kunt u eenvoudig credits kopen. U kunt de dienst nog steeds op de gebruikelijke manier gebruiken en het proces voor de dienst zelf is niet gewijzigd.",
    },
    {
      language: "fr-NL",
      message:
        "Cher utilisateur du diagnostic à distance, Nous vous informons qu’il y a un changement pour tous nos utilisateurs Pay-Per-Use. Comme dans le modèle d’abonnement, il vous est à présent possible de payer votre session à la demande en crédits. Pour ce faire vous pouvez facilement acheter des crédits sur le portail client. Bien entendu, il vous est toujours possible d’utiliser le Service comme d’habitude, le processus du Service lui-même n’a pas changé.",
    },
    {
      language: "pl-PL",
      message:
        "Drogi użytkowniku Zdalnej Diagnozy. Mamy zmianę dla wszystkich naszych użytkowników korzystających z „Usługi jednorazowej”. Rezerwując Usługę możesz opłacić ją również żetonami tak jak w przypadku modelu Pakietowego (abonamentowego). Żetony możesz łatwo kupić w portalu klienta. Nadal możesz korzystać z Usługi w zwykły sposób. Sam proces Usługi nie uległ zmianie.",
    },
    {
      language: "en-US",
      message:
        "Dear Remote Diagnostics user, we have a change for all our Pay-Per-Use users. If you book a Service you can pay this also in credits like in the subscription model. You can easily buy credits in the customer portal. You still can use the Service as usual and the process for the Service itself didn’t change.",
    },
  ];

  public static THLPhaseOutMessages = [
    {
      country: "DK",
      message:
        'Teknisk Hotline er pr. 31. december 2023 udfaset på Remote Diagnostics Portalen. Fremover bedes du derfor kontakte os på telefon <a href="tel:+45 44 89 83 90">+45 44 89 83 90</a> eller mail <a href="mailto:aa_hotline@dk.bosch.com">aa_hotline@dk.bosch.com</a>',
    },
    {
      country: "FI",
      message:
        '31.12.2023 alkaen tekniset kyselyt on poistettu käytöstä etädiagnostiikkaportaalissa. Jatkossa voit ottaa meihin yhteyttä puhelimitse <a href="tel:+358931587373">+358931587373</a> tai sähköpostitse <a href="mailto:auto.tekninenpalvelu@fi.bosch.com">auto.tekninenpalvelu@fi.bosch.com</a>',
    },
    {
      country: "NO",
      message:
        'Teknisk Hotline er pr 31. desember 2023 faset ut på Remote Diagnostics Portalen. Du kan fremover kontakte oss på telefon <a href="tel:+47 64 87 89 89">+47 64 87 89 89</a> eller e-post <a href="mailto:aa-hotline@no.bosch.com">aa-hotline@no.bosch.com</a>',
    },
    {
      country: "SE",
      message:
        'Från och med den 31 december 2023 har Teknisk Hotline fasats ut på Remote Diagnostics Portalen. I framtiden kan du kontakta oss på telefon <a href="tel:+46 8 750 18 00">+46 8 750 18 00</a> eller e-post <a href="mailto:aa.hotline@se.bosch.com">aa.hotline@se.bosch.com</a>',
    },
  ];

  public static DEFAULT_SERMI_PHASE_OUT_MESSAGE =
    "Dear Remote Diagnostics user, SERMI will come into force in Germany on April 1st, 2024. In order for you as a customer of Remote Diagnosis to continue to be able to fully use this service, you also need a SERMI certification. Further information can be found in our FAQ: {link}";

  public static SERMI_PHASE_OUT_MESSAGES = [
    {
      language: "de-DE",
      message:
        "Lieber Remote Diagnose Nutzer, am 01.04.2024 tritt in Deutschland SERMI in Kraft. Damit Sie als Kunde von Remote Diagnose diesen Service weiterhin vollumfänglich nutzen können benötigen auch Sie ebenfalls eine SERMI Zertifizierung. Weitere Infos finden Sie in unseren FAQ: {link}",
    },
    {
      language: "de-AT",
      message:
        "Lieber Remote Diagnose Nutzer, am 01.04.2024 tritt in Österreich SERMI in Kraft. Damit Sie als Kunde von Remote Diagnose diesen Service weiterhin vollumfänglich nutzen können benötigen auch Sie ebenfalls eine SERMI Zertifizierung. Weitere Infos finden Sie in unseren FAQ: {link}",
    },
    {
      language: "fr-BE",
      message:
        "Cher utilisateur du diagnostic à distance, SERMI entrera en vigueur au France le 1er avril 2024. Vous aurez dès lors, à l'avenir, en tant que client du Diagnostic à Distance, également besoin d'une certification SERMI. Vous trouverez de plus amples informations à ce sujet dans notre FAQ : {link}",
    },
    {
      language: "nl-BE",
      message:
        "Beste gebruiker van Remote Diagnose, SERMI wordt op 1 april 2024 in België van kracht. Om ervoor te zorgen dat u als klant van Diagnose op afstand optimaal gebruik kunt blijven maken van deze dienst, heeft u tevens een SERMI-certificering nodig. Meer informatie vindt u in onze FAQ: {link}",
    },
    {
      language: "nl-NL",
      message:
        "Beste gebruiker van Remote Diagnose, SERMI wordt op 1 april 2024 in Nederland van kracht. Om ervoor te zorgen dat u als klant van Diagnose op afstand optimaal gebruik kunt blijven maken van deze dienst, heeft u tevens een SERMI-certificering nodig. Meer informatie vindt u in onze FAQ: {link}",
    },
    {
      language: "fr-LU",
      message:
        "Cher utilisateur du diagnostic à distance, SERMI entrera en vigueur au Luxembourg le 1er avril 2024. Vous aurez dès lors, à l'avenir, en tant que client du Diagnostic à Distance, également besoin d'une certification SERMI. Vous trouverez de plus amples informations à ce sujet dans notre FAQ : {link}",
    },
    {
      language: "es-ES",
      message:
        "Estimado usuario de telediagnóstico, SERMI entrará en vigor en España el 1 de abril de 2024. Para que usted como cliente de Diagnóstico Remoto pueda seguir utilizando plenamente este servicio, también necesita una certificación SERMI. Puede encontrar más información en nuestras preguntas frecuentes: {link}",
    },
  ];
}
