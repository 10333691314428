import { Fragment, useState } from "react";
import { Button, Modal, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./styles/ModalTranlationOptionsPublic.css";
import { channelSetIsTranslate } from "../../redux/chat";
import Boschicon from "../../components/BoschIcon/Boschicon";
import { useAppDispatch } from "../../redux/store";
interface ModalTranlationOptionsProps {
  isOpen: boolean;
  translateModeData: any;
}

const countryOptions = [
  { key: "auto", value: "auto", text: "Detect Language" },
  { key: "af", value: "af", flag: "af", text: "Afrikaans" },
  { key: "fa", value: "fa", flag: "af", text: "Farsi (Persian)" },
  { key: "fa-AF", value: "fa-AF", flag: "af", text: "Dari" },
  { key: "sq", value: "sq", flag: "al", text: "Albanian" },
  { key: "ar", value: "ar", flag: "dz", text: "Arabic" },
  { key: "hy", value: "hy", flag: "am", text: "Armenian" },
  { key: "nl", value: "nl", flag: "aw", text: "Dutch" },
  { key: "az", value: "az", flag: "az", text: "Azerbaijani" },
  { key: "bn", value: "bn", flag: "bd", text: "Bengali" },
  { key: "bs", value: "bs", flag: "ba", text: "Bosnian" },
  { key: "bg", value: "bg", flag: "bg", text: "Bulgarian" },
  { key: "zh", value: "zh", flag: "cn", text: "Chinese (Simplified)" },
  { key: "zh-TW", value: "zh-TW", flag: "cn", text: "Chinese (Traditional)" },
  { key: "hr", value: "hr", flag: "hr", text: "Croatian" },
  { key: "cs", value: "cs", flag: "cz", text: "Czech" },
  { key: "da", value: "da", flag: "dk", text: "Danish" },
  { key: "en", value: "en", flag: "gb eng", text: "English" },
  { key: "et", value: "et", flag: "ee", text: "Estonian" },
  { key: "am", value: "am", flag: "et", text: "Amharic" },
  { key: "fi", value: "fi", flag: "fi", text: "Finnish" },
  { key: "fr", value: "fr", flag: "fr", text: "French" },
  { key: "ka", value: "ka", flag: "ge", text: "Georgian" },
  { key: "de", value: "de", flag: "de", text: "German" },
  { key: "el", value: "el", flag: "gr", text: "Greek" },
  { key: "ht", value: "ht", flag: "ht", text: "Haitian Creole" },
  { key: "hu", value: "hu", flag: "hu", text: "Hungarian" },
  { key: "ga", value: "ga", flag: "is", text: "Irish" },
  { key: "hi", value: "hi", flag: "in", text: "Hindi" },
  { key: "gu", value: "gu", flag: "in", text: "Gujarati" },
  { key: "kn", value: "kn", flag: "in", text: "Kannada" },
  { key: "mr", value: "mr", flag: "in", text: "Marathi" },
  { key: "ml", value: "ml", flag: "in", text: "Malayalam" },
  { key: "pa", value: "pa", flag: "in", text: "Punjabi" },
  { key: "ur", value: "ur", flag: "in", text: "Urdu" },
  { key: "te", value: "te", flag: "in", text: "Telugu" },
  { key: "id", value: "id", flag: "id", text: "Indonesian" },
  { key: "is", value: "is", flag: "ie", text: "Icelandic" },
  { key: "he", value: "he", flag: "il", text: "Hebrew" },
  { key: "it", value: "it", flag: "it", text: "Italian" },
  { key: "ja", value: "ja", flag: "jp", text: "Japanese" },
  { key: "kk", value: "kk", flag: "kz", text: "Kazakh" },
  { key: "sw", value: "sw", flag: "ke", text: "Swahili" },
  { key: "lv", value: "lv", flag: "lv", text: "Latvian" },
  { key: "lt", value: "lt", flag: "lt", text: "Lithuanian" },
  { key: "mk", value: "mk", flag: "mk", text: "Macedonian" },
  { key: "ms", value: "ms", flag: "my", text: "Malay" },
  { key: "mt", value: "mt", flag: "mt", text: "Maltese" },
  { key: "es-MX", value: "es-MX", flag: "mx", text: "Spanish (Mexico)" },
  { key: "mn", value: "mn", flag: "mn", text: "Mongolian" },
  { key: "ha", value: "ha", flag: "ng", text: "Hausa" },
  { key: "no", value: "no", flag: "no", text: "Norwegian (Bokmål)" },
  { key: "ps", value: "ps", flag: "pk", text: "Pashto" },
  { key: "tl", value: "tl", flag: "ph", text: "Filipino, Tagalog" },
  { key: "pl", value: "pl", flag: "pl", text: "Polish" },
  { key: "pt", value: "pt", flag: "pt", text: "Portuguese (Brazil)" },
  { key: "pt-PT", value: "pt-PT", flag: "pt", text: "Portuguese (Portugal)" },
  { key: "ro", value: "ro", flag: "ro", text: "Romanian" },
  { key: "ru", value: "ru", flag: "ru", text: "Russian" },
  { key: "sr", value: "sr", flag: "cs", text: "Serbian" },
  { key: "sk", value: "sk", flag: "sk", text: "Slovak" },
  { key: "sl", value: "sl", flag: "si", text: "Slovenian" },
  { key: "so", value: "so", flag: "so", text: "Somali" },
  { key: "ko", value: "ko", flag: "kr", text: "Korean" },
  { key: "es", value: "es", flag: "es", text: "Spanish" },
  { key: "si", value: "si", flag: "lk", text: "Sinhala" },
  { key: "sv", value: "sv", flag: "se", text: "Swedish" },
  { key: "th", value: "th", flag: "th", text: "Thai" },
  { key: "tr", value: "tr", flag: "tr", text: "Turkish" },
  { key: "uk", value: "uk", flag: "ua", text: "Ukrainian" },
  { key: "uz", value: "uz", flag: "uz", text: "Uzbek" },
  { key: "vi", value: "vi", flag: "vn", text: "Vietnamese" },
  { key: "cy", value: "cy", flag: "gb wls", text: "Welsh" },
];

const ModalTranlationOptions = ({
  isOpen,
  translateModeData,
}: ModalTranlationOptionsProps) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const isTranslate = translateModeData.status;
  const [langSource, setLangSource] = useState<string>(
    translateModeData.langSource
  );
  const [langTarget, setTarget] = useState<string>(
    translateModeData.langTarget
  );

  const handleOnClick = () => {
    dispatchReduxToolkit(
      channelSetIsTranslate({
        ...translateModeData,
        langSource: langSource,
        langTarget: langTarget,
        languagebox: false,
      })
    );
  };
  return (
    <Fragment>
      <Modal open={isOpen} size={"mini"}>
        <div className="public-translate-modal">
          <div className="colorBar"></div>

          <div className="error-area">
            <div className="error--title">
              <Boschicon name={"bosch-ic-translate"} />
              <p>{t("Enable live translation")}</p>
            </div>
            <div className="dropdown--option">
              <div className="dropdown--option-language-code">
                {t("Chat_translate_from")}
                <Dropdown
                  disabled={!isTranslate}
                  value={langSource}
                  onChange={(event: any, data: any) =>
                    setLangSource(data.value)
                  }
                  search
                  selection
                  options={countryOptions}
                  placeholder="Select Country"
                />
              </div>
              <div className="dropdown--option-language-code">
                {t("Chat_translate_to")}
                <Dropdown
                  search
                  disabled={!isTranslate}
                  value={langTarget}
                  selection
                  onChange={(event: any, data: any) => setTarget(data.value)}
                  options={countryOptions}
                  placeholder="Select Country"
                />
              </div>
            </div>
            <div className="btn--option">
              <Button
                primary
                color="blue"
                onClick={() => {
                  handleOnClick();
                }}
              >
                {t("Chat_continue_panel")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ModalTranlationOptions;
