import AWS from "aws-sdk";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import {
  createMemberArn,
  describeChannel,
  sendChannelMessage,
  listChannelMembershipsForAppInstanceUser,
  updateChannelReadMarker,
  Persistence,
  MessageType,
  markMessageSeen,
  listChannelMessages,
} from "../utils/helpers/ChimeAPI";
import { selectOriginComp } from "../redux/account";
import { selectIsSignIn } from "../redux/auth";
import {
  channelClearState,
  channelSetChannel,
  channelSetChannelArn,
  channelSetChimeUserId,
  selectApiKey,
  selectChannelArn,
  selectChannelMessage,
  selectChannelName,
  selectChimeUserId,
} from "../redux/chat";
import MessagingService from "../services/MessagingService";
import Launcher from "../utils/chat-widget/Launcher";
import { AppDispatch, useAppDispatch } from "../redux/store";
import { lightTheme } from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import addNotification from "react-push-notification";
import { useSelector } from "react-redux";
import { getApiKey, getChimeSecret } from "../services/Chime";
import { RSADecrypt } from "../services/RSA_Encrypt";
import { selectSelectedSupportRequest } from "../redux/dashboardSlide";
import { useLocation } from "react-router-dom";
import { ChannelMembershipForAppInstanceUserSummary } from "aws-sdk/clients/chimesdkmessaging";

const ChatWidget: FC = () => {
  const messagingService = MessagingService.getInstance();
  const [newMessage, setNewMessage] = useState<any>();
  const [widgetOpened, setWidgetOpened] = useState<boolean>(false);
  const [onActive, setOnActive] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [hasUnReadMessage, setHasUnReadMessage] = useState<boolean>(false);
  const [tabHasFocus, setTabHasFocus] = useState<boolean>(false);
  const [unSeenMessage, setUnSeenMessage] = useState<number>(0);
  const channelName = useAppSelector(selectChannelName);
  const channelArn = useAppSelector(selectChannelArn);
  const chimeUserId = useAppSelector(selectChimeUserId);
  const apiKey = useAppSelector(selectApiKey);
  const dispatchReduxToolkit: AppDispatch = useAppDispatch();
  const messages = useSelector(selectChannelMessage);
  const isSignIn = useAppSelector(selectIsSignIn);
  const acc = useAppSelector(selectOriginComp);
  const browserLanguage = window.navigator.language;
  const { t } = useTranslation();
  const currentRequest = useSelector(selectSelectedSupportRequest);
  let tabHasFocusNotState = true;
  const member = {
    username: acc ? acc.contactFirstName + " " + acc.contactLastName : "Workshop Technician",
    userId: acc ? acc.ciamId : "Workshop Technician",
  };
  const location = useLocation();
  const chimeUserIdRef = useRef(chimeUserId);
  const channelNameRef = useRef(channelName);
  const channelArnRef = useRef(channelArn);
  channelArnRef.current = channelArn;
  channelNameRef.current = channelName;
  chimeUserIdRef.current = chimeUserId;

  const onMessageWasSent = async (
    message: { author: string; timestamp: string; data: { text: string } },
    options?: any
  ) => {
    const newOptions = options ? JSON.parse(options) : {};
    await sendChannelMessage(
      channelArn,
      message.data.text,
      Persistence.PERSISTENT,
      MessageType.STANDARD,
      member,
      JSON.stringify({ ...newOptions, language: browserLanguage })
    );
  };

  function onClick() {
    if (!!channelArn) {
      setWidgetOpened(!widgetOpened);
    }
  }

  const messagesProcessor = async (message: any) => {
    const messageType = message?.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message?.payload);
    switch (messageType) {
      case "CHANNEL_DETAILS":
        break;
      // Channel Messages
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":
        console.log("CREATE_CHANNEL_MESSAGE===========:");
        if (
          !channelArnRef.current ||
          channelArnRef.current !== record.ChannelArn
        ) {
          dispatchReduxToolkit(channelSetChannelArn(record.ChannelArn));
          storeChannelInfo(record.ChannelArn);
        }
        if (!chimeUserIdRef.current) {
          dispatchReduxToolkit(channelSetChimeUserId(member.userId));
        }
        processChannelMessage(record);
        break;
      // Channels actions
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        break;
      case "DELETE_CHANNEL":
        if (channelNameRef.current === record.Name) {
          dispatchReduxToolkit(channelClearState());
        }
        break;
      // Channel Memberships
      case "CREATE_CHANNEL_MEMBERSHIP":
        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        if (record?.Member?.Arn.includes(member.userId)) {
          dispatchReduxToolkit(channelClearState());
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
        await startNewestActiveChannel();
    }
  };

  const storeChannelInfo = async (ChannelArn: string) => {
    const newChannel = await describeChannel(ChannelArn, member.userId);
    if (newChannel) {
      dispatchReduxToolkit(channelSetChannel(newChannel));
    }
  };

  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    if (newMessage.Persistence !== Persistence.NON_PERSISTENT && newMessage.Type !== MessageType.CONTROL) {
      if (newMessage.Sender.Arn !== createMemberArn(chimeUserId)) {
        if (!tabHasFocusNotState) {
          addNotification({
            title: newMessage.Sender.Name + " sent you a message",
            message: newMessage.Content,
            theme: "darkblue",
            duration: 5000,
            native: true,
            onClick: notifiOnclick,
          });
          checkUnreadMessage(newMessage.ChannelArn);
        } else {
          setWidgetOpened(true);
        }
      }
      setNewMessage(newMessage);
    }
  };

  const checkUnreadMessage = async (channelArn: string) => {
    const channelList = await listChannelMembershipsForAppInstanceUser(
      member.userId
    );
    if (channelList.length === 0) return;
    let currentChannel = channelList.filter((channel: ChannelMembershipForAppInstanceUserSummary) => channel.ChannelSummary?.ChannelArn === channelArn);
    if (
      new Date(
        currentChannel[0].AppInstanceUserMembershipSummary.ReadMarkerTimestamp
      ) < new Date(currentChannel[0].ChannelSummary.LastMessageTimestamp)
    ) {
      setHasUnReadMessage(true);
      const unRead_newMessage = "HAS_UNREAD_MESSAGES";
      setNewMessage(unRead_newMessage);
      //count unseenMessage
      if (!tabHasFocus) {
        let messageUnseenTotal = 0;
        const newMessages = await listChannelMessages(currentChannel[0].ChannelSummary.ChannelArn, member.userId);
        if (newMessages && newMessages.Messages) {
          newMessages.Messages.map((m: any) => {
            if (new Date(
              currentChannel[0].AppInstanceUserMembershipSummary.ReadMarkerTimestamp
            ) < new Date(m.CreatedTimestamp) && (!m.Sender.Arn.includes(member.userId))) {
              messageUnseenTotal += 1;
            }
          })
          setUnSeenMessage(messageUnseenTotal);
        }
      }
    }
  };

  const handleFocus = () => {
    setTabHasFocus(true);
    tabHasFocusNotState = true;
  };

  const handleBlur = () => {
    setTabHasFocus(false);
    tabHasFocusNotState = false
  };

  const notifiOnclick = () => {
    window.focus();
  };

  const seenMessage = (channelArn: string) => {
    const lastMessage =
      newMessage && newMessage !== "HAS_UNREAD_MESSAGES"
        ? newMessage
        : messages?.channelMessages
          ? messages.channelMessages[messages.channelMessages.length - 1]
          : null;
    if (!lastMessage) return;
    markMessageSeen(channelArn, lastMessage);
  };

  const startNewestActiveChannel = async () => {
    const channelList = await listChannelMembershipsForAppInstanceUser(
      member.userId
    );
    if (channelList.length === 0) return;
    let channelHasMessage = channelList.filter((channel: ChannelMembershipForAppInstanceUserSummary) => channel.ChannelSummary?.LastMessageTimestamp != null);
    channelHasMessage.sort((firstChannel: ChannelMembershipForAppInstanceUserSummary, nextChannel: ChannelMembershipForAppInstanceUserSummary) => {
      const timestampA = new Date(firstChannel.ChannelSummary!.LastMessageTimestamp!).getTime();
      const timestampB = new Date(nextChannel.ChannelSummary!.LastMessageTimestamp!).getTime();
      return timestampB - timestampA;
    });
    const newestChannel: ChannelMembershipForAppInstanceUserSummary = channelHasMessage[0];
    if (newestChannel && newestChannel.ChannelSummary?.ChannelArn) {
      if (!chimeUserIdRef.current) {
        dispatchReduxToolkit(channelSetChimeUserId(member.userId));
      }
      await dispatchReduxToolkit(channelSetChannelArn(newestChannel.ChannelSummary?.ChannelArn));
      await storeChannelInfo(newestChannel.ChannelSummary?.ChannelArn);
      await setNewMessage("GET_MESSAGE");
    }
  };

  //Use Effect
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    const connectingToChimeSDK = async () => {
      let headerKey = apiKey ? apiKey : await getApiKey();
      const awsKeys = await getChimeSecret(headerKey);
      AWS.config.region = process.env.REACT_APP_REACT_REGION;
      if (awsKeys) {
        const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
        if (awsKeysDecrypt) {
          let awsKeysDecryptData = JSON.parse(awsKeysDecrypt);
          AWS.config.credentials = {
            accessKeyId: awsKeysDecryptData.AccessKey,
            secretAccessKey: awsKeysDecryptData.SecretKey,
          };
          if (AWS.config.credentials && AWS.config.credentials.accessKeyId && AWS.config.credentials.secretAccessKey) {
            console.log("Subscribe to MessagingService ...");
            messagingService.setMember(member);
            messagingService.connect();
            messagingService.subscribeToMessageUpdate(messagesProcessor);
            setConnected(true);
          }
        }
      } else {
        console.log("Connecting to ChimeSDK failed ...");
      }
    };

    if (isSignIn && !connected) {
      connectingToChimeSDK();
    }
  }, [isSignIn, connected]);

  useEffect(() => {
    return () => {
      console.log("Unsubscribe to MessagingService ...");
      messagingService.close();
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
      dispatchReduxToolkit(channelClearState());
    };
  }, []);


  useEffect(() => {
    if (channelArn) {
      // hasn't open request detail yet
      if (!currentRequest) setOnActive(true);
      // had open request detail
      let currentRequestId = String(currentRequest?.ticketId) || "";
      let checkMatchingChannel = channelArn.toLowerCase().includes(currentRequestId);
      let checkMatchingRoutePath = location.pathname.toLowerCase().includes("/dashboard/requests/request-detail");
      if (checkMatchingChannel && checkMatchingRoutePath) {
        setOnActive(false);
      } else {
        setOnActive(true);
      }
    }
  }, [currentRequest, location, channelArn]);

  useEffect(() => {
    if (!channelArn) setHasUnReadMessage(false);
  }, [channelArn]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Fragment>
        {
          onActive && <>
            {(!!channelArn || hasUnReadMessage) && (
              <div>
                <Launcher
                  title={channelName}
                  onMessageWasSent={onMessageWasSent}
                  newMessage={newMessage}
                  browserLanguage={browserLanguage}
                  newMessagesCount={unSeenMessage}
                  onClick={onClick}
                  isOpen={widgetOpened}
                  placeholder={t("ChatWidget_Placeholder")}
                  updateMarker={() => {
                    updateChannelReadMarker(channelArn, chimeUserId);
                    seenMessage(channelArn);
                    setUnSeenMessage(0);
                  }}
                />
              </div>
            )}
          </>
        }
      </Fragment>
    </ThemeProvider>
  );
};

export default ChatWidget;
