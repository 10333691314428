import { useTranslation } from "react-i18next";
import AdsComponentTitle from "../../../components/adsComponentTitle/AdsComponentTitle";
import style from "./AdsVehicleIdentification.module.scss";
import RDDropdown from "../../../../components/RDComponents/RDDropdown";
import { useAppDispatch } from "../../../../redux/hooks";
import { useSelector } from "react-redux";
import {
  NewRequestStep,
  selectDisabledFields,
  selectFormValues,
  selectSearchResult,
  setCurrentStep,
  updateDisabledFields,
  updateFormValues,
  updateSearchResult,
} from "../../../../redux/newRequestSlice";
import { selectCountryCode, selectIsSignIn } from "../../../../redux/auth";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { DropdownItemProps, Icon, InputOnChangeData } from "semantic-ui-react";
import { Constants } from "../../../../utils/helpers/Constants";
import {
  GetVehicleSelectionsAsyncInput,
  getVehicleSelectionsAsync,
  searchMethodsByCountryAsync,
} from "../../../../services/VehicleInformationService";
import { SearchFunction } from "../../../../models/enums/searchFunction";
import CustomLoader from "../../../../components/loading/CustomLoader";
import { SEARCH_BY_VIN_METHOD_ID } from "../../../../constants/automaticSearch.constants";
import RDButton from "../../../../components/RDComponents/RDButton";
import {
  ADS_ENGINE_SEARCH_QUERY_KEY,
  ADS_MAKE_SEARCH_QUERY_KEY,
  ADS_MODEL_SEARCH_QUERY_KEY,
  ADS_VEHICLE_IDENTIFICATION_ERROR,
  ADS_VEHICLE_LOADING_FIELDS,
  ADS_VIN_SEARCH_QUERY_KEY,
  ADS_YEAR_SEARCH_QUERY_KEY,
} from "../../../constants/adsNewRequest.constant";
import AdsVehicleIdentificationSearch from "../adsVehicleIdentificationSearch/AdsVehicleIdentificationSearch";
import AdsBlockTitle from "../../../components/adsBlockTitle/AdsBlockTitle";
import RDInput from "../../../../components/RDComponents/RDInput";
import {
  AdsVehicleIdentificationErrors,
  AdsVehicleInfoData,
  AdsVehicleLoadingFields,
} from "../../../types/adsNewRequest.type";
import { useAdsNewRequest } from "../../../hooks/useAdsNewRequest";
import { EVehicleIdentDisabledField } from "../../../../types/newRequest.type";
import useCaseInsensitiveSearchParams from "../../../../hooks/useCaseInsensitiveSearchParams";

const AdsVehicleIdentification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getParam } = useCaseInsensitiveSearchParams();
  const formValues = useSelector(selectFormValues);
  const countryCode = useSelector(selectCountryCode);
  const isLoggedIn = useSelector(selectIsSignIn);
  const disabledFields = useSelector(selectDisabledFields);
  const foundVehicle = useSelector(selectSearchResult);
  const { validateMileage, validateVinNumber, validateLicensePlate } =
    useAdsNewRequest();
  const [brandOptions, setBrandOptions] = useState<DropdownItemProps[]>([]);
  const [modelOptions, setModelOptions] = useState<DropdownItemProps[]>([]);
  const [engineOptions, setEngineOptions] = useState<DropdownItemProps[]>([]);
  const [yearOptions, setYearOptions] = useState<DropdownItemProps[]>([]);
  const [variantOptions, setVariantOptions] = useState<DropdownItemProps[]>([]);
  const [isShowLoader, setShowLoader] = useState(false);
  const [formErrors, setFormErrors] = useState<AdsVehicleIdentificationErrors>(
    ADS_VEHICLE_IDENTIFICATION_ERROR
  );
  const [vehicleLoadingFields, setVehicleLoadingFields] =
    useState<AdsVehicleLoadingFields>(ADS_VEHICLE_LOADING_FIELDS);
  const [isAutoValidateEnabled, setAutoValidateEnabled] = useState(false);
  const [isShowEditVehicleComponent, setShowEditVehicleComponent] =
    useState(false);
  const vinParam = getParam(ADS_VIN_SEARCH_QUERY_KEY);
  const yearParam = getParam(ADS_YEAR_SEARCH_QUERY_KEY);
  const makeParam = getParam(ADS_MAKE_SEARCH_QUERY_KEY);
  const modelParam = getParam(ADS_MODEL_SEARCH_QUERY_KEY);
  const engineParam = getParam(ADS_ENGINE_SEARCH_QUERY_KEY);

  useEffect(() => {
    if (!isLoggedIn) return;

    setShowLoader(true);
    dispatch(searchMethodsByCountryAsync()).finally(() => {
      setShowLoader(false);
    });
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(
      updateFormValues({
        searchMethodId: SEARCH_BY_VIN_METHOD_ID,
        searchValue: !!formValues.vin ? formValues.vin : vinParam || "",
        vin: !!formValues.vin ? formValues.vin : vinParam || "",
      })
    );
  }, [countryCode]);

  useEffect(() => {
    if (!isLoggedIn || !countryCode) return;

    getBrandsModelsYearsVariantsInformationAsync(
      "make",
      {
        searchFunction: SearchFunction.Make,
        countryCode,
      },
      setBrandOptions
    );
  }, [isLoggedIn, countryCode]);

  useEffect(() => {
    if (!!vinParam || !!formValues.isCompletedStepVehicleIdentification) {
      setShowEditVehicleComponent(true);
      return;
    }

    setShowEditVehicleComponent(false);
  }, [formValues.isCompletedStepVehicleIdentification]);

  useEffect(() => {
    if (!formValues.brandSelected || !countryCode) return;

    getBrandsModelsYearsVariantsInformationAsync(
      "model",
      {
        searchFunction: SearchFunction.Model,
        make: formValues.brandSelected,
        countryCode,
      },
      setModelOptions
    );
  }, [formValues?.brandSelected, countryCode]);

  useEffect(() => {
    if (!formValues.modelSelected) return;

    getEngineOptionsAsync();
  }, [formValues?.modelSelected]);

  useEffect(() => {
    if (!formValues.engineSelected || !countryCode) return;

    getBrandsModelsYearsVariantsInformationAsync(
      "engine",
      {
        searchFunction: SearchFunction.YearsByEngine,
        make: formValues.brandSelected,
        model: formValues.modelSelected,
        engine:
          formValues.engineSelected !== Constants.ELECTRICAL_VEHICLE_ENGINE_ID
            ? formValues.engineSelected.toString()
            : "",
        countryCode,
      },
      setYearOptions
    );
  }, [formValues.engineSelected, countryCode]);

  useEffect(() => {
    if (
      !formValues.yearSelected ||
      !countryCode ||
      countryCode.trim().toLowerCase() !== "us"
    )
      return;

    getBrandsModelsYearsVariantsInformationAsync(
      "year",
      {
        searchFunction: SearchFunction.VariantsByEngineAndYear,
        make: formValues.brandSelected,
        model: formValues.modelSelected,
        engine:
          formValues.engineSelected !== Constants.ELECTRICAL_VEHICLE_ENGINE_ID
            ? formValues.engineSelected.toString()
            : "",
        year: formValues.yearSelected.toString(),
        countryCode: countryCode,
      },
      setVariantOptions
    );
  }, [formValues.yearSelected, countryCode]);

  useEffect(() => {
    if (
      brandOptions.length === 0 ||
      !!formValues.isCompletedStepVehicleIdentification ||
      !!formValues.isFormReset ||
      !makeParam
    )
      return;

    autofillForMakeField(makeParam);
  }, [
    brandOptions,
    formValues.isCompletedStepVehicleIdentification,
    formValues.isFormReset,
  ]);

  useEffect(() => {
    if (!foundVehicle?.vin) return;

    dispatch(
      updateFormValues({
        vin: foundVehicle?.vin || "",
      })
    );
    setFormErrors((prev) => ({
      ...prev,
      vin: validateVinNumber(foundVehicle?.vin),
    }));
  }, [foundVehicle?.vin]);

  useEffect(() => {
    if (brandOptions.length === 0 || !foundVehicle?.brand) return;

    autofillForMakeField(foundVehicle.brand);
  }, [brandOptions, foundVehicle?.brand]);

  useEffect(() => {
    if (
      modelOptions.length === 0 ||
      !!formValues.isCompletedStepVehicleIdentification ||
      !!formValues.isFormReset ||
      !makeParam ||
      !modelParam
    )
      return;

    autofillForModelField(modelParam);
  }, [
    modelOptions,
    formValues.isCompletedStepVehicleIdentification,
    formValues.isFormReset,
  ]);

  useEffect(() => {
    if (modelOptions.length === 0 || !foundVehicle?.model) return;

    autofillForModelField(foundVehicle.model);
  }, [modelOptions, foundVehicle?.model]);

  useEffect(() => {
    if (
      engineOptions.length === 0 ||
      !!formValues.isCompletedStepVehicleIdentification ||
      !!formValues.isFormReset ||
      !makeParam ||
      !modelParam ||
      !engineParam
    )
      return;

    autofillForEngineField(engineParam);
  }, [
    engineOptions,
    formValues.isCompletedStepVehicleIdentification,
    formValues.isFormReset,
  ]);

  useEffect(() => {
    if (engineOptions.length === 0 || !foundVehicle?.engine) return;

    autofillForEngineField(foundVehicle.engine);
  }, [engineOptions, foundVehicle?.engine]);

  useEffect(() => {
    if (
      yearOptions.length === 0 ||
      !!formValues.isCompletedStepVehicleIdentification ||
      !!formValues.isFormReset ||
      !makeParam ||
      !modelParam ||
      !engineParam ||
      !yearParam
    )
      return;

    autofillForYearField(yearParam);
  }, [
    yearOptions,
    formValues.isCompletedStepVehicleIdentification,
    formValues.isFormReset,
  ]);

  useEffect(() => {
    if (yearOptions.length === 0 || !foundVehicle?.year) return;

    autofillForYearField(foundVehicle.year);
  }, [yearOptions, foundVehicle?.year]);

  useEffect(() => {
    if (!!brandOptions && brandOptions.length === 1) {
      dispatch(
        updateFormValues({
          brandSelected: brandOptions[0].value as any,
        })
      );
      dispatch(updateDisabledFields(EVehicleIdentDisabledField.make));
    }
  }, [brandOptions]);

  useEffect(() => {
    if (!!modelOptions && modelOptions.length === 1) {
      dispatch(
        updateFormValues({
          modelSelected: modelOptions[0].value as any,
        })
      );
      dispatch(updateDisabledFields(EVehicleIdentDisabledField.model));
    }
  }, [modelOptions]);

  useEffect(() => {
    if (!!engineOptions && engineOptions.length === 1) {
      dispatch(
        updateFormValues({
          engineSelected: engineOptions[0].value as any,
        })
      );
      dispatch(updateDisabledFields(EVehicleIdentDisabledField.engine));
    }
  }, [engineOptions]);

  useEffect(() => {
    if (!!yearOptions && yearOptions.length === 1) {
      dispatch(
        updateFormValues({
          yearSelected: Number(yearOptions[0].value as any),
        })
      );
      dispatch(updateDisabledFields(EVehicleIdentDisabledField.year));
    }
  }, [yearOptions]);

  useEffect(() => {
    if (!!variantOptions && variantOptions.length === 1) {
      dispatch(
        updateFormValues({
          variantSelected: variantOptions[0].value as any,
        })
      );
      dispatch(updateDisabledFields(EVehicleIdentDisabledField.variant));
    }
  }, [variantOptions]);

  useEffect(() => {
    if (!isAutoValidateEnabled) return;

    setFormErrors((prev) => ({
      ...prev,
      make: !formValues.brandSelected ? t("ADS_PleaseSelectAValue") : "",
    }));
  }, [formValues.brandSelected, isAutoValidateEnabled]);

  useEffect(() => {
    if (!isAutoValidateEnabled) return;

    setFormErrors((prev) => ({
      ...prev,
      model: !formValues.modelSelected ? t("ADS_PleaseSelectAValue") : "",
    }));
  }, [formValues.modelSelected, isAutoValidateEnabled]);

  useEffect(() => {
    if (!isAutoValidateEnabled) return;

    setFormErrors((prev) => ({
      ...prev,
      engine: !formValues.engineSelected ? t("ADS_PleaseSelectAValue") : "",
    }));
  }, [formValues.engineSelected, isAutoValidateEnabled]);

  useEffect(() => {
    if (!isAutoValidateEnabled) return;

    setFormErrors((prev) => ({
      ...prev,
      year: !formValues.yearSelected ? t("ADS_PleaseSelectAValue") : "",
    }));
  }, [formValues.yearSelected, isAutoValidateEnabled]);

  useEffect(() => {
    if (!isAutoValidateEnabled || countryCode.toLowerCase() !== "us") return;

    setFormErrors((prev) => ({
      ...prev,
      variant: !formValues.variantSelected ? t("ADS_PleaseSelectAValue") : "",
    }));
  }, [formValues.variantSelected, isAutoValidateEnabled, countryCode]);

  useEffect(() => {
    setFormErrors((prev) => ({
      ...prev,
      mileage: validateMileage(formValues.mileage || ""),
    }));
  }, [formValues.mileage]);

  const autofillForMakeField = (make: string) => {
    const targetBrand = make.trim().toLowerCase();

    if (!targetBrand) return;

    const matchedBrandOption = brandOptions.find(
      (option) =>
        !!option.value &&
        option.value.toString().trim().toLowerCase() === targetBrand
    );

    if (!matchedBrandOption?.value) return;

    dispatch(
      updateFormValues({
        brandSelected: matchedBrandOption?.value
          ? matchedBrandOption?.value.toString()
          : "",
      })
    );
    dispatch(updateDisabledFields(EVehicleIdentDisabledField.make));
  };

  const autofillForModelField = (model: string) => {
    const targetModel = model.trim().toLowerCase();

    if (!targetModel) return;

    const matchedModelOption = modelOptions.find(
      (option) =>
        !!option.value &&
        option.value.toString().trim().toLowerCase() === targetModel
    );

    if (!matchedModelOption?.value) return;

    dispatch(
      updateFormValues({
        modelSelected: matchedModelOption?.value.toString(),
      })
    );
    dispatch(updateDisabledFields(EVehicleIdentDisabledField.model));
  };

  const autofillForEngineField = (engine: string) => {
    const targetEngine = engine.trim().toLowerCase();

    if (!targetEngine) return;

    const matchedEngine = getEngineAutoFillValue(targetEngine);

    if (!matchedEngine) return;

    dispatch(
      updateFormValues({
        engineSelected: matchedEngine || 0,
      })
    );
    dispatch(updateDisabledFields(EVehicleIdentDisabledField.engine));
  };

  const autofillForYearField = (targetYear: string) => {
    if (!targetYear) return;

    const matchedYearOption = yearOptions.find(
      (option) => option.value?.toString().trim() === targetYear.trim()
    );

    if (!matchedYearOption?.value) return;

    dispatch(
      updateFormValues({
        yearSelected: Number(matchedYearOption.value),
      })
    );
    dispatch(updateDisabledFields(EVehicleIdentDisabledField.year));
  };

  const getEngineAutoFillValue = (engine: string): any => {
    if (engineOptions.length === 0) return null;

    const textRegex = new RegExp("[a-zA-Z]", "g");

    let targetEngine = engine
      .replace(textRegex, "")
      .slice(0, 4)
      .trim()
      .toLowerCase();

    if (!Number(targetEngine)) return;

    targetEngine = Number(targetEngine).toFixed(1);

    const matchedEngine = engineOptions.find((engine) => {
      if (!engine.value) return false;

      if (engine.value.toString().trim().toLowerCase() === targetEngine)
        return true;

      if (Number(engine.value.toString().trim()) === Number(targetEngine))
        return true;

      return false;
    });

    if (!!matchedEngine?.value && Number(matchedEngine?.value))
      return matchedEngine.value as string;

    const formattedTargetEngine = targetEngine.replace(textRegex, "").trim();

    const relevantEngines = engineOptions.filter(
      (engine) =>
        !!engine.value &&
        engine.value
          .toString()
          .trim()
          .toLowerCase()
          .includes(`${formattedTargetEngine}`)
    );

    if (
      relevantEngines.length === 1 &&
      !!relevantEngines[0].value &&
      relevantEngines[0].value
    )
      return relevantEngines[0].value as string;

    return null;
  };

  const getBrandsModelsYearsVariantsInformationAsync = async (
    field: AdsVehicleInfoData,
    payload: GetVehicleSelectionsAsyncInput,
    callBack: Dispatch<SetStateAction<DropdownItemProps[]>>
  ) => {
    setVehicleLoadingFields((prev) => ({
      ...prev,
      [field]: true,
    }));

    const response = await dispatch(getVehicleSelectionsAsync(payload));

    if (!!response.payload) {
      callBack(
        (response.payload as string[]).map((x) => {
          return {
            key: x,
            text: x,
            value: x,
          };
        })
      );
    } else callBack([]);

    setVehicleLoadingFields((prev) => ({
      ...prev,
      [field]: false,
    }));
  };

  const getEngineOptionsAsync = async () => {
    setVehicleLoadingFields((prev) => ({
      ...prev,
      engine: true,
    }));

    const response = await dispatch(
      getVehicleSelectionsAsync({
        searchFunction: SearchFunction.Engine,
        make: formValues.brandSelected,
        model: formValues.modelSelected,
        countryCode,
      })
    );

    if (!!response.payload) {
      setEngineOptions(
        (response.payload as string[])
          .map((x) => (!x ? Constants.ELECTRICAL_VEHICLE_ENGINE_ID : x))
          .map((x) => {
            return {
              key: x,
              text:
                x === Constants.ELECTRICAL_VEHICLE_ENGINE_ID
                  ? t("ManualSearch_Electrical_Vehicle")
                  : x,
              value: x,
            };
          })
      );
    } else setEngineOptions([]);

    setVehicleLoadingFields((prev) => ({
      ...prev,
      engine: false,
    }));
  };

  const onBrandChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        brandSelected: value,
        modelSelected: "",
        engineSelected: 0,
        yearSelected: 0,
        variantSelected: "",
        disabledField: [],
      })
    );

    dispatch(updateSearchResult(null));
  };

  const onModelChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        modelSelected: value,
        engineSelected: 0,
        yearSelected: 0,
        variantSelected: "",
        disabledField: formValues.disabledField.filter(
          (field) => field === "make"
        ),
      })
    );
  };

  const onEngineChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        engineSelected: value,
        yearSelected: 0,
        variantSelected: "",
        disabledField: formValues.disabledField.filter(
          (field) => field === "make" || field === "model"
        ),
      })
    );
  };

  const onYearChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        yearSelected: Number(value),
        variantSelected: "",
        disabledField: formValues.disabledField.filter(
          (field) => field === "make" || field === "model" || field === "engine"
        ),
      })
    );
  };

  const onVariantChanged = (_: any, { value }: any) => {
    dispatch(
      updateFormValues({
        variantSelected: value,
      })
    );
  };

  const onMileageInputChange = (
    _: ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    dispatch(
      updateFormValues({
        mileage: value,
      })
    );
  };

  const onResetVehicleInfo = () => {
    const isDvciDisabled = formValues.disabledField.includes("dvci");

    dispatch(
      updateFormValues({
        brandSelected: "",
        modelSelected: "",
        engineSelected: 0,
        yearSelected: 0,
        variantSelected: "",
        mileage: "",
        disabledField: isDvciDisabled ? ["dvci"] : [],
        isFormReset: true,
      })
    );
    dispatch(updateSearchResult(null));
  };

  const isShowResetVehicleInfoButton = () => {
    return (
      !!formValues.brandSelected ||
      !!formValues.modelSelected ||
      !!formValues.engineSelected ||
      !!formValues.yearSelected ||
      !!formValues.variantSelected
    );
  };

  const isAbleToSubmit = (): boolean => {
    const vinError = validateVinNumber();
    const plateError = validateLicensePlate(false);
    const makeError = !formValues.brandSelected
      ? t("ADS_PleaseSelectAValue")
      : "";
    const modelError = !formValues.modelSelected
      ? t("ADS_PleaseSelectAValue")
      : "";
    const engineError = !formValues.engineSelected
      ? t("ADS_PleaseSelectAValue")
      : "";
    const yearError = !formValues.yearSelected
      ? t("ADS_PleaseSelectAValue")
      : "";
    const variantError =
      countryCode.trim().toLowerCase() === "us" && !formValues.variantSelected
        ? t("ADS_PleaseSelectAValue")
        : "";
    const mileageError = validateMileage(formValues.mileage || "");

    setFormErrors((prev) => ({
      ...prev,
      vin: vinError,
      plate: plateError,
      make: makeError,
      model: modelError,
      engine: engineError,
      year: yearError,
      variant: variantError,
      mileage: mileageError,
    }));

    return (
      !vinError &&
      !plateError &&
      !makeError &&
      !modelError &&
      !engineError &&
      !yearError &&
      !variantError &&
      !mileageError
    );
  };

  const onClickNextButton = () => {
    setAutoValidateEnabled(true);

    if (!isAbleToSubmit()) return;

    dispatch(
      updateFormValues({
        isCompletedStepVehicleIdentification: true,
      })
    );
    dispatch(setCurrentStep(NewRequestStep.Request_Detail));
  };

  return (
    <>
      <div className={style.container}>
        <AdsComponentTitle
          title={t("NewRequestCDM3_Breadcrumb_VehicleIdentification")}
        />
        <div className={style.wrapper}>
          <AdsVehicleIdentificationSearch
            vinError={formErrors.vin}
            plateError={formErrors.plate}
            setErrors={setFormErrors}
            setAutoValidateEnabled={setAutoValidateEnabled}
            setShowEditVehicleComponent={setShowEditVehicleComponent}
          />
          {isShowEditVehicleComponent ? (
            <div className={style.vehicle_info_wrapper}>
              <div className={style.vehicle_info_title}>
                <AdsBlockTitle title={t("ADS_SelectedVehicle")} />
                {isShowResetVehicleInfoButton() ? (
                  <div
                    className={style.reset_button}
                    onClick={onResetVehicleInfo}
                  >
                    <Icon name="redo" />
                    <span>{t("ADS_ResetVehicle")}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={style.vehicle_info}>
                <div className={style.block}>
                  {!disabledFields.includes("make") ? (
                    <RDDropdown
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Make"
                      )} *`}
                      options={brandOptions}
                      search
                      selectOnBlur={false}
                      placeholder="-"
                      value={formValues?.brandSelected}
                      onChange={onBrandChanged}
                      errorMessage={formErrors.make}
                    />
                  ) : (
                    <RDInput
                      disabled
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Make"
                      )} *`}
                      value={formValues?.brandSelected}
                    />
                  )}
                  {!disabledFields.includes("model") ? (
                    <RDDropdown
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Model"
                      )} *`}
                      options={modelOptions}
                      search
                      selectOnBlur={false}
                      placeholder="-"
                      onChange={onModelChanged}
                      value={formValues?.modelSelected}
                      disabled={!formValues?.brandSelected}
                      errorMessage={formErrors.model}
                    />
                  ) : (
                    <RDInput
                      disabled
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Model"
                      )} *`}
                      value={formValues?.modelSelected}
                    />
                  )}
                  {!disabledFields.includes("engine") ? (
                    <RDDropdown
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Engine"
                      )} *`}
                      options={engineOptions}
                      search
                      selectOnBlur={false}
                      placeholder="-"
                      value={formValues?.engineSelected?.toString()}
                      onChange={onEngineChanged}
                      disabled={!formValues?.modelSelected}
                      errorMessage={formErrors.engine}
                    />
                  ) : (
                    <RDInput
                      disabled
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Engine"
                      )} *`}
                      value={
                        formValues?.engineSelected ===
                        Constants.ELECTRICAL_VEHICLE_ENGINE_ID
                          ? t("ManualSearch_Electrical_Vehicle")
                          : formValues?.engineSelected?.toString()
                      }
                    />
                  )}
                </div>
                <div className={style.block}>
                  {!disabledFields.includes("year") ? (
                    <RDDropdown
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Year"
                      )} *`}
                      options={yearOptions}
                      search
                      selectOnBlur={false}
                      placeholder="-"
                      value={formValues?.yearSelected.toString()}
                      onChange={onYearChanged}
                      disabled={!formValues?.engineSelected}
                      errorMessage={formErrors.year}
                    />
                  ) : (
                    <RDInput
                      disabled
                      title={`${t(
                        "MyRequests_First_SelectVehicleModal_Year"
                      )} *`}
                      value={formValues?.yearSelected.toString()}
                    />
                  )}
                  {countryCode.trim().toLowerCase() === "us" ? (
                    !disabledFields.includes("variant") ? (
                      <RDDropdown
                        title={`${t(
                          "MyRequests_First_SelectVehicleModal_Variant"
                        )} *`}
                        options={variantOptions}
                        search
                        selectOnBlur={false}
                        placeholder="-"
                        value={formValues?.variantSelected}
                        onChange={onVariantChanged}
                        disabled={!formValues?.yearSelected}
                        errorMessage={formErrors.variant}
                      />
                    ) : (
                      <RDInput
                        disabled
                        title={`${t(
                          "MyRequests_First_SelectVehicleModal_Variant"
                        )} *`}
                        value={formValues?.variantSelected}
                      />
                    )
                  ) : (
                    <></>
                  )}
                  <RDInput
                    title={t("ADS_NewRequest_Mileage")}
                    value={formValues.mileage}
                    onChange={onMileageInputChange}
                    errorMessage={formErrors.mileage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={style.bottom}>
            <RDButton onClick={onClickNextButton}>
              {t("CommonText_Next")}
            </RDButton>
          </div>
        </div>
      </div>
      {isShowLoader ||
      Object.values(vehicleLoadingFields).some((state) => !!state) ? (
        <CustomLoader enable />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdsVehicleIdentification;
