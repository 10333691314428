import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ChatWindow from './ChatWindow'
import launcherIcon from './assets/popup-button.svg'
import './styles/launcher.css'

function Launcher(props) {
  const {
    title,
    onClick,
    newMessage,
    onMessageWasSent,
    placeholder,
    browserLanguage,
    newMessagesCount,
  } = props

  const defaultState = {
    isOpen: false,
  }

  const [state, setState] = useState(defaultState)

  function handleClick() {
    if (onClick) {
      onClick()
    } else {
      setState((state) => ({
        ...state,
        isOpen: !state.isOpen,
      }))
    }
  }

  return (
    <div id="sc-launcher">
      <div
        className={classNames('sc-launcher', { opened: state.isOpen })}
        onClick={handleClick}
      >
        {newMessagesCount !== 0 ? (
          <div className={'sc-count-unseen'}>
            {newMessagesCount > 10 ? '9+' : newMessagesCount}
          </div>
        ) : (
          <></>
        )}
        <img className={'sc-closed-icon'} src={launcherIcon} />
      </div>

      <ChatWindow
        title={title}
        newMessage={newMessage}
        onUserInputSubmit={onMessageWasSent}
        isOpen={props.isOpen}
        onClose={onClick}
        placeHolder={placeholder}
        updateMarker={props.updateMarker}
        browserLanguage={browserLanguage}
      />
    </div>
  )
}

Launcher.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  newMessage: PropTypes.any,
  onMessageWasSent: PropTypes.func,
  placeholder: PropTypes.string,
  updateMarker: PropTypes.any,
  browserLanguage: PropTypes.string,
  newMessagesCount: PropTypes.number,
  socketConnection: PropTypes.bool,
}

Launcher.defaultProps = {
  isOpen: false,
  mute: false,
  showEmoji: true,
  newMessage: [''],
  fileUpload: true,
  placeholder: 'Write a reply...',
  newMessagesCount: 0,
  socketConnection: true,
}

export default Launcher
