import { SharedRouteConfigure } from "../../models/Route";
import { Constants } from "../../utils/helpers/Constants";
import { REQUEST_ID_PARAM } from "../commonParams.constant";

/**
 * SHARED ROUTES
 * Do not import Shared routes to any routing configures
 */

export const CONTACT_US_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/contacts",
  name: "Contact",
  title: "Contact",
  crumb: "BreadScrumb_Contact",
  icon: "Contact",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_Contact",
    },
  ],
};

export const HOME_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/",
  name: "Home",
  title: "Remote Diagnostics Portal",
  crumb: "BreadScrumb_Home",
  icon: "Home",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
    },
  ],
};

export const SUBSCRIPTIONS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/subscriptions",
  name: "Subscriptions",
  title: "Subscriptions",
  crumb: "MenuHeaderPopup_Subscriptions_Headline",
  icon: "Subscriptions",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuHeaderPopup_Subscriptions_Headline",
    },
  ],
};

export const SUBSCRIPTION_DETAILS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/subscriptions/:id",
  name: "Subscription detail",
  title: "Subscription detail",
  crumb: "MenuHeaderPopup_Subscriptions_Headline",
  icon: "Subscriptions",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuHeaderPopup_Subscriptions_Headline",
      path: SUBSCRIPTIONS_SHARED_ROUTE.path,
    },
    {
      label: "CDM3Support_FAQs_section2_title",
    },
  ],
};

export const DASHBOARD_REQUESTS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/requests",
  name: "Requests",
  title: "Requests",
  crumb: "BreadScrumb_Dashboard_Requests",
  icon: "My Requests",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_Dashboard_Requests",
    },
  ],
};

export const DASHBOARD_REQUEST_DETAILS_SHARED_ROUTE: SharedRouteConfigure = {
  path: `/dashboard/requests/request-detail/:${REQUEST_ID_PARAM}`,
  name: "Requests",
  title: "Requests",
  crumb: "BreadScrumb_Dashboard_Requests",
  icon: "My Requests",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_Dashboard_Requests",
      path: DASHBOARD_REQUESTS_SHARED_ROUTE.path,
    },
    {
      label: "MyRequests_ProgressBar_First",
    },
  ],
};

export const DASHBOARD_TEAM_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/team",
  name: "Team Member",
  title: "Team Member",
  crumb: "DashboardCDM3_MainMenu_Team_Item",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "DashboardCDM3_MainMenu_Team_Item",
    },
  ],
};

export const DASHBOARD_TEAM_EDIT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/team/member/:employeeId?",
  name: "Member Edit",
  title: "Member Edit",
  crumb: "Dashboard_Team_Edit",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "DashboardCDM3_MainMenu_Team_Item",
      path: DASHBOARD_TEAM_SHARED_ROUTE.path,
    },
    {
      label: "Dashboard_Team_Edit",
    },
  ],
};

export const DASHBOARD_TEAM_CREATE_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/team/member-creation",
  name: "Member Create",
  title: "Member Create",
  crumb: "Dashboard_Team_Create",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "DashboardCDM3_MainMenu_Team_Item",
      path: DASHBOARD_TEAM_SHARED_ROUTE.path,
    },
    {
      label: "Dashboard_Team_Create",
    },
  ],
};

export const DASHBOARD_STATISTICS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard/statistics",
  name: "Statistics",
  title: "Statistics",
  crumb: "MenuItem_Statistics",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuItem_Statistics",
    },
  ],
};

export const DASHBOARD_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/dashboard",
  name: "Dashboard",
  title: "Dashboard",
  crumb: "MenuItem_MyRemote",
  icon: "Dashboard",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
};

export const WALLET_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/wallet",
  name: "Wallet",
  title: "Wallet",
  crumb: "BreadScrumb_Wallet",
  icon: "Wallet",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  isTrialHidden: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_Wallet",
    },
  ],
};

export const MY_SUBSCRIPTION_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/my-sub",
  name: "My Subscription",
  title: "My Subscription",
  crumb: "MenuHeaderPopup_MyAccount_Products",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  isTrialHidden: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuHeaderPopup_MyAccount_Products",
    },
  ],
};

export const SHOPPING_CART_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/v3/shopping-cart",
  name: "Shopping Cart",
  title: "Shopping Cart",
  crumb: "MenuHeaderPopup_ShoppingCart",
  icon: "Shopping Cart",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuHeaderPopup_ShoppingCart",
    },
  ],
};

export const CHECKOUT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/v3/shopping-cart/Checkout",
  name: "Check Out",
  title: "Breadcrumb_Checkout",
  crumb: "Breadcrumb_Checkout",
  icon: "Check Out",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Checkout",
    },
  ],
};

export const MY_REQUESTS_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/new-request",
  name: "My Requests",
  title: "My Requests",
  crumb: "MyRequests_Headline",
  icon: "My Requests",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "NewRequestCDM3_Breadcrumb_NewRequest",
    },
  ],
};

export const MY_BOSCH_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/myprofile",
  name: "My Profile",
  title: "My Profile",
  crumb: "MenuItem_MyBosch",
  icon: "My Profile",
  exact: true,
  isProtected: true,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuItem_MyBosch",
    },
  ],
};

export const SUPPORT_SHARED_ROUTE: SharedRouteConfigure = {
  path: "/support",
  name: "Support",
  title: "Support",
  crumb: "MenuHeaderPopup_Support_Headline",
  icon: "Support",
  exact: true,
  isProtected: false,
  permission: "",
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "MenuHeaderPopup_Support_Headline",
    },
  ],
};
