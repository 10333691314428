export enum SideNavItemKey {
  _, // To avoid index 0
  HOME,
  PRODUCTS, // Subscriptions
  PRODUCT_DETAILS, // Subscription details
  NEW_REQUEST,
  MY_REMOTE,
  ALL_REQUESTS,
  REQUEST_DETAILS,
  HARDWARE,
  TEAM,
  TEAM_CREATE,
  TEAM_EDIT,
  WALLET,
  MY_PRODUCTS,
  STATISTICS,
  MY_BOSCH,
  SUPPORT,
  SHOPPING_CART,
  CHECKOUT,
  CONTACT,
}

export interface SideNavItem {
  key: SideNavItemKey;
  icon: string;
  label: string;
  path: string;
  isLoginRequired: boolean;
  supportedRoles: number[];
  isActive: boolean;
  childItems?: SideNavItem[];
  isAccountMappedRequired?: boolean;
  isHideOnSideNavigation?: boolean;
}

export type MinimalSideNavItem = Omit<
  SideNavItem,
  "isLoginRequired" | "supportedRoles" | "isActive" | "isAccountMappedRequired"
>;
