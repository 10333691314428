import CartButton from "../../features/header/cartButton/CartButton";

const HeaderActions = () => {
  return (
    <>
      <CartButton />
    </>
  );
};

export default HeaderActions;
