import { Icon, MinimalHeader } from "@bosch/react-frok";
import style from "./AppWrapper.module.scss";
import SideNavigation from "../sideNavigation/SideNavigation";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import {
  selectCountryCode,
  selectLanguage,
  selectOpenSideNav,
} from "../../redux/auth";
import RDFrokBreacrumbs from "../../components/RDComponents/RDFrokBreacrumbs/RDFrokBreacrumbs";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouteConfigure } from "../../models/Route";
import { useRouting } from "../../hooks/useRouting";
import {
  selectIsForcedToHideNotificationBanner,
  updateForcedToHideNotificationBanner,
} from "../../redux/maintenanceSlice";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { MaintenanceBannerConstants } from "../../utils/helpers/MaintenanceMessageConstants";
import { SERMI_SUPPORTED_COUNTRIES } from "../../constants/faq.constants";
import { getHeightOfComponent } from "../../utils/dom.util";
import HeaderActions from "../headerActions/HeaderActions";

type Props = {
  route: JSX.Element;
};

const AppWrapper = ({ route }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getCurrentRoute } = useRouting();
  const language = useSelector(selectLanguage);
  const countryCode = useSelector(selectCountryCode);
  const isOpenSideNav = useSelector(selectOpenSideNav);
  const isForceToHideNotificationBanner = useSelector(
    selectIsForcedToHideNotificationBanner
  );
  const [currentRoute, setCurrentRoute] = useState<RouteConfigure | undefined>(
    undefined
  );
  const [isMaintenanceScheduleOn, setIsMaintenanceScheduleOn] = useState(false);
  const [isShowTHLMessage, setIsShowTHLMessage] = useState(false);
  const [notificationTHLMessage, setNotificationTHLMessage] = useState("");
  const [isShowPPUMessage, setIsShowPPUMessage] = useState(false);
  const [notificationPPUMessage, setNotificationPPUMessage] = useState("");
  const [isShowSERMIMessage, setIsShowSERMIMessage] = useState(false);
  const [notificationSERMIMessage, setNotificationSERMIMessage] = useState("");
  const [isShowMaintenanceBanner, setShowMaintenanceBanner] = useState(false);
  const maintenanceScheduleDatetime = useMemo(
    () => "19/01/2024 5:00 AM (CET)",
    []
  );
  const maintenanceSupportEmail = useMemo(
    () => "remote.diagnostics@bosch.com",
    []
  );
  const maintenanceBlockRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [maintenanceBannerHeight, setMaintenanceBannerHeight] = useState(0);

  useEffect(() => {
    setCurrentRoute(getCurrentRoute());
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      (isMaintenanceScheduleOn ||
        isShowTHLMessage ||
        isShowPPUMessage ||
        isShowSERMIMessage) &&
      !isForceToHideNotificationBanner
    )
      setShowMaintenanceBanner(true);
    else setShowMaintenanceBanner(false);
  }, [
    isMaintenanceScheduleOn,
    isShowTHLMessage,
    isShowPPUMessage,
    isShowSERMIMessage,
    isForceToHideNotificationBanner,
  ]);

  useEffect(() => {
    if (!isShowMaintenanceBanner) {
      setMaintenanceBannerHeight(0);
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setMaintenanceBannerHeight(getHeightOfComponent(maintenanceBlockRef));
      window.clearTimeout(timeoutId);
    }, 50);
  }, [isShowMaintenanceBanner]);

  useEffect(() => {
    if (!countryCode) return;

    // Check for THL notification messages
    if (
      MaintenanceBannerConstants.ENABLE_THL_MESSAGE_COUNTRIES.includes(
        countryCode?.toUpperCase()
      )
    ) {
      const thlMessage = MaintenanceBannerConstants.THLPhaseOutMessages.find(
        (pair) => {
          return pair.country?.toUpperCase() === countryCode?.toUpperCase();
        }
      );

      setIsShowTHLMessage(true);
      setNotificationTHLMessage(thlMessage?.message ?? "");
    } else {
      setIsShowTHLMessage(false);
      setNotificationTHLMessage("");
    }

    // Check for PPU to Credit notification messages
    if (
      MaintenanceBannerConstants.ENABLE_PPU_MESSAGE_COUNTRIES.includes(
        countryCode?.toUpperCase()
      )
    ) {
      const ppuMessage = MaintenanceBannerConstants.PPUToCreditMessages.find(
        (pair) => {
          return pair?.language?.toUpperCase() === language?.toUpperCase();
        }
      );

      setIsShowPPUMessage(
        !SERMI_SUPPORTED_COUNTRIES.includes(countryCode.toUpperCase())
      );
      setNotificationPPUMessage(
        ppuMessage?.message ?? MaintenanceBannerConstants.DEFAULT_PPU_MESSAGE
      );
    } else {
      setIsShowPPUMessage(false);
      setNotificationPPUMessage("");
    }

    if (SERMI_SUPPORTED_COUNTRIES.includes(countryCode.toUpperCase())) {
      const targetCountry =
        MaintenanceBannerConstants.SERMI_PHASE_OUT_MESSAGES.find(
          (pair) => pair.language.toLowerCase() === language.toLowerCase()
        );
      const faqLink = `${window.location.origin}/support`;
      const message = (
        targetCountry?.message ||
        MaintenanceBannerConstants.DEFAULT_SERMI_PHASE_OUT_MESSAGE
      ).replace("{link}", `<a href=${faqLink}>${faqLink}</a>`);

      setIsShowSERMIMessage(true);
      setNotificationSERMIMessage(message);
    } else {
      setIsShowSERMIMessage(false);
      setNotificationSERMIMessage("");
    }
  }, [countryCode, language]);

  useEffect(() => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    if (isShowMaintenanceBanner) {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;

      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    } else {
      wrapperRef.current.style.marginTop = "0";
      setHeightForSideNavigation("100vh");
    }
  }, [isShowMaintenanceBanner, maintenanceBannerHeight]);

  useEffect(() => {
    window.addEventListener("scroll", toggleMaintenanceNotificationBar);

    return () => {
      window.removeEventListener("scroll", toggleMaintenanceNotificationBar);
    };
  }, [
    maintenanceBannerHeight,
    window.scrollY,
    window.screen.availWidth,
    window.screen.availHeight,
  ]);

  const toggleMaintenanceNotificationBar = () => {
    if (!wrapperRef.current || !maintenanceBlockRef.current) return;

    const screenHeight = window.screen.height;
    const documentHeight = document.body.scrollHeight;

    if (documentHeight - screenHeight <= maintenanceBannerHeight) return;

    if (window.scrollY > maintenanceBannerHeight) {
      maintenanceBlockRef.current.style.display = "none";
      wrapperRef.current.style.marginTop = "0px";
      setHeightForSideNavigation("100vh");
    } else {
      const maintenanceBannerHeightAsString = maintenanceBannerHeight + "px";

      maintenanceBlockRef.current.style.display = "block";
      wrapperRef.current.style.marginTop = maintenanceBannerHeightAsString;
      setHeightForSideNavigation(
        `calc(100vh - ${maintenanceBannerHeightAsString})`
      );
    }
  };

  const setHeightForSideNavigation = (maintenanceBannerHeight: string) => {
    if (!wrapperRef.current) return;

    const sideNavigation =
      wrapperRef.current.querySelector(".m-side-navigation");

    if (!sideNavigation) return;

    (sideNavigation as HTMLElement).style.height = maintenanceBannerHeight;
  };

  const isAbleToShowFooter = () => {
    return !(
      window.location.href.includes("/signincallback") ||
      window.location.href.includes("/signoutcallback") ||
      window.location.href.includes("/sessiontimeout") ||
      window.location.href.includes("/editprofilecallback") ||
      window.location.href.includes("/launchlinkredirect") ||
      window.location.href.includes("/meeting")
    );
  };

  const handleCloseMaintenanceBanner = () => {
    setIsMaintenanceScheduleOn(false);
    setIsShowTHLMessage(false);
    setShowMaintenanceBanner(false);
    dispatch(updateForcedToHideNotificationBanner(true));

    if (!wrapperRef.current) return;

    wrapperRef.current.style.marginTop = "0px";
    setHeightForSideNavigation("100vh");
  };

  return (
    <div className={style.container}>
      {isShowMaintenanceBanner && (
        <div
          ref={maintenanceBlockRef}
          className={`maintenance_block ${style.maintenance_block}`}
        >
          <Message className="maintenance_message" info size="small">
            <div className="maintenance_detail">
              <Message.Content>
                <Icon iconName="service-time" />
                <p>
                  {isMaintenanceScheduleOn && (
                    <>
                      <span>
                        {" "}
                        {t("MaintenanceSchedule_Message_1").replace(
                          "[maintenance_schedule]",
                          maintenanceScheduleDatetime || ""
                        )}
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("MaintenanceSchedule_Message_2").replace(
                            "[support_email]",
                            "<a href='mailto:" +
                              maintenanceSupportEmail +
                              "' target='_blank'>" +
                              maintenanceSupportEmail +
                              "</a>"
                          ),
                        }}
                      ></span>
                      <br />
                    </>
                  )}
                  {isShowTHLMessage && (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: notificationTHLMessage,
                        }}
                      ></span>
                      <br />
                    </>
                  )}
                  {isShowPPUMessage && <span>{notificationPPUMessage}</span>}
                  {isShowSERMIMessage && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " " + notificationSERMIMessage,
                      }}
                    ></span>
                  )}
                </p>
                <Icon iconName="close" onClick={handleCloseMaintenanceBanner} />
              </Message.Content>
            </div>
          </Message>
        </div>
      )}
      <div ref={wrapperRef} className={style.wrapper}>
        <MinimalHeader
          actions={<HeaderActions />}
          sideNavigation={<SideNavigation />}
          className={`${style.minimal_header} ${
            !!isOpenSideNav ? style.open : ""
          }`}
        >
          {!!currentRoute?.breadcrumbs ? (
            <RDFrokBreacrumbs crumbs={currentRoute.breadcrumbs} />
          ) : (
            <></>
          )}
        </MinimalHeader>
        <div
          className={`${style.main_content} ${
            !!isOpenSideNav ? style.minimal : ""
          }`}
        >
          <div className={style.route_content}>{route}</div>
        </div>
        <div
          className={`${style.footer_wrapper} ${
            !!isOpenSideNav ? style.minimal : ""
          }`}
        >
          {isAbleToShowFooter() ? <Footer /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AppWrapper;
