import { RouteConfigure } from "../models/Route";
import {
  CHECK_OUT_CDM_3_ROUTE,
  CONTACTS_US_CDM_3_ROUTE,
  DASHBOARD_REQUESTS_CDM_3_ROUTE,
  DASHBOARD_STATISTICS_CDM_3_ROUTE,
  DASHBOARD_TEAM_CDM_3_ROUTE,
  HOME_CDM_3_ROUTE,
  MY_PROFILE_CDM_3_ROUTE,
  MY_REQUESTS_CDM_3_ROUTE,
  MY_SUBSCRIPTION_CDM_3_ROUTE,
  SHOPPING_CART_CDM_3_ROUTE,
  SUBSCRIPTIONS_CDM_3_ROUTE,
  SUBSCRIPTION_DETAIL_CDM_3_ROUTE,
  SUPPORT_CDM_3_ROUTE,
  DASHBOARD_TEAM_CREATE_CDM_3_ROUTE,
  DASHBOARD_TEAM_EDIT_CDM_3_ROUTE,
  WALLET_CDM_3_ROUTE,
  DASHBOARD_WALLET_CDM_3_ROUTE,
  DASHBOARD_MY_SUBSCRIPTION_CDM_3_ROUTE,
  DASHBOARD_CDM_3_ROUTE,
  HOME_CDM_3_ROUTE_PL,
  DASHBOARD_REQUEST_DETAILS_CDM_3_ROUTE,
  PAYMENT_DETAIL_CDM_3_ROUTE,
} from "../constants/routes/cdm3v0Routes.constants";

export const CDM_3_ROUTERS_CONFIG: RouteConfigure[] = [
  CONTACTS_US_CDM_3_ROUTE,
  HOME_CDM_3_ROUTE,
  HOME_CDM_3_ROUTE_PL,
  SUBSCRIPTIONS_CDM_3_ROUTE,
  SUBSCRIPTION_DETAIL_CDM_3_ROUTE,
  SHOPPING_CART_CDM_3_ROUTE,
  CHECK_OUT_CDM_3_ROUTE,
  DASHBOARD_REQUESTS_CDM_3_ROUTE,
  DASHBOARD_TEAM_CDM_3_ROUTE,
  DASHBOARD_STATISTICS_CDM_3_ROUTE,
  DASHBOARD_TEAM_EDIT_CDM_3_ROUTE,
  DASHBOARD_TEAM_CREATE_CDM_3_ROUTE,
  DASHBOARD_WALLET_CDM_3_ROUTE,
  MY_SUBSCRIPTION_CDM_3_ROUTE,
  PAYMENT_DETAIL_CDM_3_ROUTE,
  /**
   * Must put it in the end of all dashboard child routes
   */
  DASHBOARD_MY_SUBSCRIPTION_CDM_3_ROUTE,
  DASHBOARD_CDM_3_ROUTE,
  DASHBOARD_REQUEST_DETAILS_CDM_3_ROUTE,
  WALLET_CDM_3_ROUTE,
  MY_SUBSCRIPTION_CDM_3_ROUTE,
  MY_PROFILE_CDM_3_ROUTE,
  MY_REQUESTS_CDM_3_ROUTE,
  SUPPORT_CDM_3_ROUTE,
];
