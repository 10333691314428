import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { SignoutResponse, User } from "oidc-client-ts";
import Account, {
  GetAccountSimpleRequestModel,
  Impersonation,
} from "../models/Account";
import { CreateAccountRequest } from "../models/account/createAccountRequest";
import { UserSubscriptionInfo } from "../models/paymentAdyen/userSubscriptionInfo";
import ResponseResultBase from "../models/ResponseResultBase";
import { AuthService, CIAMCallbackData } from "../services/AuthService";
import http from "../services/HttpService";
import httpEditProfile from "../services/HttpServiceEditProfile";
import { AppDispatch, RootState } from "./store";
import { getSubscriptionStatusAsync } from "./subscriptionSlide";
import { AccountSettingsModel } from "../models/accountSettings/AccountSettings.model";
import {
  createOrUpdateAccountSettingsAsync,
  getAccountSettingsAsync,
} from "../services/AccountSettingsService";

export interface AccountState {
  account: Account | null;
  accountSubscription: UserSubscriptionInfo | null;
  originalBranch: Account | null;
  impersonationList: Impersonation[] | undefined;
  accountSettings: AccountSettingsModel | null;
}

const initialState: AccountState = {
  account: null,
  accountSubscription: null,
  originalBranch: null,
  impersonationList: [],
  accountSettings: null,
};

export const getAccountSimpleAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  GetAccountSimpleRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "account/getAccountSimple",
  async (model: GetAccountSimpleRequestModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<Account>>(
        `/api/v1/account/GetAccountSimple/${model.ciamId}/${model.language}?CountryCode=${model.countryCode}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getAccountOriginSimpleAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  GetAccountSimpleRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "account/getAccountOriginSimple",
  async (model: GetAccountSimpleRequestModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<Account>>(
        `/api/v1/account/GetAccountSimple/${model.ciamId}/${model.language}?CountryCode=${model.countryCode}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getAccountSimpleForEditProfileAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountSimpleForEditProfileAsync", async (ciamid, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Account>>(
      `/api/v1/account/GetAccountSimple/${ciamid}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getAccountExtendedAsync = createAsyncThunk<
  ResponseResultBase<Account> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/getAccountExtended", async (ciamid, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<Account>>(
      `/api/v1/account/GetAccountExtended/${ciamid}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
  }
  return null;
});

export const createAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  CreateAccountRequest,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/createAccount", async (account: CreateAccountRequest, thunkApi) => {
  let result: ResponseResultBase<Account> | null = null;
  try {
    const response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/account`,
      account
    );
    result = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const createAccountForEditProfile = createAsyncThunk<
  ResponseResultBase<Account> | null,
  Account,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/createAccountForEditProfile", async (account: Account, thunkApi) => {
  let result: ResponseResultBase<Account> | null = null;
  try {
    const response = await httpEditProfile.post<ResponseResultBase<Account>>(
      `/api/v1/account`,
      account
    );
    result = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const updateSignInAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  Account,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/updateSignInAccount", async (account: Account, thunkApi) => {
  let result: ResponseResultBase<Account> | null = null;
  try {
    const response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/account`,
      account
    );
    if (
      response.status === 204 ||
      response.status === 200 ||
      response.status === 201
    ) {
      result = {
        dataObject: account,
        errorMessages: [],
        httpStatusCode: 200,
        success: true,
      };
    } else {
      result = response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const updateAccount = createAsyncThunk<
  ResponseResultBase<Account> | null,
  Account,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/updateAccount", async (account: Account, thunkApi) => {
  let result: ResponseResultBase<Account> | null = null;
  try {
    const response = await http.post<ResponseResultBase<Account>>(
      `/api/v1/account`,
      account
    );
    if (
      response.status === 204 ||
      response.status === 200 ||
      response.status === 201
    ) {
      result = {
        dataObject: account,
        errorMessages: [],
        httpStatusCode: 200,
        success: true,
      };
    } else {
      result = response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return result;
});

export const loginCallbackAsync = createAsyncThunk<
  User | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/loginCallbackAsync", async () => {
  let result: User | null = null;
  try {
    const accountService = AuthService.getInstance();
    result = await accountService.loginCallbackAsync();
  } catch (error) {}
  return result;
});

export const logoutCallbackAsync = createAsyncThunk<
  SignoutResponse | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/logoutCallbackAsync", async () => {
  let result: SignoutResponse | null = null;
  try {
    const accountService = AuthService.getInstance();
    result = await accountService.logoutCallbackAsync();
  } catch (error) {}
  return result;
});

export const loginAsync = createAsyncThunk<
  void,
  CIAMCallbackData | undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/loginAsync", async (dataRedirect?: CIAMCallbackData | undefined) => {
  try {
    const accountService = AuthService.getInstance();
    await accountService.loginAsync(dataRedirect);
  } catch (error) {}
});

export const logoutAsync = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("account/logoutAsync", async () => {
  try {
    const accountService = AuthService.getInstance();
    await accountService.logoutAsync();
  } catch (error) {}
});

export const accountSlice = createSlice({
  name: "accountRediReducer",
  initialState,
  reducers: {
    getAccountState: (state) => {
      return { ...state };
    },
    setAccountImpersonationList: (state, action) => {
      return {
        ...state,
        originalBranch: action.payload.dataObject,
        impersonationList: action.payload.dataObject.impersonationList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.account = payload.dataObject;
        }
      })
      .addCase(getAccountOriginSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          return {
            ...state,
            originalBranch: payload.dataObject,
            impersonationList: payload.dataObject?.impersonationList,
          };
        }
      })
      .addCase(getAccountExtendedAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.httpStatusCode === 200) {
          state.account = payload.dataObject;
        }
      })
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 || payload.httpStatusCode === 201)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(updateAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 ||
            payload.httpStatusCode === 201 ||
            payload.httpStatusCode === 204)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(updateSignInAccount.fulfilled, (state, { payload }) => {
        if (
          payload != null &&
          (payload.httpStatusCode === 200 ||
            payload.httpStatusCode === 201 ||
            payload.httpStatusCode === 204)
        ) {
          state.account = payload.dataObject;
        }
      })
      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          account: null,
          accountSubscription: null,
        };
      })
      .addCase(getSubscriptionStatusAsync.fulfilled, (state, { payload }) => {
        return {
          ...state,
          accountSubscription: payload,
        };
      })
      .addCase(getAccountSettingsAsync.fulfilled, (state, { payload }) => {
        if (payload?.dataObject) {
          return {
            ...state,
            accountSettings: payload.dataObject,
          };
        }
        return {
          ...state,
        };
      })
      .addCase(
        createOrUpdateAccountSettingsAsync.fulfilled,
        (state, { payload }) => {
          if (payload?.dataObject) {
            return {
              ...state,
              accountSettings: payload.dataObject,
            };
          }
          return {
            ...state,
          };
        }
      );
  },
});

export const isAccountBeAbleToPayByBoschAccount = (rootState: RootState) => {
  return !!rootState?.accountRedi?.account?.allowPayViaAccount;
};

export const selectAccount = (rootState: RootState) =>
  rootState.accountRedi.account;
export const selectCiamId = (rootState: RootState) =>
  rootState.accountRedi.account?.ciamId ?? "";
export const selectCdbId = (rootState: RootState) =>
  rootState.accountRedi.account?.cdbId;
export const selectAccountSubscription = (rootState: RootState) =>
  rootState.accountRedi.accountSubscription;
export const selectImList = (rootState: RootState) =>
  rootState.accountRedi.impersonationList;
export const selectOriginComp = (rootState: RootState) =>
  rootState.accountRedi.originalBranch;
export const selectAccountSettings = (rootState: RootState) =>
  rootState.accountRedi.accountSettings;
export const selectSolutionGroups = (rootState: RootState) =>
  rootState.accountRedi.account?.solutionGroups;
export const selectAccountDateFormat = (rootState: RootState) =>
  rootState.accountRedi.accountSettings?.shortDateTimeFormat;
export const { getAccountState, setAccountImpersonationList } =
  accountSlice.actions;
export default accountSlice.reducer;
